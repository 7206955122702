import React, { useEffect, useState } from 'react';
import './styling/AboutInnovFin.css';
import { Button, Col, Container, Row } from 'react-bootstrap';
import LogoSlider from './components/LogoSlider';
import speakEng from './json_files/SpeakingEngagements.json';
import metaData from "./json_files/metadata.json";
import HelmetMetaData from './components/HelmetMetaData';
import { listAboutOthaliaContents, listVideoEmbeds } from "./graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import PopupComponent from './components/PopupComponent';
// import awards from './json_files/Awards.json';


function AboutOthalia() {
    const { lang } = useParams();
    // For the meta data stuff
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [keywords, setKeywords] = useState("");
    // Get location metadata from JSON file, this system maybe used in the future so that
    // non-developers can edit metadata.
    const getMeta = (data) => {
        if (data.url === window.location.pathname.split("/")[2]) {
            setTitle(data.title);
            setDescription(data.description);
            setKeywords(data.keywords);
        }
    };

    //the below is used for storing the content that will be grabbed from the API
    //is loaded is to check if the api is loaded
    const [aboutOthaliaContent, setAboutOthaliaContent] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isVidLoaded, setIsVidLoaded] = useState(false);
    const [videoEmbed, setVideoEmbed] = useState([]);

    const setContentAPI = () => {
        API.graphql(graphqlOperation(listAboutOthaliaContents))
            .then((data) => {
                const modifiedData2 = data["data"]["listAboutOthaliaContents"]["items"];

                modifiedData2.map((item) => {
                    item.content = parse(item.content);
                    item.contentFr = parse(item.contentFr);
                    return item;
                });

                setAboutOthaliaContent(modifiedData2);
                setIsLoaded(true);
                // console.log("content education: ", aboutOthaliaContent.find(find => find.id === "4c769797-8af0-4a49-b30a-6d577e8eb9d4"));
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // needs separate api function or a crash is caused
    const setVideoAPI = () => {
        API.graphql(graphqlOperation(listVideoEmbeds))
            .then((data) => {
                const modifiedData3 = data["data"]["listVideoEmbeds"]["items"];

                setVideoEmbed(modifiedData3);
                setIsVidLoaded(true);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    //list of class names to disable right-click
    const imageClasses = [".card-img-dropshadow-heavy", ".primaryElement-img", ".othalia-award-img"];

    //remove the context menu for the images
    const removeRightClick = () => {
        imageClasses.forEach(imgClass => {
            const noContextMenu = document.querySelectorAll(imgClass);
            noContextMenu.forEach(data => {
                data.addEventListener("contextmenu", e => e.preventDefault());
            })
        })
    }

    useEffect(() => {
        setContentAPI();
        setVideoAPI();
        removeRightClick();
        metaData.forEach(data => {
            getMeta(data);
        });
    }, []);

    return (
        <div>
            <HelmetMetaData
                title={title}
                description={description}
                keywords={keywords}>
            </HelmetMetaData>
            <Container>
                <div className="infoContainer" id="AboutContainer2">
                    <h1>
                        {
                            !isLoaded ?
                                (lang === 'fr' ?
                                    'À propos d\'Othalia Doe-Bruce'
                                    : 'About Othalia Doe-Bruce'
                                )
                                :
                                (lang === 'fr' ?
                                    aboutOthaliaContent.find(find => find.id === "77d5af63-d744-454b-8c84-9406f469ff71").titleFr
                                    : aboutOthaliaContent.find(find => find.id === "77d5af63-d744-454b-8c84-9406f469ff71").title
                                )
                        }
                    </h1>
                    <div className="infoContainer-description">
                        {
                            !isLoaded ?
                                (lang === 'fr' ?
                                    <p>
                                        <span>
                                            Apprenez-en plus sur notre fondatrice et PDG, Othalia Doe-Bruce, sur son entreprise à succès pavée par Blockchain.
                                            <br />
                                            Apprenez-en plus sur ce que nous faisons et comment nous pouvons vous aider.
                                        </span>
                                    </p>
                                    : <p>
                                        <span>
                                            Learn more about our Founder and CEO, Othalia Doe-Bruce, on her successful venture paved by Blockchain.
                                            <br />
                                            Learn more about what we do, and how we can help.
                                        </span>
                                    </p>
                                )
                                :
                                (lang === 'fr' ?
                                    aboutOthaliaContent.find(find => find.id === "77d5af63-d744-454b-8c84-9406f469ff71").contentFr
                                    : aboutOthaliaContent.find(find => find.id === "77d5af63-d744-454b-8c84-9406f469ff71").content
                                )
                        }
                    </div>

                    <Container className="containerWithBorder" >
                        {/* Othalia's Experience */}
                        <div className="sectionContainer">
                            <Row className="align-items-center">
                                <Col xs={12} md={12} lg={6}>
                                    <img
                                        className="img-center-size card-img-dropshadow-heavy"
                                        src={!isLoaded ? "../assets/About Othalia Assets/OthaliasExperience.png" : aboutOthaliaContent.find(find => find.id === "883b817d-23e8-4450-9299-933fcb38fbfc").image}
                                        alt='Othalias Experience '
                                    />
                                </Col>
                                <Col id="text-element-about" xs={12} md={12} lg={6}>
                                    <label className="titleSection">
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    'L\'expérience d\'Othalia'
                                                    : 'Othalia\'s Experience'
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    aboutOthaliaContent.find(find => find.id === "883b817d-23e8-4450-9299-933fcb38fbfc").titleFr
                                                    : aboutOthaliaContent.find(find => find.id === "883b817d-23e8-4450-9299-933fcb38fbfc").title
                                                )
                                        }
                                    </label>
                                    <div className="aboutText">
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    <p>
                                                        <span>
                                                            Othalia Doe-Bruce est la fondatrice d'InnovFin Consulting Inc,
                                                            un cabinet de conseil et de consultation qui aide les entreprises à tirer parti des technologies Blockchain, pour réduire les coûts,
                                                            générer des revenus et accéder au financement.
                                                            <br /><br />
                                                            Elle est également cadre au BlockchainHub, une ONG au sein de l'Université de York qui assure l'éducation,
                                                            la recherche et la commercialisation des start-ups. Là, elle a conçu et géré des programmes de commercialisation et d'éducation réussis,
                                                            ce qui lui a permis de faire croître la communauté à plus de 6 000 membres.
                                                            <br /><br />
                                                            En 2018, elle a lancé la première journée de recrutement blockchain au Canada avec 250 candidats sélectionnés et des sponsors tels que KPMG, IBM,
                                                            Toda et Skrumble.
                                                        </span>
                                                    </p>
                                                    : <p>
                                                        <span>
                                                            Othalia Doe-Bruce is the Founder of InnovFin Consulting Inc., a consulting and advisory firm helping companies leverage Blockchain technologies,
                                                            to cut costs, generate revenues, and access financing.
                                                            <br /><br />
                                                            She is also an Executive at the BlockchainHub, an NGO within York University providing education, research, and commercialization of Start-Ups.
                                                            At the BlockchainHub, Othalia has designed and managed successful commercialization and educational programs, allowing her to grow the community to 6,000+ members.
                                                            <br /><br />
                                                            In 2018, she launched the first Blockchain Recruitment Day in Canada with 250 selected applicants and sponsors such as KPMG, IBM, Toda, and Skrumble.
                                                        </span>
                                                    </p>
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    aboutOthaliaContent.find(find => find.id === "883b817d-23e8-4450-9299-933fcb38fbfc").contentFr
                                                    : aboutOthaliaContent.find(find => find.id === "883b817d-23e8-4450-9299-933fcb38fbfc").content
                                                )
                                        }
                                    </div>
                                    <Button className='button' href={`/${lang}/contact-us/`}>
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    'Contactez-nous'
                                                    : 'Contact Us'
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    aboutOthaliaContent.find(find => find.id === "883b817d-23e8-4450-9299-933fcb38fbfc").buttonFr
                                                    : aboutOthaliaContent.find(find => find.id === "883b817d-23e8-4450-9299-933fcb38fbfc").button
                                                )
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        </div>

                        {/* The Innovative Financier */}
                        <div className="sectionContainer">
                            <Row className="align-items-center">
                                <Col id="text-element" xs={12} md={12} lg={6}>
                                    <label className="titleSection">
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    <span><i>The Innovative Financier</i></span>
                                                    : <span>The Innovative Financier</span>
                                                )
                                                : (lang === 'fr' ?
                                                    aboutOthaliaContent.find(find => find.id === "5c121d67-8fcd-4745-9d72-3defab50072e").titleFr
                                                    : aboutOthaliaContent.find(find => find.id === "5c121d67-8fcd-4745-9d72-3defab50072e").title
                                                )
                                        }
                                    </label>
                                    <div>
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    <p>
                                                        <span>
                                                            En 2018, Othalia a fondé <i>The Innovative Financier</i> (TIF),
                                                            un organisme à but non lucratif et un hub d'information avec une communauté forte de plus de 600 membres.
                                                            TIF sensibilise aux technologies émergentes pour aider les individus à identifier les opportunités dans la nouvelle économie numérique.
                                                            TIF a collecté des fonds pour des organisations caritatives telles que : Armée du Salut, PAWS et Film4Peace.
                                                            <br /><br />
                                                            Au TIF, nous construisons une communauté d'entrepreneurs fintech, de développeurs, de professionnels et d'étudiants visant à apprendre,
                                                            à réseauter et à partager sur l'impact des technologies émergentes sur le secteur de la finance (y compris l'investissement).
                                                            Nous prenons nos convictions et les transformons en action.
                                                        </span>
                                                    </p>
                                                    : <p>
                                                        <span>
                                                            In 2018, Othalia founded The Innovative Financier (TIF), a not-for-profit and an information hub with a community of 600+ members.
                                                            TIF raises awareness on emerging tech to help individuals identify opportunities in the new digital economy.
                                                            TIF raised funds for charities such as Salvation Army, PAWS, and Film4Peace.
                                                            <br /><br />
                                                            At TIF, we are building a community of fintech entrepreneurs, developers, professionals, and students aiming to learn, network,
                                                            and share the impact of Emerging technologies on the Finance (and Investment) industry.
                                                            We take our convictions and turn them into action.
                                                        </span>
                                                    </p>
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    aboutOthaliaContent.find(find => find.id === "5c121d67-8fcd-4745-9d72-3defab50072e").contentFr
                                                    : aboutOthaliaContent.find(find => find.id === "5c121d67-8fcd-4745-9d72-3defab50072e").content
                                                )
                                        }
                                    </div>
                                    <Button href='https://theinnovativefinancier.ca/' className='button'>
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    'En savoir plus'
                                                    : 'Learn More'
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    aboutOthaliaContent.find(find => find.id === "5c121d67-8fcd-4745-9d72-3defab50072e").buttonFr
                                                    : aboutOthaliaContent.find(find => find.id === "5c121d67-8fcd-4745-9d72-3defab50072e").button
                                                )
                                        }
                                    </Button>
                                </Col>
                                <Col id="image-element" xs={12} md={12} lg={6}>
                                    {/* <div className="primaryElement-img"> */}
                                    <img
                                        style={{ padding: "0em 2em" }}
                                        src={!isLoaded ? "../assets/About InnovFin Assets/TheInnovativeFinancier.png" : aboutOthaliaContent.find(find => find.id === "5c121d67-8fcd-4745-9d72-3defab50072e").image}
                                        className="img-center-size card-img-dropshadow-heavy"
                                        alt='TheInnovativeFinancier'
                                    />
                                    {/* </div> */}
                                </Col>
                            </Row>
                        </div>

                        {/* Awards */}
                        <div className="sectionContainer">
                            <Row className="align-items-center">
                                <Col xs={12} md={12} lg={6} >
                                    <img
                                        src={!isLoaded ? "../assets/About Othalia Assets/AwardAcceptanceImage.png" : aboutOthaliaContent.find(find => find.id === "c8ab1884-6c93-446f-96dc-1f5e7ca12352").image}
                                        className="img-center-size card-img-dropshadow-heavy"
                                        alt='Othalia Doe-Bruce'
                                    />
                                </Col>
                                <Col id="text-element-about" xs={12} md={12} lg={6}>

                                    <label className="titleSection">
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    'Récompenses'
                                                    : 'Awards'
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    aboutOthaliaContent.find(find => find.id === "c8ab1884-6c93-446f-96dc-1f5e7ca12352").titleFr
                                                    : aboutOthaliaContent.find(find => find.id === "c8ab1884-6c93-446f-96dc-1f5e7ca12352").title
                                                )
                                        }
                                    </label>
                                    <div>
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    <p>
                                                        En tant que lauréate, Othalia a reçu le prix de l\'activisme communautaire décerné par le Waterfront Magazine, et a été nommée par ReleveON et WomenTech pour avoir brisé les barrières dans le domaine de l\'innovation. Sous sa direction, InnovFin est en train de construire l\'avenir de l\'Ed Tech :
                                                    </p>
                                                    : <p>An award winner, Othalia is the recipient of the Community Activism Award by the Waterfront Magazine, and was nominated by ReleveON and WomenTech for breaking barriers in the innovation space. Under her leadership, InnovFin is now building the Future of Ed Tech.
                                                    </p>
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    aboutOthaliaContent.find(find => find.id === "c8ab1884-6c93-446f-96dc-1f5e7ca12352").contentFr
                                                    : aboutOthaliaContent.find(find => find.id === "c8ab1884-6c93-446f-96dc-1f5e7ca12352").content
                                                )
                                        }
                                    </div>

                                    <Container>
                                        {/* The following code was for mapping individual awards, if this is to be used again, un-comment import of json
                                        <Row md={2}>
                                            {awards.map(list => (
                                                <Col>
                                                    <img src={list.source} width='250em' style={{ padding: "20px" }} alt=''/>
                                                </Col>
                                            ))}
                                        </Row> */}
                                        <Row>
                                            <img
                                                className="othalia-award-img"
                                                src={!isLoaded ? "../assets/About Othalia Assets/awards.png" : aboutOthaliaContent.find(find => find.id === "98c89a40-a178-4fed-996e-5291c1b80b5b").image}
                                                alt='Othalia Awards'
                                            />
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>

                        </div>

                    </Container>
                </div>
            </Container>

            {/* Speaking Engagements */}
            <div className='sectionContainer' id="SpeakEng">
                <Container className='primaryElement  primaryElement-Var2'>
                    <Row className="align-items-center">
                        <Col id="text-element" xs={12} lg={6}>
                            <h1>
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            'Engagements d\'allocution'
                                            : 'Speaking Engagements'
                                        )
                                        :
                                        (lang === 'fr' ?
                                            aboutOthaliaContent.find(find => find.id === "9a55ba52-8027-4106-a46b-928c810f6bab").titleFr
                                            : aboutOthaliaContent.find(find => find.id === "9a55ba52-8027-4106-a46b-928c810f6bab").title
                                        )
                                }
                            </h1>
                            <div>
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            <p>
                                                <span>
                                                    PDG et fondatrice, Othalia Doe-Bruce, une conférencière avide de Blockchain, ici pour vous inspirer, vous aider à innover,
                                                    à vous développer et à englober les technologies perturbatrices.
                                                    <br /><br />
                                                    Entrepreneur primé, l'une des rares femmes dans Blockchain perturbant le statu quo du déséquilibre entre les sexes,
                                                    Othalia Doe-Bruce est passionnée par l'idée d'aider les leaders de l'industrie et les professionnels des affaires à s'appuyer sur leur état d'esprit innovant,
                                                    à s'autonomiser, à embrasser les technologies perturbatrices et à développer leurs entreprises.
                                                    <br /><br />
                                                    Auteur présenté sur le Globe &amp; Mail, conférencier international au HCR, Blockchain Hackathon du gouvernement du Canada,
                                                    Semaine des prix de l'écran canadien, Radio Canada, FMI, Université de Californie Irvine.
                                                    En 2020, Othalia est également devenue contributrice pour Entrepreneur.com,
                                                    une plateforme médiatique comptant des millions de lecteurs et d'aspirants entrepreneurs.
                                                    <br /><br />
                                                    Elle est généreuse, bien informée et inspirante, apportant de la valeur aux entreprises avec des idées novatrices et révolutionnaires...
                                                    qui peuvent réellement être mises en œuvre.
                                                </span>
                                            </p>
                                            : <p>
                                                <span>
                                                    CEO &amp; Founder Othalia Doe-Bruce, an avid Blockchain speaker, is here to inspire you, help you innovate, grow,
                                                    and encompass disruptive technologies.
                                                    <br /><br />
                                                    She is an Award-Winning Entrepreneur, one of the few Women in Blockchain disrupting the gender imbalance status quo.
                                                    Othalia Doe-Bruce is passionate about helping industry leaders and business professionals build on their innovative mindsets, self-empower,
                                                    embrace disruptive technologies, and grow their businesses.
                                                    <br /><br />
                                                    Author featured on the Globe &amp; Mail, International Speaker at UNHCR, Government of Canada Blockchain Hackathon, Canadian Screen Awards Week,
                                                    Radio Canada, FMI, University of California Irvine. In 2020, Othalia also became a contributor for Entrepreneur.com,
                                                    a media platform with millions of readers and aspiring entrepreneurs.
                                                    <br /><br />
                                                    She is generous, knowledgeable, and inspiring, bringing value to businesses with ground-breaking,
                                                    revolutionary ideas that can be implemented in your industry
                                                </span>
                                            </p>
                                        )
                                        :
                                        (lang === 'fr' ?
                                            aboutOthaliaContent.find(find => find.id === "9a55ba52-8027-4106-a46b-928c810f6bab").contentFr
                                            : aboutOthaliaContent.find(find => find.id === "9a55ba52-8027-4106-a46b-928c810f6bab").content
                                        )
                                }
                            </div>
                            <Button className='button btn-2' style={{ float: 'left' }} href={`/${lang}/contact-us/`}>
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            'Réservez un discours d\'Othalia'
                                            : 'Book Othalia to Speak'
                                        )
                                        :
                                        (lang === 'fr' ?
                                            aboutOthaliaContent.find(find => find.id === "9a55ba52-8027-4106-a46b-928c810f6bab").buttonFr
                                            : aboutOthaliaContent.find(find => find.id === "9a55ba52-8027-4106-a46b-928c810f6bab").button
                                        )
                                }
                            </Button>
                        </Col>
                        <Col id="image-element" xs={12} lg={6}>
                            <img
                                className="img-center-size card-img-dropshadow-heavy"
                                src={!isLoaded ? "../assets/About Othalia Assets/SpeakingEngagementImage.png" : aboutOthaliaContent.find(find => find.id === "9a55ba52-8027-4106-a46b-928c810f6bab").image}
                                alt='Othalia Engagment'
                            />
                        </Col>
                    </Row>
                </Container>
                <div style={{ padding: "2em 20em" }}>
                    <div className='player-wrapper'>
                        <video
                            onContextMenu={(e) => e.preventDefault()}
                            className="react-player"
                            poster={!isVidLoaded ? null : videoEmbed.find(find => find.id === "190eaebb-ffde-450d-a71a-8ad26e77c20c").thumbnail}
                            title="Video"
                            width="100%"
                            height="100%"
                            src={!isVidLoaded ? 'https://www.youtube.com/embed/qhGefsF9-4' : videoEmbed.find(find => find.id === "190eaebb-ffde-450d-a71a-8ad26e77c20c").url.replace("watch?v=", "embed/") + "?rel=0"}
                            frameBorder="0"
                            // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            controls="controls"
                            controlsList="nodownload"
                        />
                    </div>
                </div>
                <Container style={{ paddingBottom: '2em' }}>
                    <p style={{ textAlign: 'center', marginTop: '0' }}>
                        {lang === 'fr' ?
                            <span>Parmi les engagements notables d'Othalia auprès des médias et des auteurs anglophones et francophones, on peut citer:</span>
                            : <span>Some of Othalia's Notable English and French speaking media and author engagements include:</span>
                        }
                    </p>
                </Container>
                <div>
                    <LogoSlider list={speakEng} logoId='speakEng' />
                </div>
            </div>

            {/* Published Pieces */}
            <div className='sect2'>
                <Container className='primaryElement primaryElement-Var'>
                    <Row className="align-items-center">
                        <Col id="text-element" className="sect2-txt" md={12} lg={6}>
                            <h1>
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            'Pièces publiées'
                                            : 'Published Pieces'
                                        )
                                        :
                                        (lang === 'fr' ?
                                            aboutOthaliaContent.find(find => find.id === "b69129c3-f34a-45a9-9582-ff1bf07b6ac4").titleFr
                                            : aboutOthaliaContent.find(find => find.id === "b69129c3-f34a-45a9-9582-ff1bf07b6ac4").title
                                        )
                                }
                            </h1>
                            <div>
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            <p>
                                                <span>
                                                    En 2019, Othalia a été invitée par des professeurs de l'Université d'Ottawa à coécrire le livre: " Crypto-Finance et mécanismes d'échange " publié par Springer, qui est maintenant disponible en e-book et en version papier.Ce livre est indispensable pour tous les professionnels de la finance qui cherchent à comprendre comment la blockchain change le paysage de la finance.
                                                    <br /> <br />
                                                    Othalia est un fervent partisan de l'éducation comme moyen d'autonomisation économique et s'exprime souvent lors de conférences et devant les médias pour partager sa passion et faire progresser l'adoption de Blockchain.
                                                </span>
                                            </p>
                                            : <p>
                                                <span>
                                                    In 2019, Othalia was invited by professors at the University of Ottawa to co-author the book: “Crypto-Finance and Mechanisms of Exchange'' published by Springer,
                                                    which is now available in e-book and hard copy. This book is a must-have for all Finance professionals looking to understand how Blockchain is changing the Finance landscape.
                                                    <br /><br />
                                                    Othalia is a strong proponent of education as a means toward economic self-empowerment and often speaks at conferences and to the media to share her passion and advance Blockchain adoption.
                                                </span>
                                            </p>
                                        )
                                        :
                                        (lang === 'fr' ?
                                            aboutOthaliaContent.find(find => find.id === "b69129c3-f34a-45a9-9582-ff1bf07b6ac4").contentFr
                                            : aboutOthaliaContent.find(find => find.id === "b69129c3-f34a-45a9-9582-ff1bf07b6ac4").content
                                        )
                                }
                            </div>
                            <Button href="https://www.springer.com/us/book/9783030307370" className='button' style={{ float: 'left' }}>
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            'Achetez-le maintenant'
                                            : 'Get it Now'
                                        )
                                        :
                                        (lang === 'fr' ?
                                            aboutOthaliaContent.find(find => find.id === "b69129c3-f34a-45a9-9582-ff1bf07b6ac4").buttonFr
                                            : aboutOthaliaContent.find(find => find.id === "b69129c3-f34a-45a9-9582-ff1bf07b6ac4").button
                                        )
                                }
                            </Button>
                        </Col>
                        <Col id="image-element" md={12} lg={6}>
                            <div className="primaryElement-img">
                                <img
                                    style={{ maxWidth: '50%' }}
                                    className="img-center-size card-img-dropshadow-img"
                                    src={!isLoaded ? "../assets/About Othalia Assets/Ebook.png" : aboutOthaliaContent.find(find => find.id === "b69129c3-f34a-45a9-9582-ff1bf07b6ac4").image}
                                    alt='Othalias published book' />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Background and Past Experience */}
            <div className='sect2'>
                <Container className='primaryElement  primaryElement-Var1'>
                    <Row className="align-items-center">
                        <Col md={12} lg={6}>
                            <img
                                className="img-center-size card-img-dropshadow-heavy"
                                src={!isLoaded ? "../assets/About Othalia Assets/BackgroundPastExperience.png" : aboutOthaliaContent.find(find => find.id === "692042fa-f03b-4245-90b0-81b034629319").image}
                                alt='Background Experience'
                            />
                        </Col>
                        <Col className="sect2-txt" md={12} lg={6}>
                            <h1>
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            'Contexte & expérience antérieure'
                                            : 'Background & Past Experience'
                                        )
                                        :
                                        (lang === 'fr' ?
                                            aboutOthaliaContent.find(find => find.id === "692042fa-f03b-4245-90b0-81b034629319").titleFr
                                            : aboutOthaliaContent.find(find => find.id === "692042fa-f03b-4245-90b0-81b034629319").title
                                        )
                                }
                            </h1>
                            <div>
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            <p>
                                                <span>
                                                    Othalia Doe-Bruce est titulaire d'un certificat en développement de blockchain Ethereum, du CIPM du CFA Institute, d'un MBA américain en finance d'investissement et d'un Master 1 européen en management.
                                                    <br /><br />
                                                    Ayant voyagé en Afrique, en Europe, en Amérique du Nord et en Asie, elle parle couramment le français et l'anglais. Elle a également une compréhension de base de l'espagnol et des notions de coréen.
                                                    <br /><br />
                                                    Avant de se découvrir une passion pour la blockchain, Othalia Doe-Bruce a travaillé à l'international pour des sociétés de gestion d'investissement figurant au classement Fortune 500, telles qu'UBS et Alliance Bernstein, développant une expertise dans l'analyse et le reporting des investissements.
                                                </span>
                                            </p>
                                            : <p>
                                                <span>
                                                    Othalia Doe-Bruce is the holder of a Certificate in Ethereum Blockchain Development, the CIPM from the CFA Institute, an American MBA in Investment Finance, and a European Master 1 in Management.
                                                    <br /><br />
                                                    Having travelled in Africa, Europe, North America, and Asia, she is fluent in French and English. She also has a basic understanding of Spanish and notions of Korean.
                                                    <br /><br />
                                                    Before discovering a passion for Blockchain, Othalia Doe-Bruce worked internationally for Fortune 500 investment management firms such as UBS and Alliance Bernstein, developing expertise in investment analytics and reporting.
                                                </span>
                                            </p>
                                        )
                                        :
                                        (lang === 'fr' ?
                                            aboutOthaliaContent.find(find => find.id === "692042fa-f03b-4245-90b0-81b034629319").contentFr
                                            : aboutOthaliaContent.find(find => find.id === "692042fa-f03b-4245-90b0-81b034629319").content
                                        )
                                }
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>
            {/* The following is used to show any popup at this location, there may not be any popups to show */}
            <PopupComponent lang={lang} location="About othalia" />
        </div >
    )
}

export default AboutOthalia;