import * as Yup from 'yup';

const ContactSchemaFr = Yup.object().shape({
    name: Yup.string()
        .required("Le prénom est obligatoire"),
    email: Yup.string()
        .email("Format d'adresse courriel non valide")
        .required("Le courriel est requis"),
    // inputText: Yup.string()
    //     .min(1, "Please enter an inquiry here")
    //     .required("Please look over your inquiry message"),
    inputDrop: Yup.string()
        .required("Veuillez chosir une option ici")
})

export default ContactSchemaFr;