import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Field } from "formik";
import { RichTextField } from "react-admin";
import ContactText from "../json_files/ContactText.json"

const InquiryForm = ({
    name,
    lang,
    placehold
}) => {
    return (
        <Field
            name={name}>
            {({ field, form, meta }) => {
                // console.log("field ", field)
                return (
                    <Form.Group className="mb-3">
                        <InputGroup className="mb-3">
                            {lang === "fr" ? (
                                <>
                                    <Form.Control
                                        {...field}
                                        type="text"
                                        as="textarea"
                                        rows={6}
                                        placeholder={placehold}
                                        isInvalid={!!form.errors.inputText}
                                        name={name}
                                        id={name}
                                    />
                                </>
                            ) : (
                                <>
                                    <Form.Control
                                        {...field}
                                        type="text"
                                        as="textarea"
                                        rows={6}
                                        placeholder={placehold}
                                        isInvalid={!!form.errors.inputText}
                                        name={name}
                                        id={name}
                                        style={{whiteSpace:"pre-wrap"}}
                                    />
                                </>
                            )}
                            <Form.Control.Feedback type="invalid">
                                {" "}
                                {form.errors.inputText}{" "}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                );
            }}

        </Field>
    )
}

export default InquiryForm;