import React, { useEffect, useState } from 'react';
import '../styling/Services.css';
import { Button, Card, CardGroup, Container, Row } from 'react-bootstrap';
import metaData from "../json_files/metadata.json";
import HelmetMetaData from '../components/HelmetMetaData';
import { listServices } from "../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import PopupComponent from '../components/PopupComponent';

function Implementation() {
    const { lang } = useParams();
    // For the meta data stuff
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [keywords, setKeywords] = useState("");
    // Get location metadata from JSON file, this system maybe used in the future so that
    // non-developers can edit metadata.
    const getMeta = (data) => {
        if (data.url === window.location.pathname.split("/")[2]) {
            setTitle(data.title);
            setDescription(data.description);
            setKeywords(data.keywords);
        }
    };

    //the below is used for storing the content that will be grabbed from the API
    //is loaded is to check if the api is loaded
    const [serviceContent, setServiceContent] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const setContentAPI = () => {
        API.graphql(graphqlOperation(listServices))
            .then((data) => {
                const modifiedData2 = data["data"]["listServices"]["items"];

                modifiedData2.map((item) => {
                    item.content = parse(item.content);
                    item.contentFr = parse(item.contentFr);
                    return item;
                });

                setServiceContent(modifiedData2);
                setIsLoaded(true);
                // console.log("content education: ", serviceContent.find(find => find.id === "4c769797-8af0-4a49-b30a-6d577e8eb9d4"));
            })
            .catch((err) => {
                console.log(err);
            });
    }

     //list of class names to disable right-click
     const imageClasses = [".card-img-dropshadow"];

     //remove the context menu for the images
     const removeRightClick = () =>{
         imageClasses.forEach(imgClass => {
             const noContextMenu = document.querySelectorAll(imgClass);
             noContextMenu.forEach(data => {
                 data.addEventListener("contextmenu", e => e.preventDefault());
             })
         })
     }

    useEffect(() => {
        setContentAPI();
        removeRightClick();
        metaData.forEach(data => {
            getMeta(data);
        });
    }, []);
    return (
        <Container>
            <HelmetMetaData
                title={title}
                description={description}
                keywords={keywords}>
            </HelmetMetaData>
            <div className='infoContainer' id='serviceContainer'>
                <h1>
                    {
                        !isLoaded ?
                            (lang === 'fr' ?
                                'Services de mise en œuvre'
                                : 'Implementation Services'
                            )
                            :
                            (lang === 'fr' ?
                                serviceContent.find(find => find.id === "f497d39e-bfdf-4112-8d2b-2866a13331a7").titleFr
                                : serviceContent.find(find => find.id === "f497d39e-bfdf-4112-8d2b-2866a13331a7").title
                            )
                    }
                </h1>
                <div className="infoContainer-description">
                    {
                        !isLoaded ?
                            (lang === 'fr' ?
                                <p>
                                    Nos services de mise en œuvre de Blockchain constituent un ensemble tout-en-un, de la planification à l\'intégration. Nous sommes présents à chaque étape pour aider votre entreprise ou organisation à développer des solutions Blockchain génératrices de revenus.
                                </p>
                                : <p>
                                    Our Blockchain implementation services are an all-in-one package, from planning to integration. We are there every step of the way to help your business or organization develop revenue-generating Blockchain solutions.
                                </p>
                            )
                            :
                            (lang === 'fr' ?
                                serviceContent.find(find => find.id === "f497d39e-bfdf-4112-8d2b-2866a13331a7").contentFr
                                : serviceContent.find(find => find.id === "f497d39e-bfdf-4112-8d2b-2866a13331a7").content
                            )
                    }
                </div>

                {/* Container for the cards */}
                <Container className="serviceContainer" style={{ paddingLeft: '1%', paddingRight: '1%' }}>
                    <Row className="justify-content-md-center">
                        <CardGroup className="justify-content-md-center">

                            <Card className="infoCard">
                                <Card.Body>
                                    <h1>
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    'Produit de paiement de fidélisation blockchain'
                                                    : 'Blockchain Loyalty Payment Product'
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    serviceContent.find(find => find.id === "4443cd8f-989d-4ee2-8aac-ec5815194417").titleFr
                                                    : serviceContent.find(find => find.id === "4443cd8f-989d-4ee2-8aac-ec5815194417").title
                                                )
                                        }
                                    </h1>
                                    <Card.Img className="card-img-dropshadow" variant="top" src={!isLoaded ? "../assets/Implementation Services Assets/BlockchainLoyaltyPayment.png" : serviceContent.find(find => find.id === "4443cd8f-989d-4ee2-8aac-ec5815194417").image}/>
                                    {
                                        !isLoaded ?
                                            (lang === 'fr' ?
                                                <p>
                                                    La solution idéale pour fidéliser les clients, les retenir et améliorer vos résultats. Augmentez vos revenus à long terme!
                                                </p>
                                                : <p>
                                                    The perfect solution to increase customer loyalty, retention, and your bottom line. Increase long-term revenues!
                                                </p>
                                            )
                                            :
                                            (lang === 'fr' ?
                                                serviceContent.find(find => find.id === "4443cd8f-989d-4ee2-8aac-ec5815194417").contentFr
                                                : serviceContent.find(find => find.id === "4443cd8f-989d-4ee2-8aac-ec5815194417").content
                                            )
                                    }
                                </Card.Body>
                                <Card.Footer>
                                    <Button href="https://www.innov-edu.ca/" className='button'>
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    'En savoir plus'
                                                    : 'Find out more'
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    serviceContent.find(find => find.id === "4443cd8f-989d-4ee2-8aac-ec5815194417").buttonFr
                                                    : serviceContent.find(find => find.id === "4443cd8f-989d-4ee2-8aac-ec5815194417").button
                                                )
                                        }
                                    </Button>
                                </Card.Footer>
                            </Card>

                            <Card className="infoCard">
                                <Card.Body>
                                    <h1>
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    'Produit de certification blockchain'
                                                    : 'Blockchain Certification Product'
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    serviceContent.find(find => find.id === "e246e51f-7213-4b20-b5ee-a282fc3c4a6a").titleFr
                                                    : serviceContent.find(find => find.id === "e246e51f-7213-4b20-b5ee-a282fc3c4a6a").title
                                                )
                                        }
                                    </h1>
                                    <Card.Img className="card-img-dropshadow" variant="top" src={!isLoaded ? "../assets/Implementation Services Assets/BlockchainCertificationProduct.png" : serviceContent.find(find => find.id === "e246e51f-7213-4b20-b5ee-a282fc3c4a6a").image} />
                                    {
                                        !isLoaded ?
                                            (lang === 'fr' ?
                                                <p>
                                                    Automatisez et numérisez en toute sécurité la délivrance des certifications. Gagnez du temps et évitez les tracasseries administratives!
                                                </p>
                                                : <p>
                                                    Securely automate and digitalize the issuance of certifications. Save time and administrative hurdles!
                                                </p>
                                            )
                                            :
                                            (lang === 'fr' ?
                                                serviceContent.find(find => find.id === "e246e51f-7213-4b20-b5ee-a282fc3c4a6a").contentFr
                                                : serviceContent.find(find => find.id === "e246e51f-7213-4b20-b5ee-a282fc3c4a6a").content
                                            )
                                    }
                                </Card.Body>
                                <Card.Footer>
                                    
                                        {
                                            !isLoaded ?
                                            <Button href="https://www.innov-edu.ca/" className='button'>
                                                {(lang === 'fr' ?
                                                    'En savoir plus'
                                                    : 'Find out more'
                                                )}
                                                </Button>
                                                :
                                                <Button href={serviceContent.find(find => find.id === "e246e51f-7213-4b20-b5ee-a282fc3c4a6a").link} className='button'>
                                                {(lang === 'fr' ?
                                                    serviceContent.find(find => find.id === "e246e51f-7213-4b20-b5ee-a282fc3c4a6a").buttonFr
                                                    : serviceContent.find(find => find.id === "e246e51f-7213-4b20-b5ee-a282fc3c4a6a").button
                                                )}
                                                </Button>
                                        }
                                    
                                </Card.Footer>
                            </Card>

                            <Card className="infoCard">
                                <Card.Body>
                                    <h1>
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    'Équipe de développement blockchain'
                                                    : 'Blockchain Development Team'
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    serviceContent.find(find => find.id === "abbb5c21-4e97-43c4-b595-79e4adb8a521").titleFr
                                                    : serviceContent.find(find => find.id === "abbb5c21-4e97-43c4-b595-79e4adb8a521").title
                                                )
                                        }
                                    </h1>
                                    <Card.Img className="card-img-dropshadow" variant="top" src={!isLoaded ? "../assets/Implementation Services Assets/BlockchainDevelopmentTeam.png" : serviceContent.find(find => find.id === "abbb5c21-4e97-43c4-b595-79e4adb8a521").image}/>
                                    {
                                        !isLoaded ?
                                            (lang === 'fr' ?
                                                <p>
                                                    Vous avez besoin de développeurs pour donner vie à votre projet Blockchain ? Laissez-nous vous aider à réunir la bonne équipe pour faire de votre projet une réalité!
                                                </p>
                                                : <p>
                                                    Do you need developers to bring your Blockchain project to life? Let us help you assemble the right team to make your project a reality!
                                                </p>
                                            )
                                            :
                                            (lang === 'fr' ?
                                                serviceContent.find(find => find.id === "abbb5c21-4e97-43c4-b595-79e4adb8a521").contentFr
                                                : serviceContent.find(find => find.id === "abbb5c21-4e97-43c4-b595-79e4adb8a521").content
                                            )
                                    }
                                </Card.Body>
                                <Card.Footer>
                                    <Button href={`/${lang}/contact-us/`} className='button'>
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    'Contactez-nous'
                                                    : 'Contact Us'
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    serviceContent.find(find => find.id === "abbb5c21-4e97-43c4-b595-79e4adb8a521").buttonFr
                                                    : serviceContent.find(find => find.id === "abbb5c21-4e97-43c4-b595-79e4adb8a521").button
                                                )
                                        }
                                    </Button>
                                </Card.Footer>
                            </Card>

                            <Card className="infoCard">
                                <Card.Body>
                                    <h1>
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    'Équipe de marketing et des relations publiques blockchain'
                                                    : 'Blockchain Marketing and PR Team'
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    serviceContent.find(find => find.id === "a83cbffd-ce92-4a92-82b3-eab487e30130").titleFr
                                                    : serviceContent.find(find => find.id === "a83cbffd-ce92-4a92-82b3-eab487e30130").title
                                                )
                                        }
                                    </h1>
                                    <Card.Img className="card-img-dropshadow" variant="top" src={!isLoaded ? "../assets/Implementation Services Assets/BlockchainMarketingPRTeam.png" : serviceContent.find(find => find.id === "a83cbffd-ce92-4a92-82b3-eab487e30130").image}/>
                                    {
                                        !isLoaded ?
                                            (lang === 'fr' ?
                                                <p>
                                                    Vous avez besoin de spécialistes du marketing pour faire connaître votre projet blockchain? Nous pouvons vous aider à constituer une équipe pour apporter de la visibilité à votre projet!
                                                </p>
                                                : <p>
                                                    Do you need marketers to bring awareness to your Blockchain project? We can help you assemble a team to bring visibility to your project!
                                                </p>
                                            )
                                            :
                                            (lang === 'fr' ?
                                                serviceContent.find(find => find.id === "a83cbffd-ce92-4a92-82b3-eab487e30130").contentFr
                                                : serviceContent.find(find => find.id === "a83cbffd-ce92-4a92-82b3-eab487e30130").content
                                            )
                                    }
                                </Card.Body>
                                <Card.Footer>
                                    <Button href={`/${lang}/contact-us/`} className='button'>
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    'Contactez-nous'
                                                    : 'Contact Us'
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    serviceContent.find(find => find.id === "a83cbffd-ce92-4a92-82b3-eab487e30130").buttonFr
                                                    : serviceContent.find(find => find.id === "a83cbffd-ce92-4a92-82b3-eab487e30130").button
                                                )
                                        }
                                    </Button>
                                </Card.Footer>
                            </Card>
                            {/* </Col> */}
                        </CardGroup>
                    </Row>
                </Container>
            </div>
            {/* The following is used to show any popup at this location, there may not be any popups to show */}
            <PopupComponent lang={lang} location="Implementation"/>
        </Container>
    )
}

export default Implementation;