import * as Yup from 'yup';

const ContactSchema = Yup.object().shape({
    name: Yup.string()
        .required("Name is required"),
    email: Yup.string()
        .email("Invalid email address format")
        .required("Email is required"),
    // inputText: Yup.string()
    //     .min(1, "Please enter an inquiry here")
    //     .required("Please look over your inquiry message"),
    inputDrop: Yup.string()
        .required("Please choose an inquiry here")
})

export default ContactSchema;