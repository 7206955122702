import * as Yup from 'yup';

//Yup validation schema
const RegisterConfirmSchema = Yup.object().shape({
  username: Yup.string()
    .required("Username is required"),
  authCode: Yup.number()
    .required("Authentication code is required")
    .typeError("Confirmation code must be a number!"),
})

export default RegisterConfirmSchema;