import "./styling/Home.css"
import React, { useEffect, useState } from 'react';
import { Button, Card, CardGroup, Col, Container, Row } from 'react-bootstrap';
import LogoSlider from './components/LogoSlider'
import SocialMedia from './components/SocialMedia';
import clients from './json_files/Clients.json'
import partners from './json_files/Partners.json'
import 'bootstrap'
import { API, graphqlOperation } from "aws-amplify";
import { listHomes, listVideoEmbeds, listServices } from "./graphql/queries";
import FeaturedBlog from './components/FeaturedBlog';
import HelmetMetaData from './components/HelmetMetaData';
import metaData from "./json_files/metadata.json";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import PopupComponent from "./components/PopupComponent";

function Home() {
    const { lang } = useParams();
    // For the meta data stuff
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [keywords, setKeywords] = useState("");

    //the below is used for storing the content that will be grabbed from the API
    //is loaded is to check if the api is loaded
    //isVidLoaded is to check if video URL is loaded
    const [homeContent, setHomeContent] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isVidLoaded, setIsVidLoaded] = useState(false);
    const [videoEmbed, setVideoEmbed] = useState([]);

    //sets up the api to grab data from the database, dynamoDB in this case
    const setContentAPI = () => {
        //calls the api to qurey the data using the given query
        API.graphql(graphqlOperation(listHomes))
            .then((data) => {
                //stores list of data in a variable to be manipulated later
                const modifiedData2 = data["data"]["listHomes"]["items"];
                //following map function to parse the content field into a usable form
                modifiedData2.map((item) => {
                    item.content = parse(item.content);
                    item.contentFr = parse(item.contentFr);
                    return item;
                });
                //set the data grabbed from the api and set the variable used later
                setHomeContent(modifiedData2);
                //lets the app know that the data has loaded and can be rendered
                setIsLoaded(true);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // needs separate api function for grabbing video url or a crash is caused
    const setVideoAPI = () => {
        API.graphql(graphqlOperation(listVideoEmbeds))
            .then((data) => {
                const modifiedData3 = data["data"]["listVideoEmbeds"]["items"];

                setVideoEmbed(modifiedData3);
                //Lets the app know that the video URL data is laoded, we need seperate load variables for the site not to crash
                setIsVidLoaded(true);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // Get location metadata from JSON file, this system maybe used in the future so that
    // non-developers can edit metadata.
    const getMeta = (data) => {
        if (data.url === window.location.pathname.split("/")[2]) {
            setTitle(data.title);
            setDescription(data.description);
            setKeywords(data.keywords);
        }
    };

    const [isCourseLoaded, setIsCourseLoaded] = useState(false);
    const [serviceContent, setServiceContent] = useState([]);

    const setCourseContentAPI = () => {
        API.graphql(graphqlOperation(listServices))
            .then((data) => {
                const modifiedData2 = data["data"]["listServices"]["items"];

                modifiedData2.map((item) => {
                    item.content = parse(item.content);
                    item.contentFr = parse(item.contentFr);
                    return item;
                });

                setServiceContent(modifiedData2);
                setIsCourseLoaded(true);
                // console.log("content education: ", serviceContent.find(find => find.id === "4c769797-8af0-4a49-b30a-6d577e8eb9d4"));
            })
            .catch((err) => {
                console.log(err);
            });
    }

    //list of class names to disable right-click
    const imageClasses = [".card-img-dropshadow", ".primaryElement-img", ".card-img-top"];

    //remove the context menu for the images
    const removeRightClick = () => {
        imageClasses.forEach(imgClass => {
            const noContextMenu = document.querySelectorAll(imgClass);
            noContextMenu.forEach(data => {
                data.addEventListener("contextmenu", e => e.preventDefault());
            })
        })
    }

    useEffect(() => {
        setContentAPI();
        setVideoAPI();
        setCourseContentAPI();
        removeRightClick();
        // noContextMenu.addEventListener("contextmenu", e => e.preventDefault());
        metaData.forEach(data => {
            getMeta(data);
        });
    }, [])

    return (
        <div>
            {/* Use helmet metadata to generate metadata */}
            <HelmetMetaData
                title={title}
                description={description}
                keywords={keywords}>
            </HelmetMetaData>
            <Container>

                {/* The first section of the website, includes the title and image*/}
                <div className='sect1'>
                    <Container className='headerElement'>
                        <Row className="align-items-center">
                            <Col id="text-element" md={12} lg={7}>
                                {/* English and french text for the first section */}

                                <div className='sect1txt'>
                                    <h1>
                                        {//checks if api is loaded, if not, use back up text
                                            !isLoaded ? (
                                                lang === 'fr' ?
                                                    'Nous vous apportons la stratégie, la mise en œuvre et l\'éducation de la Blockchain'
                                                    :
                                                    'We provide Blockchain Strategy, Implementation, and Education'
                                            ) : (
                                                lang === 'fr' ?
                                                    homeContent.find(find => find.id === "be497ca5-6e43-497d-88d1-57634d593ac2").titleFr
                                                    : homeContent.find(find => find.id === "be497ca5-6e43-497d-88d1-57634d593ac2").title
                                            )
                                        }
                                    </h1>
                                    {//checks if api is loaded, if not, use back up text
                                        !isLoaded ? (
                                            lang === 'fr' ?
                                                <p>Nous vous apportons la stratégie, la mise en œuvre et l'éducation de la Blockchain</p>
                                                :
                                                <p>We provide Blockchain Strategy, Implementation, and Education</p>
                                        ) : (
                                            lang === 'fr' ?
                                                //remove p later
                                                homeContent.find(find => find.id === "be497ca5-6e43-497d-88d1-57634d593ac2").contentFr
                                                : homeContent.find(find => find.id === "be497ca5-6e43-497d-88d1-57634d593ac2").content
                                        )
                                    }
                                    <Button href={`/${lang}/contact-us/`} className='button'>
                                        {!isLoaded ?
                                            (lang === 'fr' ?
                                                'Demander une démo'
                                                : 'Request a Demo'
                                            )
                                            : (lang === 'fr' ?
                                                homeContent.find(find => find.id === "be497ca5-6e43-497d-88d1-57634d593ac2").buttonFr
                                                : homeContent.find(find => find.id === "be497ca5-6e43-497d-88d1-57634d593ac2").button
                                            )
                                        }
                                    </Button>
                                </div>
                            </Col>
                            <Col id="image-element" md={12} lg={5}>
                                {/* <img src="../assets//home Page Assets/Header.svg" alt='' /> */}
                                {/* <svg src='../assets//home Page Assets/Header2.svg' alt=''/> */}
                                {/* <object aria-labelledby='header img' type="image/svg+xml" data="../assets//home Page Assets/Header2.svg" alt=''></object> */}

                                {/* The animated SVG used for the header, this causes some errors in the console, but it doesnt seem to affect anything */}
                                <object className="animation-svg" aria-labelledby='header img' type="image/svg+xml" data="/assets/Home Page Assets/Header2.svg" alt='Blockchain'></object>
                            </Col>
                        </Row>
                    </Container>

                    {/* Info container containing the cards */}
                    <div className='infoContainer'>
                        <h1>
                            {
                                !isLoaded ?
                                    (lang === 'fr' ?
                                        'Services de conseil'
                                        : 'Consulting Services'
                                    )
                                    :
                                    (lang === 'fr' ?
                                        homeContent.find(find => find.id === "499a8f48-ec5c-43ec-9e38-d764844c47b6").titleFr
                                        : homeContent.find(find => find.id === "499a8f48-ec5c-43ec-9e38-d764844c47b6").title
                                    )
                            }
                        </h1>
                        <div className="infoContainer-description">
                            {
                                !isLoaded ?
                                    (lang === 'fr' ?
                                        <p>Notre équipe s'engage à assurer la qualité de tous nos services. Prenez rendez-vous dès aujourd'hui avec nos représentants pour voir comment Blockchain peut bénéficier à votre entreprise ou organisation.</p>
                                        : <p>Our team commits to quality in all our services. Book a time today with our representatives to see how Blockchain can benefit your business or organization.</p>
                                    )
                                    :
                                    (lang === 'fr' ?
                                        homeContent.find(find => find.id === "499a8f48-ec5c-43ec-9e38-d764844c47b6").contentFr
                                        : homeContent.find(find => find.id === "499a8f48-ec5c-43ec-9e38-d764844c47b6").content
                                    )
                            }
                        </div>

                        {/* Container for the cards */}
                        <Container className="serviceContainer" style={{ paddingLeft: '1%', paddingRight: '1%' }}>
                            {/* Create a grouping of cards with the same styling */}
                            <CardGroup className="justify-content-md-center" >
                                {/* Card element for each card to display the data needed
                                a future implmentation could probably use a map feature to make this more efficient */}
                                <Card className="infoCard" >
                                    <Card.Body>
                                        <Card.Img id="img1" className="card-img-dropshadow" variant="top" alt="innovfin education" src={!isLoaded ? "../assets/Home Page Assets/Education.png" : homeContent.find(find => find.id === "73f1fbbf-4286-4916-8d73-a7271787fe49").image} style={{ padding: '0' }} />
                                        <h1>
                                            {
                                                !isLoaded ?
                                                    (lang === 'fr' ?
                                                        'Éducation'
                                                        : 'Education'
                                                    )
                                                    :
                                                    (lang === 'fr' ?
                                                        homeContent.find(find => find.id === "73f1fbbf-4286-4916-8d73-a7271787fe49").titleFr
                                                        : homeContent.find(find => find.id === "73f1fbbf-4286-4916-8d73-a7271787fe49").title
                                                    )
                                            }
                                        </h1>
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    <p>Formation Blockchain en ligne et hors ligne pour vos cadres, dirigeants, employés et clients.</p>
                                                    : <p>Online and Off-line Blockchain education for your executives, managers, employees and clients.</p>
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    homeContent.find(find => find.id === "73f1fbbf-4286-4916-8d73-a7271787fe49").contentFr
                                                    : homeContent.find(find => find.id === "73f1fbbf-4286-4916-8d73-a7271787fe49").content
                                                )
                                        }
                                    </Card.Body>
                                    <Card.Footer >
                                        <Button className='button' href={`/${lang}/Education/`}>
                                            {
                                                !isLoaded ?
                                                    (lang === 'fr' ?
                                                        'En savoir plus'
                                                        : 'Find out More'
                                                    )
                                                    :
                                                    (lang === 'fr' ?
                                                        homeContent.find(find => find.id === "73f1fbbf-4286-4916-8d73-a7271787fe49").buttonFr
                                                        : homeContent.find(find => find.id === "73f1fbbf-4286-4916-8d73-a7271787fe49").button
                                                    )
                                            }
                                        </Button>
                                    </Card.Footer>
                                </Card>
                                <Card className="infoCard">
                                    <Card.Body>
                                        <Card.Img className="card-img-dropshadow" variant="top" src={!isLoaded ? "../assets/Home Page Assets/Strategy.png" : homeContent.find(find => find.id === "9b3d40e7-18ef-4a2a-9033-03024e79eedc").image} style={{ padding: '0' }} />
                                        <h1>
                                            {
                                                !isLoaded ?
                                                    (lang === 'fr' ?
                                                        'Stratégie'
                                                        : 'Strategy'
                                                    )
                                                    :
                                                    (lang === 'fr' ?
                                                        homeContent.find(find => find.id === "9b3d40e7-18ef-4a2a-9033-03024e79eedc").titleFr
                                                        : homeContent.find(find => find.id === "9b3d40e7-18ef-4a2a-9033-03024e79eedc").title
                                                    )
                                            }
                                        </h1>
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    <p>Des conceptions stratégiques qui intègrent les technologies de rupture afin que votre entreprise reste pertinente pour les années à venir.</p>
                                                    : <p>Strategic designs that integrate disruptive technologies to keep your business relevant for years to come.</p>
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    homeContent.find(find => find.id === "9b3d40e7-18ef-4a2a-9033-03024e79eedc").contentFr
                                                    : homeContent.find(find => find.id === "9b3d40e7-18ef-4a2a-9033-03024e79eedc").content
                                                )
                                        }
                                    </Card.Body>
                                    <Card.Footer >
                                        <Button className='button' href={`/${lang}/Strategy/`}>
                                            {
                                                !isLoaded ?
                                                    (lang === 'fr' ?
                                                        'En savoir plus'
                                                        : 'Find out More'
                                                    )
                                                    :
                                                    (lang === 'fr' ?
                                                        homeContent.find(find => find.id === "9b3d40e7-18ef-4a2a-9033-03024e79eedc").buttonFr
                                                        : homeContent.find(find => find.id === "9b3d40e7-18ef-4a2a-9033-03024e79eedc").button
                                                    )
                                            }
                                        </Button>
                                    </Card.Footer>
                                </Card>
                                <Card className="infoCard">
                                    <Card.Body>
                                        <Card.Img className="card-img-dropshadow" variant="top" src={!isLoaded ? "../assets/Home Page Assets/Implementation.png" : homeContent.find(find => find.id === "6dc73b0c-2227-41dc-bb5b-3ac28fc3d5f3").image} style={{ padding: '0' }} />
                                        <h1>
                                            {
                                                !isLoaded ?
                                                    (lang === 'fr' ? 'Mise en œuvre'
                                                        : 'Implementation'
                                                    )
                                                    :
                                                    (lang === 'fr' ?
                                                        homeContent.find(find => find.id === "6dc73b0c-2227-41dc-bb5b-3ac28fc3d5f3").titleFr
                                                        : homeContent.find(find => find.id === "6dc73b0c-2227-41dc-bb5b-3ac28fc3d5f3").title
                                                    )
                                            }
                                        </h1>
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    <p>Construisez votre projet Blockchain et accédez à des solutions de marketing, de développement et de financement alternatif!</p>
                                                    : <p>Build your Blockchain project and gain access to marketing, development, and alternative financing solutions!</p>
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    homeContent.find(find => find.id === "6dc73b0c-2227-41dc-bb5b-3ac28fc3d5f3").contentFr
                                                    : homeContent.find(find => find.id === "6dc73b0c-2227-41dc-bb5b-3ac28fc3d5f3").content
                                                )
                                        }
                                    </Card.Body>
                                    <Card.Footer >
                                        <Button className='button' href={`/${lang}/Implementation/`}>
                                            {
                                                !isLoaded ?
                                                    (lang === 'fr' ?
                                                        'En savoir plus'
                                                        : 'Find out More'
                                                    )
                                                    :
                                                    (lang === 'fr' ?
                                                        homeContent.find(find => find.id === "6dc73b0c-2227-41dc-bb5b-3ac28fc3d5f3").buttonFr
                                                        : homeContent.find(find => find.id === "6dc73b0c-2227-41dc-bb5b-3ac28fc3d5f3").button
                                                    )
                                            }
                                        </Button>
                                    </Card.Footer>
                                </Card>
                                <Card className="infoCard">
                                    <Card.Body>
                                        <Card.Img className="card-img-dropshadow" variant="top" src={!isLoaded ? "../assets/Home Page Assets/Book_a_Blockchain_Speaker.png" : homeContent.find(find => find.id === "eb8c391b-0e80-41b6-ae40-94e45087ad78").image} style={{ padding: '0' }} />
                                        <h1>
                                            {
                                                !isLoaded ?
                                                    (lang === 'fr' ? 'Réserver un conférencier spécialiste de la Blockchain'
                                                        : 'Book a Blockchain Speaker'
                                                    )
                                                    :
                                                    (lang === 'fr' ?
                                                        homeContent.find(find => find.id === "eb8c391b-0e80-41b6-ae40-94e45087ad78").titleFr
                                                        : homeContent.find(find => find.id === "eb8c391b-0e80-41b6-ae40-94e45087ad78").title
                                                    )
                                            }
                                        </h1>
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    <p>Réservez un conférencier spécialiste de la Blockchain pour les événements organisés par votre entreprise, tels que des conférences, des événements d'appréciation et des retraites de cadres!</p>
                                                    : <p>Book a Blockchain speaker for your corporate &amp; organizational events! Have a Blockchain speaker attend your next conference, appreciation event, or executive retreats!</p>
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    homeContent.find(find => find.id === "eb8c391b-0e80-41b6-ae40-94e45087ad78").contentFr
                                                    : homeContent.find(find => find.id === "eb8c391b-0e80-41b6-ae40-94e45087ad78").content
                                                )
                                        }
                                    </Card.Body>
                                    <Card.Footer >
                                        <Button className='button' href={`/${lang}/BookSpeaker/`}>
                                            {
                                                !isLoaded ?
                                                    (lang === 'fr' ?
                                                        'En savoir plus'
                                                        : 'Find out More'
                                                    )
                                                    :
                                                    (lang === 'fr' ?
                                                        homeContent.find(find => find.id === "eb8c391b-0e80-41b6-ae40-94e45087ad78").buttonFr
                                                        : homeContent.find(find => find.id === "eb8c391b-0e80-41b6-ae40-94e45087ad78").button
                                                    )
                                            }
                                        </Button>
                                    </Card.Footer>
                                </Card>
                            </CardGroup>
                        </Container>
                    </div>
                </div>

            </Container>

            {/* <Container
            //below code is for revealing/hide premium feature
            //container={container.current}
            >
                <div>
                    <h1 style={{ padding: ".5em 0em" }}>
                        {
                            !isLoaded ?
                                null
                                :
                                (lang === 'fr' ?
                                    homeContent.find(find => find.id === "a550f997-fc09-4081-84a5-5c637905a6ff").titleFr
                                    : homeContent.find(find => find.id === "a550f997-fc09-4081-84a5-5c637905a6ff").title
                                )
                        }
                    </h1>
                </div>
                <div className="infoContainer-description">
                    {
                        !isLoaded ?
                            (lang === 'fr' ?
                                <p>Notre équipe s'engage à assurer la qualité de tous nos services. Prenez rendez-vous dès aujourd'hui avec nos représentants pour voir comment Blockchain peut bénéficier à votre entreprise ou organisation.</p>
                                : <p>Our team commits to quality in all our services. Book a time today with our representatives to see how Blockchain can benefit your business or organization.</p>
                            )
                            :
                            (lang === 'fr' ?
                                homeContent.find(find => find.id === "a550f997-fc09-4081-84a5-5c637905a6ff").contentFr
                                : homeContent.find(find => find.id === "a550f997-fc09-4081-84a5-5c637905a6ff").content
                            )
                    }
                </div>
                <CardGroup style={{ marginTop: "50px" }} className="justify-content-md-center">

                    <Card className="infoCard">
                        <Card.Body>
                            <h1>{
                                !isCourseLoaded ?
                                    (lang === 'fr' ?
                                        'Cours en ligne premium'
                                        : 'Premium Course'
                                    )
                                    :
                                    (lang === 'fr' ?
                                        serviceContent.find(find => find.id === "52657067-d79d-459e-96ba-142bca33a347").titleFr
                                        : serviceContent.find(find => find.id === "52657067-d79d-459e-96ba-142bca33a347").title
                                    )
                            }
                            </h1>
                            <Card.Img className="card-img-dropshadow" variant="top" src={!isCourseLoaded ? "./assets/Education Services Assets/FreeOnlineCourses.png" : serviceContent.find(find => find.id === "52657067-d79d-459e-96ba-142bca33a347").image} />
                            {
                                !isCourseLoaded ?
                                    (lang === 'fr' ?
                                        <p>
                                            <span>Bientôt disponible!<br /><br />Inscrivez-vous à notre lettre d\'information pour recevoir des informations sur nos progrès.</span>
                                        </p>
                                        : <p>
                                            <span>Coming Soon!<br /><br />Subscribe to our newsletter to receive updates about our progress.</span>
                                        </p>
                                    )
                                    :
                                    (lang === 'fr' ?
                                        serviceContent.find(find => find.id === "52657067-d79d-459e-96ba-142bca33a347").contentFr
                                        : serviceContent.find(find => find.id === "52657067-d79d-459e-96ba-142bca33a347").content
                                    )
                            }
                        </Card.Body>
                        <Card.Footer>

                            {
                                !isCourseLoaded ?
                                    (<Button
                                        className='button'
                                        href=""
                                    >
                                        {lang === 'fr' ?
                                            'Abonnez-vous'
                                            : 'Check it Out!'}
                                    </Button>
                                    )
                                    :
                                    (<Button
                                        className='button'
                                        href={serviceContent.find(find => find.id === "52657067-d79d-459e-96ba-142bca33a347").link}
                                    >
                                        {lang === 'fr' ?
                                            serviceContent.find(find => find.id === "52657067-d79d-459e-96ba-142bca33a347").buttonFr
                                            : serviceContent.find(find => find.id === "52657067-d79d-459e-96ba-142bca33a347").button}
                                    </Button>
                                    )
                            }
                        </Card.Footer>
                    </Card>

                    <Card className="infoCard">
                        <Card.Body>
                            <h1>
                                {
                                    !isCourseLoaded ?
                                        (lang === 'fr' ?
                                            'Cours en ligne premium'
                                            : 'Premium Online Courses'
                                        )
                                        :
                                        (lang === 'fr' ?
                                            serviceContent.find(find => find.id === "5a8feff9-e6b6-4736-b661-d45195dd66e8").titleFr
                                            : serviceContent.find(find => find.id === "5a8feff9-e6b6-4736-b661-d45195dd66e8").title
                                        )
                                }
                            </h1>
                            <Card.Img className="card-img-dropshadow" variant="top" src={!isCourseLoaded ? "./assets/Education Services Assets/PremiumOnlineCourses.png" : serviceContent.find(find => find.id === "5a8feff9-e6b6-4736-b661-d45195dd66e8").image} />
                            {
                                !isCourseLoaded ?
                                    (lang === 'fr' ?
                                        <p>
                                            <span>Bientôt disponibl!<br /><br />Inscrivez-vous à notre lettre d\'information pour recevoir des informations sur nos progrès.</span>
                                        </p>
                                        : <p>
                                            <span>Coming Soon!<br /><br />Subscribe to our newsletter to receive updates about our progress.</span>
                                        </p>
                                    )
                                    :
                                    (lang === 'fr' ?
                                        serviceContent.find(find => find.id === "5a8feff9-e6b6-4736-b661-d45195dd66e8").contentFr
                                        : serviceContent.find(find => find.id === "5a8feff9-e6b6-4736-b661-d45195dd66e8").content
                                    )
                            }
                        </Card.Body>
                        <Card.Footer>
                            {
                                !isCourseLoaded ?
                                    (<Button
                                        className='button'
                                        href="https://www.innov-edu.ca/"
                                    >
                                        {lang === 'fr' ?
                                            'Abonnez-vous'
                                            : 'Subscribe'}
                                    </Button>
                                    )
                                    :
                                    (<Button
                                        className='button'
                                        href={serviceContent.find(find => find.id === "5a8feff9-e6b6-4736-b661-d45195dd66e8").link}
                                    >
                                        {lang === 'fr' ?
                                            serviceContent.find(find => find.id === "5a8feff9-e6b6-4736-b661-d45195dd66e8").buttonFr
                                            : serviceContent.find(find => find.id === "5a8feff9-e6b6-4736-b661-d45195dd66e8").button}
                                    </Button>
                                    )
                            }
                        </Card.Footer>
                    </Card>

                    <Card className="infoCard">
                        <Card.Body>
                            <h1>
                                {
                                    !isCourseLoaded ?
                                        (lang === 'fr' ?
                                            'Éducation hors ligne'
                                            : 'Bundle Up'
                                        )
                                        :
                                        (lang === 'fr' ?
                                            serviceContent.find(find => find.id === "dad11d3f-e859-4c2c-b0e5-c75f836dda9e").titleFr
                                            : serviceContent.find(find => find.id === "dad11d3f-e859-4c2c-b0e5-c75f836dda9e").title
                                        )
                                }
                            </h1>
                            <Card.Img className="card-img-dropshadow" variant="top" src={!isCourseLoaded ? "./assets/Education Services Assets/OfflineEducation.png" : serviceContent.find(find => find.id === "dad11d3f-e859-4c2c-b0e5-c75f836dda9e").image} />
                            {
                                !isCourseLoaded ?
                                    (lang === 'fr' ?
                                        <p>
                                            Vous cherchez à organiser une session de formation en personne sur la blockchain pour vos clients, cadres ou employés ? Contactez-nous dès aujourd\'hui pour discuter de vos besoins!
                                        </p>
                                        : <p>Looking to hold an in-person education session for your clients, executives or employees on Blockchain? Contact us today to discuss your needs!
                                        </p>
                                    )
                                    :
                                    (lang === 'fr' ?
                                        serviceContent.find(find => find.id === "dad11d3f-e859-4c2c-b0e5-c75f836dda9e").contentFr
                                        : serviceContent.find(find => find.id === "dad11d3f-e859-4c2c-b0e5-c75f836dda9e").content
                                    )
                            }
                        </Card.Body>
                        <Card.Footer>

                            {
                                !isCourseLoaded ?
                                    (<Button href={`/${lang}/contact-us/`} className='button'>
                                        {lang === 'fr' ?
                                            'Contactez-nous'
                                            : 'Check it Out'}
                                    </Button>
                                    )
                                    :
                                    (<Button href={serviceContent.find(find => find.id === "dad11d3f-e859-4c2c-b0e5-c75f836dda9e").link} className='button'>
                                        {lang === 'fr' ?
                                            serviceContent.find(find => find.id === "dad11d3f-e859-4c2c-b0e5-c75f836dda9e").buttonFr
                                            : serviceContent.find(find => find.id === "dad11d3f-e859-4c2c-b0e5-c75f836dda9e").button}

                                    </Button>
                                    )
                            }
                        </Card.Footer>
                    </Card>
                </CardGroup>
            </Container> */}

            {/* The section containing the youtube embed */}
            <div className='sect2'>
                <Container className='primaryElement-video primaryElement-Var1'>
                    <Row className="align-items-center">
                        {/* First column is the iFrame displaying the youtube video */}
                        <Col md={12} lg={6} >
                            <div className='player-wrapper'>
                                <video
                                    onContextMenu={(e) => e.preventDefault()}
                                    className="react-player"
                                    poster={!isVidLoaded ? null : lang === "fr" ?
                                        videoEmbed.find(find => find.id === "ea19d645-4801-47b1-8773-6da54539431f").thumbnail :
                                        videoEmbed.find(find => find.id === "8b5ec8a8-4a75-493f-90ea-bcc10a03becd").thumbnail}
                                    title="Video"
                                    width="100%"
                                    height="100%"
                                    src={
                                        !isVidLoaded ?
                                            'https://www.youtube.com/embed/4qhGefsF9-4' :
                                            lang === "fr" ?
                                                videoEmbed.find(find => find.id === "ea19d645-4801-47b1-8773-6da54539431f").url.replace("watch?v=", "embed/") + "?rel=0" :
                                                videoEmbed.find(find => find.id === "8b5ec8a8-4a75-493f-90ea-bcc10a03becd").url.replace("watch?v=", "embed/") + "?rel=0"
                                    }
                                    frameBorder="0"
                                    // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    controls="controls"
                                    controlsList="nodownload"
                                />
                            </div>
                        </Col>
                        {/* second column displays the data/information related to the video */}
                        <Col className="sect2-txt" md={12} lg={6}>
                            <h1>
                                {/* {lang === 'fr' ? 'À propos d\'Innovfin' : 'About Innovfin'} */}
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            'À propos d\'Innovfin'
                                            : 'About Innovfin'
                                        )
                                        :
                                        (lang === 'fr' ?
                                            homeContent.find(find => find.id === "cf85cc8d-5431-4b40-ba44-0ca75e9b47e5").titleFr
                                            : homeContent.find(find => find.id === "cf85cc8d-5431-4b40-ba44-0ca75e9b47e5").title
                                        )
                                }
                            </h1>
                            {
                                !isLoaded ?
                                    (lang === 'fr' ?
                                        <p>
                                            <span>Quelques mots de bienvenue de notre directrice générale, Othalia Doe-Bruce.<br /><br />InnovFin est spécialisée dans la technologie Blockchain et peut fournir des
                                                capacités en matière d'intelligence artificielle (IA), d'Internet des objets (IdO) et de réalité augmentée (AR)/réalité virtuelle (VR).<br /><br />Parlez-nous dès
                                                aujourd'hui de la façon dont nous pouvons soutenir votre croissance pour vous mettre sur une voie solide vers le succès et le profit.
                                            </span>
                                        </p>
                                        : <p>
                                            <span>
                                                A few welcoming words from our CEO, Othalia Doe-Bruce!
                                                <br /><br />
                                                InnovFin specializes in Blockchain technology and can provide capabilities in Artificial Intelligence (AI), Internet of Things (IoT), and Augmented Reality (AR)/Virtual Reality (VR).
                                                <br /><br />
                                                Talk to us today about how we can support your growth to put you on a solid track to success and profit.
                                            </span>
                                        </p>
                                    )
                                    :
                                    (lang === 'fr' ?
                                        homeContent.find(find => find.id === "cf85cc8d-5431-4b40-ba44-0ca75e9b47e5").contentFr
                                        : homeContent.find(find => find.id === "cf85cc8d-5431-4b40-ba44-0ca75e9b47e5").content
                                    )
                            }
                            <Button className='button' style={{ float: 'left' }} href={`/${lang}/About/`}>
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            'En savoir plus sur InnovFin'
                                            : 'Learn More About InnovFin'
                                        )
                                        :
                                        (lang === 'fr' ?
                                            homeContent.find(find => find.id === "cf85cc8d-5431-4b40-ba44-0ca75e9b47e5").buttonFr
                                            : homeContent.find(find => find.id === "cf85cc8d-5431-4b40-ba44-0ca75e9b47e5").button
                                        )
                                }
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Section about Blockchain, uses same styling as youtubeplayer section */}
            <div className='sect2'>
                <Container className='primaryElement primaryElement-Var1'>
                    <Row className="align-items-center">
                        <Col className="sect2-txt" id="text-element" md={12} lg={6}>
                            <h1>{
                                !isLoaded ?
                                    (lang === 'fr' ?
                                        'Qu\'est-ce que la Blockchain?'
                                        : 'What is Blockchain?'
                                    )
                                    :
                                    (lang === 'fr' ?
                                        homeContent.find(find => find.id === "0c46d4d5-2757-4049-8fd2-f80d9d0f9801").titleFr
                                        : homeContent.find(find => find.id === "0c46d4d5-2757-4049-8fd2-f80d9d0f9801").title
                                    )
                            }</h1>
                            {
                                !isLoaded ?
                                    (lang === 'fr' ?
                                        <p>
                                            <span>Blockchain est une nouvelle technologie révolutionnaire qui bouleverse la façon dont les personnes et les entreprises interagissent entre elles. <br /><br />
                                                Surnommé le "Nouvel Internet", il ne s'agit pas seulement de la technologie derrière les crypto-monnaies comme le Bitcoin, mais aussi d'une technologie émergente
                                                apportant de nouveaux niveaux d'efficacité opérationnelle, de transparence et de confiance, de réduction des coûts ; et des sources alternatives de financement
                                                dans les domaines de la fabrication et de la chaîne d'approvisionnement, des paiements de fidélité, des soins de santé, de la finance et plus.....
                                            </span>
                                        </p>
                                        :
                                        <p>
                                            <span>Blockchain is a revolutionary new technology that disrupts the way people and businesses interact with each other.
                                                <br /><br />
                                                Dubbed the "New Internet," Blockchain is not only the technology behind Cryptocurrencies such as Bitcoin,
                                                but also the emerging technology bringing new levels of operational efficiency, transparency, trust,
                                                cost reduction, and alternative sources of financing in businesses!
                                                <br /><br />
                                                Blockchain technology can be implemented in various industries such as Manufacturing and Supply Chains, Health Care, Finance, and more!
                                            </span>
                                        </p>
                                    )
                                    :
                                    (lang === 'fr' ?
                                        homeContent.find(find => find.id === "0c46d4d5-2757-4049-8fd2-f80d9d0f9801").contentFr
                                        : homeContent.find(find => find.id === "0c46d4d5-2757-4049-8fd2-f80d9d0f9801").content
                                    )
                            }
                            <Button href={`/${lang}/Blog/`} className='button' style={{ float: 'left' }}>
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            'En savoir plus'
                                            : 'Learn More'
                                        )
                                        :
                                        (lang === 'fr' ?
                                            homeContent.find(find => find.id === "0c46d4d5-2757-4049-8fd2-f80d9d0f9801").buttonFr
                                            : homeContent.find(find => find.id === "0c46d4d5-2757-4049-8fd2-f80d9d0f9801").button
                                        )
                                }
                            </Button>
                        </Col>
                        <Col id="image-element" md={12} lg={6}>
                            <div className="primaryElement-img">
                                <img
                                    src={!isLoaded ? "../assets/Home Page Assets/Blockchain_Infographic-02.png" : homeContent.find(find => find.id === "0c46d4d5-2757-4049-8fd2-f80d9d0f9801").image} alt='Blockchain'
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Section about Innov-Edu */}
            <div className='sect2'>
                <Container className='primaryElement primaryElement-Var2'>
                    <Row className="align-items-center">
                        <Col className="sect2-txt" id="text-element" md={12} lg={6}>
                            <h1>
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            'Plate-forme d\'apprentissage interactive Innov-Edu'
                                            : 'Innov-Edu Interactive Learning Platform'
                                        )
                                        :
                                        (lang === 'fr' ?
                                            homeContent.find(find => find.id === "e38204ab-0f9e-4a5b-a269-e737af65500a").titleFr
                                            : homeContent.find(find => find.id === "e38204ab-0f9e-4a5b-a269-e737af65500a").title
                                        )
                                }
                            </h1>
                            {
                                !isLoaded ?
                                    (lang === 'fr' ?
                                        <p>
                                            <span>Innov-Edu est la plateforme de choix pour les employeurs qui cherchent à améliorer les compétences de leurs employés ou de les réorienter vers une nouvelle formation avec les compétences essentielles de l'industrie 4.0.
                                                <br /><br />
                                                Nous élaborons une liste exhaustive de cours pour doter les employés du savoir-faire technologique émergent, afin que les organisations puissent innover, automatiser et motiver.
                                                <br /><br />
                                                Tous nos cours sont disponibles en ligne, à la demande, et sont accessibles depuis votre ordinateur portable, votre tablette, votre téléphone et votre casque de réalité virtuelle.
                                            </span>
                                        </p>
                                        :
                                        <p>
                                            <span>Innov-Edu is the platform of choice for employers looking to upskill and reskill their employees with Industry 4.0 essential skills.
                                                <br /><br />
                                                We are building an extensive list of courses to equip employees with emerging tech know-how, so organizations can innovate, automate, and motivate.
                                                <br /><br />
                                                All of our courses are available online, on-demand, and can be accessed from your laptop, your tablet, your phone, and on your virtual reality headset.
                                            </span>
                                        </p>
                                    )
                                    :
                                    (lang === 'fr' ?
                                        homeContent.find(find => find.id === "e38204ab-0f9e-4a5b-a269-e737af65500a").contentFr
                                        : homeContent.find(find => find.id === "e38204ab-0f9e-4a5b-a269-e737af65500a").content
                                    )
                            }
                            <Button className='button btn-2' style={{ float: 'left' }} href='https://www.innov-edu.ca/'>
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            'En savoir plus'
                                            : 'Learn More'
                                        )
                                        :
                                        (lang === 'fr' ?
                                            homeContent.find(find => find.id === "e38204ab-0f9e-4a5b-a269-e737af65500a").buttonFr
                                            : homeContent.find(find => find.id === "e38204ab-0f9e-4a5b-a269-e737af65500a").button
                                        )
                                }
                            </Button>
                        </Col>
                        <Col id="image-element" md={12} lg={6}>
                            <div className="primaryElement-img">
                                <img id="innov-edu-img" src={!isLoaded ? "../assets/Home Page Assets/EdTechIcon.png" : homeContent.find(find => find.id === "e38204ab-0f9e-4a5b-a269-e737af65500a").image} width="70%" alt='Edtech platform' />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* 
            Client's words section uses styling of section 2 and another class for a third section
            Include infocards similar to those found above
            */}
            <div className='sect2 sect3' id="Client">
                <h1>{
                    !isLoaded ?
                        (lang === 'fr' ?
                            'Ce que disent nos clients'
                            : 'What Our Client\'s Say'
                        )
                        :
                        (lang === 'fr' ?
                            homeContent.find(find => find.id === "0e2250e6-0d80-442b-9e3c-82c667f1d4dc").titleFr
                            : homeContent.find(find => find.id === "0e2250e6-0d80-442b-9e3c-82c667f1d4dc").title
                        )
                }
                </h1>
                <div className="infoContainer-description">
                    {
                        !isLoaded ?
                            (lang === 'fr' ?
                                <p>Lisez ce que nos clients disent d'InnovFin ! Prêt à commencer votre voyage vers la Blockchain? Prenez rendez-vous avec nous dès aujourd'hui.</p>
                                : <p>Read about what our clients say about InnovFin! Ready to get started on your Blockchain journey? Book an appointment with us today.</p>
                            )
                            :
                            (lang === 'fr' ?
                                homeContent.find(find => find.id === "0e2250e6-0d80-442b-9e3c-82c667f1d4dc").contentFr
                                : homeContent.find(find => find.id === "0e2250e6-0d80-442b-9e3c-82c667f1d4dc").content
                            )
                    }
                </div>
                {/* Cards with the client's testimony */}
                <Container style={{ paddingLeft: '3%', paddingRight: '3%', paddingBottom: '5rem' }}>
                    <Row className="justify-content-md-center">
                        <CardGroup className="justify-content-md-center">
                            <Card className="infoCard" style={{ margin: '2em' }}>
                                <Card.Body>
                                    <Card.Img className="card-img-dropshadow" variant="top" src={!isLoaded ? "../assets/Home Page Assets/TestimonyImage1.png" : homeContent.find(find => find.id === "2949a555-44f2-40d8-8770-0af3bb4925fc").image} style={{ maxHeight: '20em ', maxWidth: '20em' }} />
                                    <h1>{
                                        !isLoaded ?
                                            ("Vancouver, BC(Canada)")
                                            :
                                            (homeContent.find(find => find.id === "2949a555-44f2-40d8-8770-0af3bb4925fc").title)
                                    }
                                    </h1>
                                    {
                                        !isLoaded ?
                                            (lang === 'fr' ?
                                                <p>Je ne sais pas ce que c'est, mais les mots que vous utilisez m'ont fait comprendre pour la toute première fois ce qu'est Blockchain. Merci pour cela !</p>
                                                : <p>I don't know what it is, but the words you use made me understand for the very first time what Blockchain is. Thanks for that!</p>
                                            )
                                            :
                                            (lang === 'fr' ?
                                                homeContent.find(find => find.id === "2949a555-44f2-40d8-8770-0af3bb4925fc").contentFr
                                                : homeContent.find(find => find.id === "2949a555-44f2-40d8-8770-0af3bb4925fc").content
                                            )
                                    }
                                </Card.Body>
                            </Card>

                            <Card className="infoCard" style={{ margin: '2em' }}>
                                <Card.Body>
                                    <Card.Img className="card-img-dropshadow" variant="top" src={!isLoaded ? "../assets/Home Page Assets/TestimonyImage2.png" : homeContent.find(find => find.id === "936f143b-bfa8-46ba-9376-f1c2b2b6e433").image} style={{ maxHeight: '20em ', maxWidth: '20em' }} />
                                    <h1>{
                                        !isLoaded ?
                                            ("Toronto, ON (Canada)")
                                            :
                                            (homeContent.find(find => find.id === "936f143b-bfa8-46ba-9376-f1c2b2b6e433").title)
                                    }
                                    </h1>
                                    {
                                        !isLoaded ?
                                            (lang === 'fr' ?
                                                <span>Maintenant que je comprends la Blockchain, j'aimerais vraiment l'utiliser pour mon entreprise de production alimentaire. En particulier, pour automatiser ma chaîne d'approvisionnement!</span>
                                                : <span>Now that I understand Blockchain, I would really like to use it for my food production business. Especially, to automate my Supply Chain!</span>
                                            )
                                            :
                                            (lang === 'fr' ?
                                                homeContent.find(find => find.id === "936f143b-bfa8-46ba-9376-f1c2b2b6e433").contentFr
                                                : homeContent.find(find => find.id === "936f143b-bfa8-46ba-9376-f1c2b2b6e433").content
                                            )
                                    }
                                </Card.Body>
                            </Card>

                            <Card className="infoCard" style={{ margin: '2em' }}>
                                <Card.Body>
                                    <Card.Img className="card-img-dropshadow" variant="top" src={!isLoaded ? "../assets/Home Page Assets/TestimonyImage3.png" : homeContent.find(find => find.id === "ad8a104d-77ae-404e-af4b-d099ef5b5bb9").image} style={{ maxHeight: '20em ', maxWidth: '20em' }} />
                                    <h1>{
                                        !isLoaded ?
                                            ("Toronto, ON (Canada)")
                                            :
                                            (homeContent.find(find => find.id === "ad8a104d-77ae-404e-af4b-d099ef5b5bb9").title)
                                    }
                                    </h1>
                                    {
                                        !isLoaded ?
                                            (lang === 'fr' ?
                                                <p>Votre solution Blockchain ferait gagner beaucoup de temps à l\'université. Délivrer une certification nous prend généralement plusieurs jours!</p>
                                                : <p>Your Blockchain solution would save the university so much time. Issuing a certification typically takes us days!</p>
                                            )
                                            :
                                            (lang === 'fr' ?
                                                homeContent.find(find => find.id === "ad8a104d-77ae-404e-af4b-d099ef5b5bb9").contentFr
                                                : homeContent.find(find => find.id === "ad8a104d-77ae-404e-af4b-d099ef5b5bb9").content
                                            )
                                    }
                                </Card.Body>
                            </Card>

                        </CardGroup>
                    </Row>
                </Container>
            </div>
            
            {/* Advisors */}
            <div className='sect2'>
                <Container className='primaryElement primaryElement-Var1'>
                    <Row className="align-items-center">
                        <Col className="sect2-txt" id="text-element" md={12} lg={6}>
                            <h1>{
                                !isLoaded ?
                                    (lang === 'fr' ?
                                        'Conseiller numérique approuvé'
                                        : 'Approved Digital Advisor'
                                    )
                                    :
                                    (lang === 'fr' ?
                                        homeContent.find(find => find.id === "3e329a4e-c63d-4385-9617-7f51f3600ebc").titleFr
                                        : homeContent.find(find => find.id === "3e329a4e-c63d-4385-9617-7f51f3600ebc").title
                                    )
                            }</h1>
                            {
                                !isLoaded ?
                                    (lang === 'fr' ?
                                        <p>
                                            loading...
                                        </p>
                                        :
                                        <p>
                                            loading...
                                        </p>
                                    )
                                    :
                                    (lang === 'fr' ?
                                        homeContent.find(find => find.id === "3e329a4e-c63d-4385-9617-7f51f3600ebc").contentFr
                                        : homeContent.find(find => find.id === "3e329a4e-c63d-4385-9617-7f51f3600ebc").content
                                    )
                            }
                            <Button href={`/${lang}/contact-us/`} className='button' style={{ float: 'left' }}>
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            'En savoir plus'
                                            : 'Learn More'
                                        )
                                        :
                                        (lang === 'fr' ?
                                            homeContent.find(find => find.id === "3e329a4e-c63d-4385-9617-7f51f3600ebc").buttonFr
                                            : homeContent.find(find => find.id === "3e329a4e-c63d-4385-9617-7f51f3600ebc").button
                                        )
                                }
                            </Button>
                        </Col>
                        <Col id="image-element" md={12} lg={6}>
                            <div className="primaryElement-img">
                                <img
                                    src={!isLoaded ? "../assets/Home Page Assets/Blockchain_Infographic-02.png" : homeContent.find(find => find.id === "3e329a4e-c63d-4385-9617-7f51f3600ebc").image} alt='Advisor'
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* uses section 3 styling with edited styling through its id, this section is for the clients slider */}
            <div className='sect2' id="client">
                <h1>{lang === 'fr' ? 'Rendu possible par nos clients et partenaires' : 'Made Possible by our Clients and Partners'}</h1>
                <h1>{lang === 'fr' ? 'Nos clients fidèles' : 'Our trusted Clients'}</h1>
                <LogoSlider list={clients} logoId='client' />
                <h1>{lang === 'fr' ? 'Nos partenaires' : 'Our Partners'}</h1>
                <LogoSlider list={partners} logoId='partner' />
            </div>

            <FeaturedBlog lang={lang} />

            {/* Book a Consultation */}
            <div className='sect2'>
                <Container className='primaryElement primaryElement-Var1'>
                    <Row className="align-items-center">
                        <Col className="sect2-txt" id="text-element" sm={12} md={6}>
                            <h1>{
                                !isLoaded ?
                                    (lang === 'fr' ?
                                        'Réservez une consultation maintenant!'
                                        : 'Book a Consulation now!'
                                    )
                                    :
                                    (lang === 'fr' ?
                                        homeContent.find(find => find.id === "b62510c8-25ed-47d4-8576-f9c2b6c9e5fd").titleFr
                                        : homeContent.find(find => find.id === "b62510c8-25ed-47d4-8576-f9c2b6c9e5fd").title
                                    )
                            }</h1>
                            {
                                !isLoaded ?
                                    (lang === 'fr' ?
                                        <p>
                                            <span>
                                                Curieux de connaître nos services, ou de savoir comment construire votre projet Blockchain?
                                                <br /><br />
                                                Prenez rendez-vous avec nous aujourd\'hui, et un expert Blockchain vous fera commencer par une consultation gratuite de 15 minutes pour voir comment nos services peuvent répondre à vos besoins
                                            </span>
                                        </p>
                                        :
                                        <p>
                                            <span>
                                                Curious about our services or how to build your Blockchain project?
                                                <br /><br />
                                                Book a time with us today, and a Blockchain expert will get you started with a free 15 minutes consultation to see how our services can best suit your needs.
                                            </span>
                                        </p>
                                    )
                                    :
                                    (lang === 'fr' ?
                                        homeContent.find(find => find.id === "b62510c8-25ed-47d4-8576-f9c2b6c9e5fd").contentFr
                                        : homeContent.find(find => find.id === "b62510c8-25ed-47d4-8576-f9c2b6c9e5fd").content
                                    )
                            }
                            <Button href={`/${lang}/contact-us/`} className='button' style={{ float: 'left' }}>
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            'Réservez maintenant'
                                            : 'Book Now'
                                        )
                                        : (lang === 'fr' ?
                                            homeContent.find(find => find.id === "b62510c8-25ed-47d4-8576-f9c2b6c9e5fd").buttonFr
                                            : homeContent.find(find => find.id === "b62510c8-25ed-47d4-8576-f9c2b6c9e5fd").button
                                        )
                                }
                            </Button>
                        </Col>
                        <Col id="image-element" sm={12} md={6}>
                            <div className="primaryElement-img">
                                <img
                                    className="card-img-dropshadow-heavy"
                                    src={!isLoaded ? "../assets/Home Page Assets/BookAconsultation.png" : homeContent.find(find => find.id === "b62510c8-25ed-47d4-8576-f9c2b6c9e5fd").image} alt='Book InnovFin consultant'
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Join Slack */}
            <div className='sect2'>
                <Container className='primaryElement primaryElement-Var1'>
                    <Row className="align-items-center">
                        <Col sm={12} md={6}>
                            <div className="primaryElement-img">
                                <img
                                    className="card-img-dropshadow-heavy"
                                    src={!isLoaded ? "../assets/Home Page Assets/SlackPromo.png" : homeContent.find(find => find.id === "874a4e1b-6442-4641-a451-b06a47f5f542").image} alt='InnovFin Slack'
                                />
                            </div>
                        </Col>
                        <Col className="sect2-txt" sm={12} md={6}>
                            <h1>
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            'Rejoignez notre communauté sur Slack!'
                                            : 'Join our Community on Slack!'
                                        )
                                        :
                                        (lang === 'fr' ?
                                            homeContent.find(find => find.id === "874a4e1b-6442-4641-a451-b06a47f5f542").titleFr
                                            : homeContent.find(find => find.id === "874a4e1b-6442-4641-a451-b06a47f5f542").title
                                        )
                                }
                            </h1>
                            {
                                !isLoaded ?
                                    (lang === 'fr' ?
                                        <p>
                                            <span>
                                                Rejoindre notre communauté vous apporte de nombreux avantages. Rencontrez des experts de la Blockchain, tenez-vous au courant de l\'actualité de la Blockchain, apprenez comment faire évoluer votre entreprise vers l\'industrie 4.0 grâce aux discussions hebdomadaires de la communauté et soyez le premier à recevoir des offres spéciales et des promotions en temps limité ! Il suffit de moins d\'une minute pour rejoindre gratuitement la communauté ci-dessous.
                                            </span>
                                        </p>
                                        : <p>
                                            <span>
                                                Joining our community brings you many benefits. Network with Blockchain experts, stay up-to-date with major Blockchain news, learn how to bring your business to industry 4.0 with weekly community discussions, and be the first to receive limited-time special deals and promotions! It takes less than a minute to join the community <b style={{ fontWeight: "900" }}>for free</b> below.
                                            </span>
                                        </p>
                                    )
                                    :
                                    (lang === 'fr' ?
                                        homeContent.find(find => find.id === "874a4e1b-6442-4641-a451-b06a47f5f542").contentFr
                                        : homeContent.find(find => find.id === "874a4e1b-6442-4641-a451-b06a47f5f542").content
                                    )
                            }
                            {/* <Container> */}
                            <Button
                                href='https://join.slack.com/t/innovfinedtech/shared_invite/zt-u70pg874-JXRdvazWBrNWikoo5k1t4Q'
                                className='button'
                                style={{ float: 'left' }}>
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            'Rejoindre'
                                            : 'Join Our Community'
                                        )
                                        :
                                        (lang === 'fr' ?
                                            homeContent.find(find => find.id === "874a4e1b-6442-4641-a451-b06a47f5f542").buttonFr
                                            : homeContent.find(find => find.id === "874a4e1b-6442-4641-a451-b06a47f5f542").button
                                        )}
                            </Button>
                            {/* </Container> */}
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <SocialMedia lang={lang} />
            </div>




            {/* 
                Cookie Popup
                This module is the popup that shows up once, until the cookies expires
            */}
            {
                <PopupComponent lang={lang} location="home" />
            }
        </div >
    )
}

export default Home;