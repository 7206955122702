import React, { useContext, useEffect, useRef, useState } from 'react';
import '../styling/Services.css';
import { Accordion, AccordionContext, Button, Card, CardGroup, Col, Container, Row, useAccordionToggle } from 'react-bootstrap';
import metaData from "../json_files/metadata.json";
import HelmetMetaData from '../components/HelmetMetaData';
import { Analytics } from "aws-amplify";
import { listServices, listVideoEmbeds } from "../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import PopupComponent from '../components/PopupComponent';
import { Portal } from '@material-ui/core';

function Education() {
    const { lang } = useParams();
    // For the meta data stuff
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [keywords, setKeywords] = useState("");
    // Get location metadata from JSON file, this system maybe used in the future so that
    // non-developers can edit metadata.
    const getMeta = (data) => {
        if (data.url === window.location.pathname.split("/")[2]) {
            setTitle(data.title);
            setDescription(data.description);
            setKeywords(data.keywords);
        }
    };

    //the below is used for storing the content that will be grabbed from the API
    //is loaded is to check if the api is loaded
    const [serviceContent, setServiceContent] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isVidLoaded, setIsVidLoaded] = useState(false);
    const [videoEmbed, setVideoEmbed] = useState([]);

    const setContentAPI = () => {
        API.graphql(graphqlOperation(listServices))
            .then((data) => {
                const modifiedData2 = data["data"]["listServices"]["items"];

                modifiedData2.map((item) => {
                    item.content = parse(item.content);
                    item.contentFr = parse(item.contentFr);
                    return item;
                });

                setServiceContent(modifiedData2);
                setIsLoaded(true);
                // console.log("content education: ", serviceContent.find(find => find.id === "4c769797-8af0-4a49-b30a-6d577e8eb9d4"));
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // needs separate api function for grabbing video url or a crash is caused
    const setVideoAPI = () => {
        API.graphql(graphqlOperation(listVideoEmbeds))
            .then((data) => {
                const modifiedData3 = data["data"]["listVideoEmbeds"]["items"];

                setVideoEmbed(modifiedData3);
                //Lets the app know that the video URL data is laoded, we need seperate load variables for the site not to crash
                setIsVidLoaded(true);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    //list of class names to disable right-click
    const imageClasses = [".card-img-dropshadow"];

    //remove the context menu for the images
    const removeRightClick = () => {
        imageClasses.forEach(imgClass => {
            const noContextMenu = document.querySelectorAll(imgClass);
            noContextMenu.forEach(data => {
                data.addEventListener("contextmenu", e => e.preventDefault());
            })
        })
    }

    useEffect(() => {
        setContentAPI();
        setVideoAPI();
        removeRightClick();
        metaData.forEach(data => {
            getMeta(data);
        });
    }, []);

    function handleClick(type) {
        Analytics.record({
            name: "Education Subscribe",
            attributes: {
                course: type
            }
        })
            .then(data => (console.log("Pressed", data)))
            .catch(error => (console.log(error)))
    }

    // All code below is for revealing and hiding the premium courses
    // Remember to add "visuallyhidden" and "hidden" classes to serviceContainer-premium
    // also reenable container={container.current} below
    // function ContextToggle({ children, eventKey, callback }) {
    //     const { activeEventKey } = useContext(AccordionContext);

    //     const decoratedOnClick = useAccordionToggle(
    //         eventKey,
    //         () => callback && callback(eventKey),
    //     );

    //     const isCurrentEventKey = activeEventKey === eventKey;

    //     return (
    //         <Button
    //             type="button"
    //             className='button'
    //             onClick={decoratedOnClick}
    //         >
    //             {children}
    //         </Button>
    //     );
    // }

    // const [showCourse, setShowCourse] = useState(false);
    // const container = useRef(null);
    // const handleCourses = () => {
    //     setShowCourse(!showCourse);
    // };

    // let serviceContainerPremium = document.getElementById("serviceContainer-premium");
    // const handleCourses2 = () => {
    //     if (serviceContainerPremium.classList.contains("hidden")) {
    //         serviceContainerPremium.classList.remove("hidden");
    //         setTimeout(function () {
    //             serviceContainerPremium.classList.remove("visuallyhidden");
    //         }, 20);
    //     } else {
    //         serviceContainerPremium.classList.add("visuallyhidden");
    //         serviceContainerPremium.addEventListener("transitionend",
    //             function (e) {
    //                 serviceContainerPremium.classList.add("hidden");
    //             }, {
    //             capture: false,
    //             once: true,
    //             passive: false
    //         });
    //     }
    //     setShowCourse(!showCourse);
    // };


    return (
        <div>
            <HelmetMetaData
                title={title}
                description={description}
                keywords={keywords}>
            </HelmetMetaData>
            <Container>
                {/* <div className='sect1'> */}

                <div className='infoContainer' id='serviceContainer'>
                    <h1>{
                        !isLoaded ?
                            (lang === 'fr' ?
                                'Services d\'éducation'
                                : 'Education Services'
                            )
                            :
                            (lang === 'fr' ?
                                serviceContent.find(find => find.id === "4c769797-8af0-4a49-b30a-6d577e8eb9d4").titleFr
                                : serviceContent.find(find => find.id === "4c769797-8af0-4a49-b30a-6d577e8eb9d4").title
                            )
                    }
                    </h1>
                    <div className="infoContainer-description">
                        {
                            !isLoaded ?
                                (lang === 'fr' ?
                                    <p>InnovFin lance Innov-Edu, une plateforme destinée à aider les employeurs à former leurs employés aux technologies émergentes et à les aider à améliorer leurs compétences et à certifier leurs employés dans ce domaine.</p>
                                    : <p>InnovFin is launching Innov-Edu, a platform to help employers educate their employees on emerging technologies &amp; help employers upskill and certify their employees with emerging tech skills.</p>
                                )
                                :
                                (lang === 'fr' ?
                                    serviceContent.find(find => find.id === "4c769797-8af0-4a49-b30a-6d577e8eb9d4").contentFr
                                    : serviceContent.find(find => find.id === "4c769797-8af0-4a49-b30a-6d577e8eb9d4").content
                                )
                        }
                    </div>

                    {/* Container for the cards */}
                    <Container className="serviceContainer" style={{ paddingLeft: '1%', paddingRight: '1%' }}>
                        <Row className="justify-content-md-center">
                            <CardGroup className="justify-content-md-center">

                                <Card className="infoCard">
                                    <Card.Body>
                                        <h1>{
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    'Cours en ligne gratuits'
                                                    : 'Free Online Courses'
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    serviceContent.find(find => find.id === "305be7cc-03ab-44ad-b99c-a3c2e826eb4c").titleFr
                                                    : serviceContent.find(find => find.id === "305be7cc-03ab-44ad-b99c-a3c2e826eb4c").title
                                                )
                                        }
                                        </h1>
                                        <Card.Img className="card-img-dropshadow" variant="top" src={!isLoaded ? "../assets/Education Services Assets/FreeOnlineCourses.png" : serviceContent.find(find => find.id === "305be7cc-03ab-44ad-b99c-a3c2e826eb4c").image} />
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    <p>
                                                        <span>Bientôt disponible!<br /><br />Inscrivez-vous à notre lettre d\'information pour recevoir des informations sur nos progrès.</span>
                                                    </p>
                                                    : <p>
                                                        <span>Coming Soon!<br /><br />Subscribe to our newsletter to receive updates about our progress.</span>
                                                    </p>
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    serviceContent.find(find => find.id === "305be7cc-03ab-44ad-b99c-a3c2e826eb4c").contentFr
                                                    : serviceContent.find(find => find.id === "305be7cc-03ab-44ad-b99c-a3c2e826eb4c").content
                                                )
                                        }
                                    </Card.Body>
                                    <Card.Footer>
                                        {
                                            !isLoaded ?
                                                <Button
                                                    className='button'
                                                    onClick={
                                                        () => { handleClick("Free Online Courses") }
                                                    }
                                                    href="#learn-more"
                                                >
                                                    {(lang === 'fr' ?
                                                        'Abonnez-vous'
                                                        : 'Subscribe'
                                                    )}
                                                </Button>
                                                :
                                                <Button
                                                    className='button'
                                                    onClick={
                                                        () => { handleClick("Free Online Courses") }
                                                    }
                                                    href={serviceContent.find(find => find.id === "305be7cc-03ab-44ad-b99c-a3c2e826eb4c").link}
                                                >
                                                    {(lang === 'fr' ?
                                                        serviceContent.find(find => find.id === "305be7cc-03ab-44ad-b99c-a3c2e826eb4c").buttonFr
                                                        : serviceContent.find(find => find.id === "305be7cc-03ab-44ad-b99c-a3c2e826eb4c").button
                                                    )}
                                                </Button>
                                        }
                                    </Card.Footer>
                                </Card>

                                <Card className="infoCard">
                                    <Card.Body>
                                        <h1>
                                            {
                                                !isLoaded ?
                                                    (lang === 'fr' ?
                                                        'Cours en ligne premium'
                                                        : 'Premium Online Courses'
                                                    )
                                                    :
                                                    (lang === 'fr' ?
                                                        serviceContent.find(find => find.id === "72aee29c-991f-423c-bfef-01bdf6a941a6").titleFr
                                                        : serviceContent.find(find => find.id === "72aee29c-991f-423c-bfef-01bdf6a941a6").title
                                                    )
                                            }

                                        </h1>
                                        <Card.Img className="card-img-dropshadow" variant="top" src={!isLoaded ? "../assets/Education Services Assets/OfflineEducation.png" : serviceContent.find(find => find.id === "72aee29c-991f-423c-bfef-01bdf6a941a6").image} />
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    <p>
                                                        Vous cherchez à organiser une session de formation en personne sur la blockchain pour vos clients, cadres ou employés ? Contactez-nous dès aujourd\'hui pour discuter de vos besoins!
                                                    </p>
                                                    : <p>Looking to hold an in-person education session for your clients, executives or employees on Blockchain? Contact us today to discuss your needs!
                                                    </p>
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    serviceContent.find(find => find.id === "72aee29c-991f-423c-bfef-01bdf6a941a6").contentFr
                                                    : serviceContent.find(find => find.id === "72aee29c-991f-423c-bfef-01bdf6a941a6").content
                                                )
                                        }
                                    </Card.Body>
                                    <Card.Footer>
                                        {
                                            !isLoaded ?
                                                <Button href="#learn-more" className='button'>
                                                    {(lang === 'fr' ?
                                                        'Contactez-nous'
                                                        : 'Contact Us'
                                                    )}
                                                </Button>
                                                :
                                                <Button href={serviceContent.find(find => find.id === "72aee29c-991f-423c-bfef-01bdf6a941a6").link} className='button'>
                                                    {(lang === 'fr' ?
                                                        serviceContent.find(find => find.id === "72aee29c-991f-423c-bfef-01bdf6a941a6").buttonFr
                                                        : serviceContent.find(find => find.id === "72aee29c-991f-423c-bfef-01bdf6a941a6").button
                                                    )}
                                                </Button>
                                        }

                                    </Card.Footer>
                                </Card>

                                <Card className="infoCard">
                                    <Card.Body>
                                        <h1>
                                            {
                                                !isLoaded ?
                                                    (lang === 'fr' ?
                                                        'Éducation hors ligne'
                                                        : 'Offline Education'
                                                    )
                                                    :
                                                    (lang === 'fr' ?
                                                        serviceContent.find(find => find.id === "a7e7aefb-cf5c-4023-b655-36bd937b2766").titleFr
                                                        : serviceContent.find(find => find.id === "a7e7aefb-cf5c-4023-b655-36bd937b2766").title
                                                    )

                                            }
                                        </h1>
                                        <Card.Img className="card-img-dropshadow" variant="top" src={!isLoaded ? "../assets/Education Services Assets/PremiumOnlineCourses.png" : serviceContent.find(find => find.id === "a7e7aefb-cf5c-4023-b655-36bd937b2766").image} />
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    <p>
                                                        <span>Bientôt disponibl!<br /><br />Inscrivez-vous à notre lettre d\'information pour recevoir des informations sur nos progrès.</span>
                                                    </p>
                                                    : <p>
                                                        <span>Coming Soon!<br /><br />Subscribe to our newsletter to receive updates about our progress.</span>
                                                    </p>
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    serviceContent.find(find => find.id === "a7e7aefb-cf5c-4023-b655-36bd937b2766").contentFr
                                                    : serviceContent.find(find => find.id === "a7e7aefb-cf5c-4023-b655-36bd937b2766").content
                                                )
                                        }
                                    </Card.Body>
                                    <Card.Footer>
                                        {
                                            !isLoaded ?
                                                <Button
                                                    className='button'
                                                    href=""
                                                >
                                                    {(lang === 'fr' ?
                                                        'Abonnez-vous'
                                                        : 'Subscribe'
                                                    )}

                                                </Button>
                                                :
                                                <Button
                                                    className='button'
                                                    href={serviceContent.find(find => find.id === "a7e7aefb-cf5c-4023-b655-36bd937b2766").link}
                                                >
                                                    {(lang === 'fr' ?
                                                        serviceContent.find(find => find.id === "a7e7aefb-cf5c-4023-b655-36bd937b2766").buttonFr
                                                        : serviceContent.find(find => find.id === "a7e7aefb-cf5c-4023-b655-36bd937b2766").button
                                                    )}
                                                </Button>
                                        }
                                    </Card.Footer>
                                </Card>

                            </CardGroup>
                        </Row>
                    </Container>

                </div>
                {/* </div> */}
            </Container>

            {/* Container for Premium courses */}
            {/* <Container className="serviceContainer-premium" id="serviceContainer-premium" style={{ paddingLeft: '1%', paddingRight: '1%' }}>

                <h1 style={{ margin: "0em 0em .5em 0em" }}>
                    {lang === "fr" ?
                        "Nos cours premium"
                        : "Our Premium Courses!"}
                </h1>
                <p className="infoContainer-description" style={{ margin: "0em 0em 1em 0em" }}>
                    {
                        lang === 'fr' ?
                            "Découvrez certains de nos cours premium disponibles dès maintenant (seulement en anglais)"
                            : "Check out some of our premium courses available now"

                    }
                </p>
                <Container
                //below code is for revealing/hide premium feature
                //container={container.current}
                >
                    <CardGroup className="justify-content-md-center">

                        <Card className="infoCard">
                            <Card.Body>
                                <h1>{
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            'Cours en ligne premium'
                                            : 'Premium Course'
                                        )
                                        :
                                        (lang === 'fr' ?
                                            serviceContent.find(find => find.id === "52657067-d79d-459e-96ba-142bca33a347").titleFr
                                            : serviceContent.find(find => find.id === "52657067-d79d-459e-96ba-142bca33a347").title
                                        )
                                }
                                </h1>
                                <Card.Img className="card-img-dropshadow" variant="top" src={!isLoaded ? "../assets/Education Services Assets/FreeOnlineCourses.png" : serviceContent.find(find => find.id === "52657067-d79d-459e-96ba-142bca33a347").image} />
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            <p>
                                                <span>Bientôt disponible!<br /><br />Inscrivez-vous à notre lettre d\'information pour recevoir des informations sur nos progrès.</span>
                                            </p>
                                            : <p>
                                                <span>Coming Soon!<br /><br />Subscribe to our newsletter to receive updates about our progress.</span>
                                            </p>
                                        )
                                        :
                                        (lang === 'fr' ?
                                            serviceContent.find(find => find.id === "52657067-d79d-459e-96ba-142bca33a347").contentFr
                                            : serviceContent.find(find => find.id === "52657067-d79d-459e-96ba-142bca33a347").content
                                        )
                                }
                            </Card.Body>
                            <Card.Footer>

                                {
                                    !isLoaded ?
                                        (<Button
                                            className='button'
                                            onClick={
                                                () => { handleClick("Free Online Courses") }
                                            }
                                            href=""
                                        >
                                            {lang === 'fr' ?
                                                'Abonnez-vous'
                                                : 'Check it Out!'}
                                        </Button>
                                        )
                                        :
                                        (<Button
                                            className='button'
                                            onClick={
                                                () => { handleClick("Free Online Courses") }
                                            }
                                            href={serviceContent.find(find => find.id === "52657067-d79d-459e-96ba-142bca33a347").link}
                                        >
                                            {lang === 'fr' ?
                                                serviceContent.find(find => find.id === "52657067-d79d-459e-96ba-142bca33a347").buttonFr
                                                : serviceContent.find(find => find.id === "52657067-d79d-459e-96ba-142bca33a347").button}
                                        </Button>
                                        )
                                }
                            </Card.Footer>
                        </Card>

                        <Card className="infoCard">
                            <Card.Body>
                                <h1>
                                    {
                                        !isLoaded ?
                                            (lang === 'fr' ?
                                                'Cours en ligne premium'
                                                : 'Premium Online Courses'
                                            )
                                            :
                                            (lang === 'fr' ?
                                                serviceContent.find(find => find.id === "5a8feff9-e6b6-4736-b661-d45195dd66e8").titleFr
                                                : serviceContent.find(find => find.id === "5a8feff9-e6b6-4736-b661-d45195dd66e8").title
                                            )
                                    }
                                </h1>
                                <Card.Img className="card-img-dropshadow" variant="top" src={!isLoaded ? "../assets/Education Services Assets/PremiumOnlineCourses.png" : serviceContent.find(find => find.id === "5a8feff9-e6b6-4736-b661-d45195dd66e8").image} />
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            <p>
                                                <span>Bientôt disponibl!<br /><br />Inscrivez-vous à notre lettre d\'information pour recevoir des informations sur nos progrès.</span>
                                            </p>
                                            : <p>
                                                <span>Coming Soon!<br /><br />Subscribe to our newsletter to receive updates about our progress.</span>
                                            </p>
                                        )
                                        :
                                        (lang === 'fr' ?
                                            serviceContent.find(find => find.id === "5a8feff9-e6b6-4736-b661-d45195dd66e8").contentFr
                                            : serviceContent.find(find => find.id === "5a8feff9-e6b6-4736-b661-d45195dd66e8").content
                                        )
                                }
                            </Card.Body>
                            <Card.Footer>
                                {
                                    !isLoaded ?
                                        (<Button
                                            className='button'
                                            onClick={() => {
                                                handleClick("Premium Online Courses")
                                            }
                                            }
                                            href="https://www.innov-edu.ca/"
                                        >
                                            {lang === 'fr' ?
                                                'Abonnez-vous'
                                                : 'Subscribe'}
                                        </Button>
                                        )
                                        :
                                        (<Button
                                            className='button'
                                            onClick={() => {
                                                handleClick("Premium Online Courses")
                                            }
                                            }
                                            href={serviceContent.find(find => find.id === "5a8feff9-e6b6-4736-b661-d45195dd66e8").link}
                                        >
                                            {lang === 'fr' ?
                                                serviceContent.find(find => find.id === "5a8feff9-e6b6-4736-b661-d45195dd66e8").buttonFr
                                                : serviceContent.find(find => find.id === "5a8feff9-e6b6-4736-b661-d45195dd66e8").button}
                                        </Button>
                                        )
                                }
                            </Card.Footer>
                        </Card>

                        <Card className="infoCard">
                            <Card.Body>
                                <h1>
                                    {
                                        !isLoaded ?
                                            (lang === 'fr' ?
                                                'Éducation hors ligne'
                                                : 'Bundle Up'
                                            )
                                            :
                                            (lang === 'fr' ?
                                                serviceContent.find(find => find.id === "dad11d3f-e859-4c2c-b0e5-c75f836dda9e").titleFr
                                                : serviceContent.find(find => find.id === "dad11d3f-e859-4c2c-b0e5-c75f836dda9e").title
                                            )
                                    }
                                </h1>
                                <Card.Img className="card-img-dropshadow" variant="top" src={!isLoaded ? "../assets/Education Services Assets/OfflineEducation.png" : serviceContent.find(find => find.id === "dad11d3f-e859-4c2c-b0e5-c75f836dda9e").image} />
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            <p>
                                                Vous cherchez à organiser une session de formation en personne sur la blockchain pour vos clients, cadres ou employés ? Contactez-nous dès aujourd\'hui pour discuter de vos besoins!
                                            </p>
                                            : <p>Looking to hold an in-person education session for your clients, executives or employees on Blockchain? Contact us today to discuss your needs!
                                            </p>
                                        )
                                        :
                                        (lang === 'fr' ?
                                            serviceContent.find(find => find.id === "dad11d3f-e859-4c2c-b0e5-c75f836dda9e").contentFr
                                            : serviceContent.find(find => find.id === "dad11d3f-e859-4c2c-b0e5-c75f836dda9e").content
                                        )
                                }
                            </Card.Body>
                            <Card.Footer>

                                {
                                    !isLoaded ?
                                        (<Button href={`/${lang}/contact-us/`} className='button'>
                                            {lang === 'fr' ?
                                                'Contactez-nous'
                                                : 'Check it Out'}
                                        </Button>
                                        )
                                        :
                                        (<Button href={serviceContent.find(find => find.id === "dad11d3f-e859-4c2c-b0e5-c75f836dda9e").link} className='button'>
                                            {lang === 'fr' ?
                                                serviceContent.find(find => find.id === "dad11d3f-e859-4c2c-b0e5-c75f836dda9e").buttonFr
                                                : serviceContent.find(find => find.id === "dad11d3f-e859-4c2c-b0e5-c75f836dda9e").button}

                                        </Button>
                                        )
                                }
                            </Card.Footer>
                        </Card>
                    </CardGroup>
                </Container>
            </Container> */}

            <div className='sect2' id="learn-more">
                <Container className='primaryElement  primaryElement-Var2'>
                    <Row className="align-items-center">
                        <Col className="sect2-txt" id="text-element" lg={6}>
                            <h1>
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            'Plate-forme d\'apprentissage interactive Innov-Edu'
                                            : 'Innov-Edu Interactive Learning Platform'
                                        )
                                        :
                                        (lang === 'fr' ?
                                            serviceContent.find(find => find.id === "2df34cd0-02d8-4d0b-bd46-19e76b3a988f").titleFr
                                            : serviceContent.find(find => find.id === "2df34cd0-02d8-4d0b-bd46-19e76b3a988f").title
                                        )
                                }
                            </h1>
                            <div>
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            <p>
                                                <span>Innov-Edu est la plateforme de choix pour les employeurs qui cherchent à améliorer les compétences de leurs employés ou de les réorienter vers une nouvelle formation avec les compétences essentielles de l'industrie 4.0.
                                                    <br /><br />
                                                    Nous élaborons une liste exhaustive de cours pour doter les employés du savoir-faire technologique émergent, afin que les organisations puissent innover, automatiser et motiver.
                                                    <br /><br />
                                                    Tous nos cours sont disponibles en ligne, à la demande, et sont accessibles depuis votre ordinateur portable, votre tablette, votre téléphone et votre casque de réalité virtuelle.
                                                </span>
                                            </p>
                                            : <p>
                                                <span>Innov-Edu is the platform of choice for employers looking to upskill and reskill their employees with Industry 4.0 essential skillsets.
                                                    <br /><br />
                                                    We are building an extensive list of courses to equip employees with emerging tech know-how, so organizations can innovate, automate, and motivate.
                                                    <br /><br />
                                                    All of our courses are available online, on-demand, and can be accessed from your laptop, your tablet, your phone, and on your virtual reality headset.
                                                </span>
                                            </p>
                                        )
                                        :
                                        (lang === 'fr' ?
                                            serviceContent.find(find => find.id === "2df34cd0-02d8-4d0b-bd46-19e76b3a988f").contentFr
                                            : serviceContent.find(find => find.id === "2df34cd0-02d8-4d0b-bd46-19e76b3a988f").content
                                        )
                                }
                            </div>
                            <Button className='button btn-2' style={{ float: 'left' }} href='https://www.innov-edu.ca/'>
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            'En savoir plus'
                                            : 'Learn More'
                                        )
                                        :
                                        (lang === 'fr' ?
                                            serviceContent.find(find => find.id === "2df34cd0-02d8-4d0b-bd46-19e76b3a988f").buttonFr
                                            : serviceContent.find(find => find.id === "2df34cd0-02d8-4d0b-bd46-19e76b3a988f").button
                                        )
                                }
                            </Button>
                        </Col>
                        <Col id="image-element" lg={6}>
                            {!isVidLoaded ?
                                (<div className="primaryElement-img">
                                    <img src={!isLoaded ? "../assets/Home Page Assets/EdTechIcon.png" : serviceContent.find(find => find.id === "2df34cd0-02d8-4d0b-bd46-19e76b3a988f").image} alt='InnovFin EdTech platform' />
                                </div>)
                                : (<div className='player-wrapper'>
                                    <video
                                        onContextMenu={(e) => e.preventDefault()}
                                        poster={videoEmbed.find(find => find.id === "0c71d8ea-3cbc-4b9a-b2cf-d253c4cf1bfc").thumbnail}
                                        className="react-player"
                                        id="edu-player"
                                        title="Video"
                                        width="100%"
                                        height="100%"
                                        src={videoEmbed.find(find => find.id === "0c71d8ea-3cbc-4b9a-b2cf-d253c4cf1bfc").url.replace("watch?v=", "embed/") + "?rel=0"}
                                        frameBorder="0"
                                        // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        controls="controls"
                                        controlsList="nodownload"
                                    />
                                </div>
                                )}
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* The following is used to show any popup at this location, there may not be any popups to show */}
            <PopupComponent lang={lang} location="Education" />
        </div >
    )
}

export default Education;