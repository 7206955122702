import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { hydrate, render } from "react-dom";
import Amplify, { Analytics } from "aws-amplify"
import config from "./aws-exports"
import {HelmetProvider} from "react-helmet-async";

Amplify.configure(config);

const helmentContext={};

ReactDOM.render(
  <HelmetProvider context={helmentContext}>
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>

  </React.StrictMode>
  </HelmetProvider>,
  document.getElementById("root")
);

//react snap stuff
// const APP = (
//   <HelmetProvider context={helmentContext}>
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>

//   </React.StrictMode>
//   </HelmetProvider>
// )

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(APP, rootElement);
// } else {
//   render(APP, rootElement);
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
