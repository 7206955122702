/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      slug
      image
      video
      title
      content
      author
      categories
      featured
      lang
      postLink
      sources
      createdAt
      updatedAt
      status
      publish_at
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        slug
        image
        video
        title
        content
        author
        categories
        featured
        lang
        postLink
        sources
        publish_at
        status
        createdAt
        updatedAt
        status
        publish_at
      }
      nextToken
    }
  }
`;
export const postsByTitleByDate = /* GraphQL */ `
  query PostsByTitleByDate(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
    $publish_at: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $title: String
  ){
    postsByTitleByDate(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      publish_at: $publish_at
      sortDirection: $sortDirection
      title: $title
    ){
      items{
        id
        slug
        image
        video
        title
        content
        author
        categories
        featured
        lang
        postLink
        sources
        publish_at
        status
        createdAt
        updatedAt
        status
        publish_at
      }
      nextToken
    }
  }
`;
export const postsByLang = /* GraphQL */ `
  query PostsByLang(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
    $publish_at: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $lang: String
  ){
    postsByLang(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      publish_at: $publish_at
      sortDirection: $sortDirection
      lang: $lang
    ){
      items{
        id
        slug
        image
        video
        title
        content
        author
        categories
        featured
        lang
        postLink
        sources
        publish_at
        status
        createdAt
        updatedAt
        status
        publish_at
      }
      nextToken
    }
  }
`;
export const getVideoEmbed = /* GraphQL */ `
  query GetVideoEmbed($id: ID!) {
    getVideoEmbed(id: $id) {
      id
      pageLocation
      url
      thumbnail
    }
  }
`;
export const listVideoEmbeds = /* GraphQL */ `
  query ListVideoEmbeds(
    $filter: ModelVideoEmbedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoEmbeds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        pageLocation
        url
        thumbnail
      }
      nextToken
    }
  }
`;

export const getFAQpost = /* GraphQL */ `
  query GetFaqPost($id: ID!) {
    getFAQpost(id: $id) {
      id
      question
      questionFr
      createdAt
      answer
      answerFr
    }
  }
`;
export const listFAQposts = /* GraphQL */ `
  query ListFAQPosts(
    $filter: ModelFAQpostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFAQposts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question
        questionFr
        createdAt
        answer
        answerFr
      }
      nextToken
    }
  }
`;

export const getFAQpostfr = /* GraphQL */ `
  query GetFaqPostfr($id: ID!) {
    getFAQpostfr(id: $id) {
      id
      question
      answer
    }
  }
`;
export const listFAQpostfrs = /* GraphQL */ `
  query ListFAQPostfrs(
    $filter: ModelFAQpostfrFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFAQpostfrs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question
        answer
      }
      nextToken
    }
  }
`;

export const getHome = /* GraphQL */ `
  query GetHome($id: ID!) {
    getHome(id: $id) {
      id
      image
      title
      content
      button
      titleFr
      contentFr
      buttonFr
    }
  }
`;

export const listHomes = /* GraphQL */ `
  query ListHomes(
    $filter: ModelHomeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHomes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        image
        title
        content
        button
        titleFr
        contentFr
        buttonFr
      }
      nextToken
    }
  }
`;
export const getService = /* GraphQL */ `
  query GetService($id: ID!) {
    getService(id: $id) {
      id
      image
      title
      content
      button
      titleFr
      contentFr
      buttonFr
      link
    }
  }
`;

export const listServices = /* GraphQL */ `
  query ListServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        image
        title
        content
        button
        titleFr
        contentFr
        buttonFr
        link
      }
      nextToken
    }
  }
`;

export const getAboutOthaliaContent = /* GraphQL */ `
  query GetAboutOthaliaContent($id: ID!) {
    getAboutOthaliaContent(id: $id) {
      id
      image
      title
      content
      button
      titleFr
      contentFr
      buttonFr
    }
  }
`;

export const listAboutOthaliaContents = /* GraphQL */ `
  query ListAboutOthaliaContents(
    $filter: ModelAboutOthaliaContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAboutOthaliaContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        image
        title
        content
        button
        titleFr
        contentFr
        buttonFr
      }
      nextToken
    }
  }
`;

export const getAboutContent = /* GraphQL */ `
  query GetAboutContent($id: ID!) {
    getAboutContent(id: $id) {
      id
      image
      title
      content
      button
      titleFr
      contentFr
      buttonFr
    }
  }
`;

export const listAboutContents = /* GraphQL */ `
  query ListAboutContents(
    $filter: ModelAboutContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAboutContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        image
        title
        content
        button
        titleFr
        contentFr
        buttonFr
      }
      nextToken
    }
  }
`;

export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      type
      title
      content
      titleFr
      contentFr
    }
  }
`;

export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        title
        content
        titleFr
        contentFr
      }
      nextToken
    }
  }
`;
export const getAuthor = /* GraphQL */ `
  query GetAuthor($id: ID!) {
    getAuthor(id: $id) {
      id
      image
      author
      description
    }
  }
`;
export const listAuthors = /* GraphQL */ `
  query ListAuthors(
    $filter: ModelAuthorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuthors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        image
        author
        description
      }
      nextToken
    }
  }
`;
export const getIconImage = /* GraphQL */ `
  query GetIconImage($id: ID!) {
    getIconImage(id: $id) {
      id
      image
      title
      link
    }
  }
`;
export const listIconImages = /* GraphQL */ `
  query ListIconImages(
    $filter: ModelIconImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIconImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        image
        title
        link
      }
      nextToken
    }
  }
`;
export const getPopup = /* GraphQL */ `
  query GetPopup($id: ID!) {
    getPopup(id: $id) {
      id
      image
      location
      title
      content
      button
      link
      titleFr
      contentFr
      buttonFr
      linkFr
      enable
      createdAt
    }
  }
`;
export const listPopups = /* GraphQL */ `
  query ListPopups(
    $filter: ModelPopupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPopups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        image
        location
        title
        content
        button
        link
        titleFr
        contentFr
        buttonFr
        linkFr
        enable
        createdAt
      }
      nextToken
    }
  }
`;
export const getTerm = /* GraphQL */ `
  query GetTerm($id: ID!) {
    getTerm(id: $id) {
      id
      title
      content
      titleFr
      contentFr
    }
  }
`;
export const listTerms = /* GraphQL */ `
  query ListTerms(
    $filter: ModelTermFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTerms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        titleFr
        contentFr
      }
      nextToken
    }
  }
`;