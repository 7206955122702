import {
  List,
  ReferenceInput,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  DateField,
  TextField,
  EditButton,
  TextInput,
  BooleanInput,
  DateInput,
  ImageInput,
  ImageField,
  ArrayInput,
  AutocompleteInput,
  SimpleFormIterator,
  Toolbar,
  SaveButton,
  AutocompleteArrayInput,
  CheckboxGroupInput,
  DateTimeInput,
  required,
  RichTextField,
  Pagination,
  RadioButtonGroupInput,
  BooleanField,
  SearchInput,
  Filter
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { AmplifyImageInput, AmplifyFilter } from "react-admin-amplify";
import quillEmoji from "quill-emoji";
import Quill from "quill";
import "quill-emoji/dist/quill-emoji.css"

const { EmojiBlot } = quillEmoji;

//for the richtextinput toolbar options
const toolbarOptions = [
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  [{ 'size': ['small', false, 'large', 'huge'] }],
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote'],
  ['link', 'image'],
  [{ 'script': 'sub' }, { 'script': 'super' }],
  [{ 'indent': '-1' }, { 'indent': '+1' }],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  [{ 'align': [] }, { 'color': ["#000000", "#FF0000", "#FFF000", "#00FF00", "#0000FF", "#F000FF", "custom-color"] }],
  ['clean'],
  ['emoji']
];

const toolbarOptions2 = {
  container: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote'],
    ['link', 'image'],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'align': [] }, { 'color': ["#000000", "#FF0000", "#FFF000", "#00FF00", "#0000FF", "#F000FF", "custom-color"] }],
    ['clean'],
    ['emoji']
  ],
  handlers: { 'emoji': function () { } }
};

//for the color picker
const configureQuill = quill => quill.getModule('toolbar').addHandler('color', function (value) {
  if (value === 'custom-color') {
    var picker = document.getElementById('custom-color');
    if (!picker) {
      picker = document.createElement('input');
      picker.id = 'custom-color';
      picker.type = 'color';
      picker.style.display = 'none';
      picker.value = '#FF0000';
      document.body.appendChild(picker);

      picker.addEventListener('change', function () {
        quill.format('color', picker.value);
      }, false);
    }
    picker.click();
  } else {
    quill.format('color', value);
  }
  this.quill.format('color', value);
});

const PostStatus = [
  { id: false, name: "DRAFT" },
  { id: true, name: "PUBLISHED" },
];
const PostPagination = (props) => (
  <Pagination rowsPerPageOptions={[75, 100, 125]} {...props} />
);

const PostFilters = (props) => (
  // <TextInput label="Search" source="q" alwaysOn />,
  <Filter {...props}>
    {/* {console.log(props)} */}
    {/* <SearchInput placeholder="Title Search" source="title" resettable alwaysOn/> */}
    <TextInput source="postsByTitleByDate.title" label="Full Title" alwaysOn resettable />
    {/* <TextInput source="postsByLang.lang" label="Full Title" alwaysOn resettable /> */}
    <AutocompleteInput
      source="postsByLang.lang"
      label="Language"
      choices={[
        { id: "english", name: "English" },
        { id: "french", name: "French" },
      ]}
    />
    {/* <DateInput source="postsByTitleByDate.publish_at.eq" label="date" alwaysOn/> */}
    {/* <BooleanInput source={props.data.status} alwaysOn /> */}
  </Filter>
);

export const PostList = (props) => (
  <List {...props} perPage={75} pagination={<PostPagination />} filters={<PostFilters />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="slug" label="Formatted URL" />
      <TextField source="title" />
      <BooleanField source="status" />
      <DateField source="publish_at" sortBy="postsByTitleByDate.publish_at.eq" sortable={true} />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <EditButton basePath="/posts" />
    </Datagrid>
  </List>
);

export const PostCreate = (props) => {
  const createSlug = (data) => {
    let tempStr = data.title;
    tempStr = tempStr.replace(/^\s+|\s+$/g, ''); // trim
    tempStr = tempStr.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      tempStr = tempStr.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    tempStr = tempStr.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return tempStr;
  }
  // Transform the data before it is sent to the data provider
  const transformCreate = (data) => ({
    ...data,
    image: `https://${data.image.bucket}.s3.${data.image.region
      }.amazonaws.com/public/${data.image.key.replaceAll(" ", "+")}`,
    categories: data.categories.split(","),
    slug: createSlug(data)
  });

  return (
    <Create title="Create a Post" {...props} transform={transformCreate}>
      <SimpleForm>
        <AmplifyImageInput
          validate={required()}
          source="image"
          accept="image/*"
        />
        <span>
          Note: Need an image, even if there is a video, the image is used to
          display the blog on the main blog page. Image name can now include spaces, but try to avoid special
          characters such as ! = + - ?
        </span>
        <br />
        <TextInput source="video" />
        <span>
          Note: Leave blank to just display the image on the blog. This is not
          required.
        </span>
        <br />
        <TextInput source="title" validate={required()} />
        <TextInput source="author" />
        <span>
          Note: Make sure that the Author here matches the Author in "Blog Posts
          Authors"
        </span>
        <br />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions}
          source="content"
          validate={required()}
          configureQuill={configureQuill}
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <span>Note: Please use make the "Sources" label a heading.</span>
        <br />
        <TextInput source="categories" parse={(text) => text.toLowerCase()} />
        <span>
          Note: categories will be changed to lowercase so even if you have caps
          lock on, the text here will only display lowercase
        </span>
        <br />
        <AutocompleteInput
          source="lang"
          label="Language"
          validate={required()}
          choices={[
            { id: "english", name: "English" },
            { id: "french", name: "french" },
          ]}
        />
        <span>
          Note: Choose whether the post is to be put on the english or french
          site.
        </span>
        <br />
        <TextInput source="postLink" label="Corresponding Blog Post" />
        <span>
          Note: Post the "formatted url" for the blog you want to link to, leave empty if
          there is none. (For example if there is no French/English alternate)
        </span>
        <br />
        <DateTimeInput source="publish_at" initialValue={Date.now()} />
        <span>
          Note: When creating, set the publish at time to current time or later (setting it to the past can cause errors)
        </span>
        <br />

        <RadioButtonGroupInput
          label="status"
          source="status"
          choices={PostStatus}
        />
        <span>Note: Enable to make the post visible.</span>
        <br />
        <BooleanInput label="featured" source="featured" />
      </SimpleForm>
    </Create>
  );
};
export const PostEdit = (props) => {
  // Transform the data before it is sent to the data provider
  // For editing, we have to check if the user is updating the picture or not, if not
  // image.bucket will be undefined as data.image is just the URL, so return the URL of the previous image
  // instead of trying to update it with the https link, which will return https://undefined.s3.undefined.amazonaws.com/public/undefined
  const getImgString = (data) => {
    if (data.image.bucket === undefined) {
      return data.image;
    } else {
      return `https://${data.image.bucket}.s3.${data.image.region
        }.amazonaws.com/public/${data.image.key.replaceAll(" ", "+")}`;
    }
  };

  const formatCategory = (data) => {
    if (typeof data.categories === "string") {
      return data.categories.split(",");
    } else {
      return data.categories;
    }
  };

  const transformCreate = (data) => ({
    ...data,
    image: getImgString(data),
    categories: formatCategory(data),
  });

  return (
    <Edit {...props} transform={transformCreate}>
      <SimpleForm>
        <ImageField source="image" label="Current Image" />
        <AmplifyImageInput source="image" accept="image/*" />
        <span>
          Note: Image name can now include spaces, but try to avoid special
          characters such as ! = + - ?
        </span>
        <br />
        <span>
          Note: If there is no image displayed, this means that this section
          does not require an image and you can just ignore it. It will not
          affect the site.
        </span>
        <br />
        <TextInput source="video" />
        <span>
          Note: To revert back to just an image, delete the url entirely.
        </span>
        <br />
        <TextInput source="slug" label="Formatted URL" />
        <span>
          Note: This is for the url of the website. Try not to change this unless necessary.
        </span>
        <br />
        <TextInput source="title" />
        <TextInput source="author" />
        <span>
          Note: Make sure that the Author here matches the Author in "Blog Posts
          Authors"
        </span>
        <br />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="content"
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <span>
          Note: Please use heading 3 for the word sources as it will add the
          divider.
        </span>
        <br />
        <TextInput source="categories" parse={(text) => text.toLowerCase()} />
        <span>
          Note: categories will be changed to lowercase so even if you have caps
          lock on, the text here will only display lowercase
        </span>
        <br />
        <AutocompleteInput
          source="lang"
          label="Language"
          choices={[
            { id: "english", name: "English" },
            { id: "french", name: "french" },
          ]}
        />
        <span>
          Note: Choose whether the post is to be put on the english or french
          site.
        </span>
        <br />
        <TextInput source="postLink" label="Corresponding Blog Post" />
        <span>
          Note: Post the "formatted url" for the blog you want to link to, leave empty if
          there is none. (For example if there is no French/English alternate)
        </span>
        <br />
        <DateTimeInput source="publish_at" />

        <RadioButtonGroupInput
          label="status"
          source="status"
          choices={PostStatus}
        />
        <span>Note: Enable to make the post visible.</span>
        <br />
        <BooleanInput label="featured" source="featured" />
      </SimpleForm>
    </Edit>
  );
};

export const VideoEmbedList = (props) => (
  <List {...props} perPage={25} pagination={<PostPagination />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="pageLocation" />
      <TextField source="url" />
      <EditButton basePath="/VideoEmbeds" />
    </Datagrid>
  </List>
);

export const VideoEmbedCreate = (props) => {
  // Transform the data before it is sent to the data provider
  const transformCreate = (data) => ({
    ...data,
    thumbnail: `https://${data.image.bucket}.s3.${data.image.region
      }.amazonaws.com/public/${data.image.key.replaceAll(" ", "+")}`
  });

  return (
    <Create title="Create a Embed" {...props} transform={transformCreate}>
      <SimpleForm>
        <AmplifyImageInput
          validate={required()}
          source="thumbnail"
          accept="image/*"
        />
        <TextInput source="pageLocation" validate={required()} />
        <TextInput source="url" validate={required()} />
      </SimpleForm>
    </Create>
  );
};

export const VideoEmbedEdit = (props) => {
  // Transform the data before it is sent to the data provider
  // For editing, we have to check if the user is updating the picture or not, if not
  // image.bucket will be undefined as data.image is just the URL, so return the URL of the previous image
  // instead of trying to update it with the https link, which will return https://undefined.s3.undefined.amazonaws.com/public/undefined
  const getImgString = (data) => {
    if (data.image.bucket === undefined) {
      return data.image;
    } else {
      return `https://${data.image.bucket}.s3.${data.image.region
        }.amazonaws.com/public/${data.image.key.replaceAll(" ", "+")}`;
    }
  };

  const transformCreate = (data) => ({
    ...data,
    thumbnail: getImgString(data)
  });

  return (
    <Edit {...props} transform={transformCreate}>
      <SimpleForm>
        <ImageField source="thumbnail" label="Current Image" />
        <AmplifyImageInput source="thumbnail" accept="image/*" />
        <TextInput source="pageLocation" />
        <TextInput source="url" />
      </SimpleForm>
    </Edit>
  );
};

export const FAQCreate = (props) => {
  return (
    <Create title="Create a Post" {...props}>
      <SimpleForm>
        <TextInput source="question" validate={required()} />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="answer"
          validate={required()}
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <TextInput source="questionFr" validate={required()} />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="answerFr"
          validate={required()}
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
      </SimpleForm>
    </Create>
  );
};

export const FAQlist = (props) => (
  <List {...props} perPage={25} pagination={<PostPagination />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="question" />
      <RichTextField source="answer" stripTags />
      <TextField source="questionFr" />
      <RichTextField source="answerFr" stripTags />
      <EditButton basePath="/FAQposts" />
    </Datagrid>
  </List>
);

export const FAQEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="question" />
      <br />
      <span>
        To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
      </span>
      <RichTextInput
        // toolbar={toolbarOptions}
        source="answer"
        options={{
          modules: {
            toolbar: toolbarOptions2,
            "emoji-toolbar": true,
            "emoji-textarea": true,
            "emoji-shortname": true
          }
        }}
      />
      <TextInput source="questionFr" />
      <br />
      <span>
        To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
      </span>
      <RichTextInput
        // toolbar={toolbarOptions}
        source="answerFr"
        options={{
          modules: {
            toolbar: toolbarOptions2,
            "emoji-toolbar": true,
            "emoji-textarea": true,
            "emoji-shortname": true
          }
        }} />
    </SimpleForm>
  </Edit>
);

export const HomeList = (props) => (
  <List {...props} perPage={25} pagination={<PostPagination />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="title" />
      <RichTextField source="content" stripTags />
      <TextField source="button" />
      <TextField source="titleFr" />
      <RichTextField source="contentFr" stripTags />
      <TextField source="buttonFr" />
      <EditButton basePath="/homes" />
    </Datagrid>
  </List>
);
export const HomeCreate = (props) => {
  // Transform the data before it is sent to the data provider
  const transformCreate = (data) => ({
    ...data,
    image: `https://${data.image.bucket}.s3.${data.image.region
      }.amazonaws.com/public/${data.image.key.replaceAll(" ", "+")}`,
  });

  return (
    <Create title="Create a Home Post" {...props} transform={transformCreate}>
      <SimpleForm>
        <AmplifyImageInput
          source="image"
          accept="image/*"
          validate={required()}
        />
        <TextInput source="title" validate={required()} />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="content"
          validate={required()}
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <TextInput source="button" validate={required()} />
        <TextInput source="titleFr" validate={required()} />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="contentFr"
          validate={required()}
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <TextInput source="buttonFr" validate={required()} />
      </SimpleForm>
    </Create>
  );
};
export const HomeEdit = (props) => {
  // Transform the data before it is sent to the data provider
  // For editing, we have to check if the user is updating the picture or not, if not
  // image.bucket will be undefined as data.image is just the URL, so return the URL of the previous image
  // instead of trying to update it with the https link, which will return https://undefined.s3.undefined.amazonaws.com/public/undefined
  const getImgString = (data) => {
    if (data.image.bucket === undefined) {
      return data.image;
    } else {
      return `https://${data.image.bucket}.s3.${data.image.region
        }.amazonaws.com/public/${data.image.key.replaceAll(" ", "+")}`;
    }
  };

  const transformCreate = (data) => ({
    ...data,
    image: getImgString(data),
  });

  return (
    <Edit {...props} transform={transformCreate}>
      <SimpleForm>
        <ImageField source="image" label="Current Image" />
        <span>
          Note: If there is no image displayed, this means that this section
          does not require an image and you can just ignore it. It will not
          affect the site.
        </span>
        <br />
        <AmplifyImageInput source="image" accept="image/*" />
        <span>
          Note: Image name can now include spaces, but try to avoid special
          characters such as ! = + - ?
        </span>
        <br />
        <TextInput source="title" />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="content"
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <TextInput source="button" />
        <TextInput source="titleFr" />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="contentFr"
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <TextInput source="buttonFr" />
      </SimpleForm>
    </Edit>
  );
};

export const ServiceList = (props) => (
  <List {...props} perPage={25} pagination={<PostPagination />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="title" />
      <RichTextField source="content" stripTags />
      <TextField source="button" />
      <TextField source="titleFr" />
      <RichTextField source="contentFr" stripTags />
      <TextField source="buttonFr" />
      <EditButton basePath="/services" />
    </Datagrid>
  </List>
);
export const ServiceCreate = (props) => {
  // Transform the data before it is sent to the data provider
  const transformCreate = (data) => ({
    ...data,
    image: `https://${data.image.bucket}.s3.${data.image.region
      }.amazonaws.com/public/${data.image.key.replaceAll(" ", "+")}`,
  });

  return (
    <Create title="Create a Home Post" {...props} transform={transformCreate}>
      <SimpleForm>
        <AmplifyImageInput
          source="image"
          accept="image/*"
          validate={required()}
        />
        <TextInput source="title" validate={required()} />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="content"
          validate={required()}
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <TextInput source="button" validate={required()} />
        <TextInput source="titleFr" validate={required()} />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="contentFr"
          validate={required()}
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <TextInput source="buttonFr" validate={required()} />
        <TextInput source="link" />
        <span>
          Link not required
        </span>
        <br />
      </SimpleForm>
    </Create>
  );
};
export const ServiceEdit = (props) => {
  // Transform the data before it is sent to the data provider
  // For editing, we have to check if the user is updating the picture or not, if not
  // image.bucket will be undefined as data.image is just the URL, so return the URL of the previous image
  // instead of trying to update it with the https link, which will return https://undefined.s3.undefined.amazonaws.com/public/undefined
  const getImgString = (data) => {
    if (data.image.bucket === undefined) {
      return data.image;
    } else {
      return `https://${data.image.bucket}.s3.${data.image.region
        }.amazonaws.com/public/${data.image.key.replaceAll(" ", "+")}`;
    }
  };

  const transformCreate = (data) => ({
    ...data,
    image: getImgString(data),
  });

  return (
    <Edit {...props} transform={transformCreate}>
      <SimpleForm>
        <ImageField source="image" label="Current Image" />
        <span>
          Note: If there is no image displayed, this means that this section
          does not require an image and you can just ignore it. It will not
          affect the site.
        </span>
        <br />
        <AmplifyImageInput source="image" accept="image/*" />
        <span>
          Note: Image name can now include spaces, but try to avoid special
          characters such as ! = + - ?
        </span>
        <br />
        <TextInput source="title" />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="content"
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <TextInput source="button" />
        <TextInput source="titleFr" />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="contentFr"
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <TextInput source="buttonFr" />
        <TextInput source="link" />
        <span>
          Link not required
        </span>
        <br />
      </SimpleForm>
    </Edit>
  );
};

export const AboutOthaliaContentList = (props) => (
  <List {...props} perPage={25} pagination={<PostPagination />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="title" />
      <RichTextField source="content" stripTags />
      <TextField source="button" />
      <TextField source="titleFr" />
      <RichTextField source="contentFr" stripTags />
      <TextField source="buttonFr" />
      <EditButton basePath="/AboutOthaliaContents" />
    </Datagrid>
  </List>
);
export const AboutOthaliaContentCreate = (props) => {
  // Transform the data before it is sent to the data provider
  const transformCreate = (data) => ({
    ...data,
    image: `https://${data.image.bucket}.s3.${data.image.region
      }.amazonaws.com/public/${data.image.key.replaceAll(" ", "+")}`,
  });

  return (
    <Create title="Create a Home Post" {...props} transform={transformCreate}>
      <SimpleForm>
        <AmplifyImageInput
          source="image"
          accept="image/*"
          validate={required()}
        />
        <TextInput source="title" validate={required()} />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="content"
          validate={required()}
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <TextInput source="button" validate={required()} />
        <TextInput source="titleFr" validate={required()} />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="contentFr"
          validate={required()}
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <TextInput source="buttonFr" validate={required()} />
      </SimpleForm>
    </Create>
  );
};
export const AboutOthaliaContentEdit = (props) => {
  // Transform the data before it is sent to the data provider
  // For editing, we have to check if the user is updating the picture or not, if not
  // image.bucket will be undefined as data.image is just the URL, so return the URL of the previous image
  // instead of trying to update it with the https link, which will return https://undefined.s3.undefined.amazonaws.com/public/undefined
  const getImgString = (data) => {
    if (data.image.bucket === undefined) {
      return data.image;
    } else {
      return `https://${data.image.bucket}.s3.${data.image.region
        }.amazonaws.com/public/${data.image.key.replaceAll(" ", "+")}`;
    }
  };

  const transformCreate = (data) => ({
    ...data,
    image: getImgString(data),
  });

  return (
    <Edit {...props} transform={transformCreate}>
      <SimpleForm>
        <ImageField source="image" label="Current Image" />
        <span>
          Note: If there is no image displayed, this means that this section
          does not require an image and you can just ignore it. It will not
          affect the site.
        </span>
        <br />
        <AmplifyImageInput source="image" accept="image/*" />
        <span>
          Note: Image name can now include spaces, but try to avoid special
          characters such as ! = + - ?
        </span>
        <br />
        <TextInput source="title" />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="content"
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <TextInput source="button" />
        <TextInput source="titleFr" />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="contentFr"
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <TextInput source="buttonFr" />
      </SimpleForm>
    </Edit>
  );
};

export const AboutContentList = (props) => (
  <List {...props} perPage={25} pagination={<PostPagination />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="title" />
      <RichTextField source="content" stripTags />
      <TextField source="button" />
      <TextField source="titleFr" />
      <RichTextField source="contentFr" stripTags />
      <TextField source="buttonFr" />
      <EditButton basePath="/AboutContents" />
    </Datagrid>
  </List>
);
export const AboutContentCreate = (props) => {
  // Transform the data before it is sent to the data provider
  const transformCreate = (data) => ({
    ...data,
    image: `https://${data.image.bucket}.s3.${data.image.region
      }.amazonaws.com/public/${data.image.key.replaceAll(" ", "+")}`,
  });

  return (
    <Create title="Create a Home Post" {...props} transform={transformCreate}>
      <SimpleForm>
        <AmplifyImageInput
          source="image"
          accept="image/*"
          validate={required()}
        />
        <TextInput source="title" validate={required()} />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="content"
          validate={required()}
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <TextInput source="button" validate={required()} />
        <TextInput source="titleFr" validate={required()} />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="contentFr"
          validate={required()}
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <TextInput source="buttonFr" validate={required()} />
      </SimpleForm>
    </Create>
  );
};
export const AboutContentEdit = (props) => {
  // Transform the data before it is sent to the data provider
  // For editing, we have to check if the user is updating the picture or not, if not
  // image.bucket will be undefined as data.image is just the URL, so return the URL of the previous image
  // instead of trying to update it with the https link, which will return https://undefined.s3.undefined.amazonaws.com/public/undefined
  const getImgString = (data) => {
    if (data.image.bucket === undefined) {
      return data.image;
    } else {
      return `https://${data.image.bucket}.s3.${data.image.region
        }.amazonaws.com/public/${data.image.key.replaceAll(" ", "+")}`;
    }
  };

  const transformCreate = (data) => ({
    ...data,
    image: getImgString(data),
  });

  return (
    <Edit {...props} transform={transformCreate}>
      <SimpleForm>
        <ImageField source="image" label="Current Image" />
        <span>
          Note: If there is no image displayed, this means that this section
          does not require an image and you can just ignore it. It will not
          affect the site.
        </span>
        <br />
        <AmplifyImageInput source="image" accept="image/*" />
        <span>
          Note: Image name can now include spaces, but try to avoid special
          characters such as ! = + - ?
        </span>
        <br />
        <TextInput source="title" />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="content"
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <TextInput source="button" />
        <TextInput source="titleFr" />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="contentFr"
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <TextInput source="buttonFr" />
      </SimpleForm>
    </Edit>
  );
};

export const ContactList = (props) => (
  <List {...props} perPage={25} pagination={<PostPagination />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="type" />
      <TextField source="title" />
      <TextField source="content" />
      <TextField source="titleFr" />
      <TextField source="contentFr" />
      <EditButton basePath="/Contacts" />
    </Datagrid>
  </List>
);
export const ContactCreate = (props) => {
  return (
    <Create title="Create a Home Post" {...props}>
      <SimpleForm>
        <AutocompleteArrayInput
          validate={required()}
          source="type"
          choices={[
            { id: "Question", name: "Question" },
            // {id: "Display", name:"Display"}
          ]}
        />
        <TextInput source="title" validate={required()} />
        <TextInput source="content" validate={required()} fullWidth={true} />
        <span>
          Note: This textfield is different from the other pages due to the
          implementation of the subjects. To add blank lines between paragraphs,
          use {"<br /><br/>"}. Check other posts for examples.
        </span>
        <TextInput source="titleFr" validate={required()} />
        <TextInput source="contentFr" validate={required()} fullWidth={true} />
      </SimpleForm>
    </Create>
  );
};

export const ContactEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="content" fullWidth={true} />
      <TextInput source="titleFr" />
      <TextInput source="contentFr" fullWidth={true} />
    </SimpleForm>
  </Edit>
);

export const AuthorList = (props) => (
  <List {...props} perPage={25} pagination={<PostPagination />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="author" />
      <TextField source="description" />
      <EditButton basePath="/authors" />
    </Datagrid>
  </List>
);

export const AuthorCreate = (props) => {
  // Transform the data before it is sent to the data provider
  const transformCreate = (data) => ({
    ...data,
    image: `https://${data.image.bucket}.s3.${data.image.region
      }.amazonaws.com/public/${data.image.key.replaceAll(" ", "+")}`,
  });

  return (
    <Create title="Create a Author" {...props} transform={transformCreate}>
      <SimpleForm>
        <AmplifyImageInput
          validate={required()}
          source="image"
          accept="image/*"
        />
        <TextInput source="author" validate={required()} />
        <TextInput source="description" validate={required()} />
      </SimpleForm>
    </Create>
  );
};

export const AuthorEdit = (props) => {
  // Transform the data before it is sent to the data provider
  // For editing, we have to check if the user is updating the picture or not, if not
  // image.bucket will be undefined as data.image is just the URL, so return the URL of the previous image
  // instead of trying to update it with the https link, which will return https://undefined.s3.undefined.amazonaws.com/public/undefined
  const getImgString = (data) => {
    if (data.image.bucket === undefined) {
      return data.image;
    } else {
      return `https://${data.image.bucket}.s3.${data.image.region
        }.amazonaws.com/public/${data.image.key.replaceAll(" ", "+")}`;
    }
  };

  const transformCreate = (data) => ({
    ...data,
    image: getImgString(data),
  });

  return (
    <Edit {...props} transform={transformCreate}>
      <SimpleForm>
        <ImageField source="image" label="Current Image" />
        <span>
          Note: If there is no image displayed, this means that this section
          does not require an image and you can just ignore it. It will not
          affect the site.
        </span>
        <br />
        <AmplifyImageInput source="image" accept="image/*" />
        <span>
          Note: Image name can now include spaces, but try to avoid special
          characters such as ! = + - ?
        </span>
        <br />
        <TextInput source="author" />
        <TextInput source="description" />
      </SimpleForm>
    </Edit>
  );
};

export const IconList = (props) => (
  <List {...props} perPage={25} pagination={<PostPagination />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="link" />
      <EditButton basePath="/iconimages" />
    </Datagrid>
  </List>
);

export const IconCreate = (props) => {

  // Transform the data before it is sent to the data provider
  const transformCreate = (data) => ({
    ...data,
    image: `https://${data.image.bucket}.s3.${data.image.region
      }.amazonaws.com/public/${data.image.key.replaceAll(" ", "+")}`
  });

  return (
    <Create title="Create a Post" {...props} transform={transformCreate}>
      <SimpleForm>
        <AmplifyImageInput
          validate={required()}
          source="image"
          accept="image/*"
        />
        <span>
          Note: Need an image
        </span>
        <br />
        <TextInput source="title" validate={required()} />
        <TextInput source="link" validate={required()} />
      </SimpleForm>
    </Create>
  );
};
export const IconEdit = (props) => {
  // Transform the data before it is sent to the data provider
  // For editing, we have to check if the user is updating the picture or not, if not
  // image.bucket will be undefined as data.image is just the URL, so return the URL of the previous image
  // instead of trying to update it with the https link, which will return https://undefined.s3.undefined.amazonaws.com/public/undefined
  const getImgString = (data) => {
    if (data.image.bucket === undefined) {
      return data.image;
    } else {
      return `https://${data.image.bucket}.s3.${data.image.region
        }.amazonaws.com/public/${data.image.key.replaceAll(" ", "+")}`;
    }
  };

  const transformCreate = (data) => ({
    ...data,
    image: getImgString(data),
  });

  return (
    <Edit {...props} transform={transformCreate}>
      <SimpleForm>
        <ImageField source="image" label="Current Image" />
        <AmplifyImageInput source="image" accept="image/*" />
        <span>
          Note: Image name can now include spaces, but try to avoid special
          characters such as ! = + - ?
        </span>
        <br />
        <TextInput source="title" />
        <TextInput source="link" />
      </SimpleForm>
    </Edit>
  );
};

export const PopupList = (props) => (
  <List {...props} perPage={25} pagination={<PostPagination />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="location" label="Popup location" />
      <TextField source="title" />
      <RichTextField source="content" stripTags />
      <TextField source="titleFr" />
      <RichTextField source="contentFr" stripTags />
      <BooleanField source="enable" />
      <EditButton basePath="/popups" />
    </Datagrid>
  </List>
);

export const PopupCreate = (props) => {
  // Transform the data before it is sent to the data provider
  const transformCreate = (data) => ({
    ...data,
    image: `https://${data.image.bucket}.s3.${data.image.region
      }.amazonaws.com/public/${data.image.key.replaceAll(" ", "+")}`,
  });

  return (
    <Create title="Create a Popup" {...props} transform={transformCreate}>
      <SimpleForm>
        <AmplifyImageInput
          validate={required()}
          source="image"
          accept="image/*"
        />
        <span>
          Note: Need an image, even if there is a video, the image is used to
          display the blog on the main blog page. Image name can now include spaces, but try to avoid special
          characters such as ! = + - ?
        </span>
        <br />
        <TextInput source="location" />
        <span>
          Note: Location of the popup, wont necessarily affect the popup. This field is case insensitive.
          Make sure that the location matches the name of the location, for example, for "about innovfin" page make sure the location is "about innovfin"
          More examples: Blog post page = blog post, blog = blog, about othalia = about othalia...
          <br />
          For more specific implementations, please ask a developer (for example the "Get it Now Banner")
        </span>
        <br />
        <TextInput source="title" validate={required()} />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="content"
          validate={required()}
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <TextInput source="button" validate={required()} label="Button Text" />
        <span>
          Note: Text on the button
        </span>
        <br />
        <TextInput source="link" label="Button Redirect" />
        <span>
          Note: Post the link that the english button redirects to.
        </span>
        <br />
        <TextInput source="titleFr" validate={required()} />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="contentFr"
          validate={required()}
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <TextInput source="buttonFr" validate={required()} label="French Button Text" />
        <span>
          Note: Text on the button
        </span>
        <br />
        <TextInput source="linkFr" label="French Button Redirect" />
        <span>
          Note: Post the link that the french button redirects to.
        </span>
        <br />
        <BooleanInput label="Enabled" source="enable" />
      </SimpleForm>
    </Create>
  );
};
export const PopupEdit = (props) => {
  // Transform the data before it is sent to the data provider
  // For editing, we have to check if the user is updating the picture or not, if not
  // image.bucket will be undefined as data.image is just the URL, so return the URL of the previous image
  // instead of trying to update it with the https link, which will return https://undefined.s3.undefined.amazonaws.com/public/undefined
  const getImgString = (data) => {
    if (data.image.bucket === undefined) {
      return data.image;
    } else {
      return `https://${data.image.bucket}.s3.${data.image.region
        }.amazonaws.com/public/${data.image.key.replaceAll(" ", "+")}`;
    }
  };

  const transformCreate = (data) => ({
    ...data,
    image: getImgString(data)
  });

  return (
    <Edit {...props} transform={transformCreate}>
      <SimpleForm>
        <ImageField source="image" label="Current Image" />
        <AmplifyImageInput source="image" accept="image/*" />
        <span>
          Note: If there is no image displayed, this means that this section
          does not require an image and you can just ignore it. It will not
          affect the site.
        </span>
        <br />
        <TextInput source="location" disabled={true} />
        <span>
          Note: Location of the popup, wont necessarily affect the popup. This field is case insensitive.
          Make sure that the location matches the name of the location, for example, for "about innovfin" page make sure the location is "about innovfin"
          More examples: Blog post page = blog post, blog = blog, about othalia = about othalia...
          <br />
          For more specific implementations, please ask a developer (for example the "Get it Now Banner")
        </span>
        <br />
        <TextInput source="title" />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="content"
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <TextInput source="button" label="Button Text" />
        <span>
          Note: Text on the button
        </span>
        <br />
        <TextInput source="link" label="Button Redirect" />
        <span>
          Note: Post the full redirect link that the english button redirects to.
        </span>
        <br />
        <TextInput source="titleFr" />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="contentFr"
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <TextInput source="buttonFr" label="French Button Text" />
        <span>
          Note: Text on the button
        </span>
        <br />
        <TextInput source="linkFr" label="French Button Redirect" />
        <span>
          Note: Post the full redirect link that the french button redirects to.
        </span>
        <br />
        <BooleanInput label="Enabled" source="enable" />
      </SimpleForm>
    </Edit>
  );
};
export const TermList = (props) => (
  <List {...props} perPage={25} pagination={<PostPagination />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="title" />
      <RichTextField source="content" stripTags />
      <TextField source="titleFr" />
      <RichTextField source="contentFr" stripTags />
      <EditButton basePath="/terms" />
    </Datagrid>
  </List>
);

export const TermCreate = (props) => {

  return (
    <Create title="Create..." {...props}>
      <SimpleForm>
        <TextInput source="title" validate={required()} />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="content"
          validate={required()}
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <TextInput source="titleFr" validate={required()} />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="contentFr"
          validate={required()}
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
      </SimpleForm>
    </Create>
  );
};

export const TermEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="title" />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="content"
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
        <TextInput source="titleFr" />
        <br />
        <span>
          To undo or redo actions in this field, you can use the keyboard shortcuts "CTRL + Z (Windows)/Command + Z (Mac)" to undo and "CTRL + Y (Windows)/Command + Y (Mac)" to redo.
        </span>
        <RichTextInput
          // toolbar={toolbarOptions} 
          source="contentFr"
          options={{
            modules: {
              toolbar: toolbarOptions2,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true
            }
          }}
        />
      </SimpleForm>
    </Edit>
  );
};