import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import RegisterSchema from './Schema/RegisterSchema';
import RegisterSchemaFr from './Schema/RegisterSchemaFr';
import Auth from '@aws-amplify/auth';

function Register({ lang }) {
    const storedForm = JSON.parse(localStorage.getItem('formState'))
    const [formState, setFormState] = useState(storedForm)
    const [localFormState] = useState(storedForm)

    useEffect(() => {
        localStorage.setItem("formState", JSON.stringify(formState))
    }, [formState])
    async function signUp(data) {
        try {
            const { username, email, password, firstname, lastname } = data
            await Auth.signUp({ username, password, attributes: { email, 'custom:firstname': firstname, 'custom:lastname': lastname } })
            setFormState(() => ({ ...localFormState, formType: "confirmSignUp" }))
            window.location.reload();
        } catch (UsernameExistsException) {
            alert('Username is already in use!')
        }
    }
    
    return (
        <Container>
            <div className="infoContainer" >
                <React.Fragment>
                    <h1>{lang === 'fr' ? 'S\'inscrire' : 'Sign up'}</h1>
                    <p style={{ padding: '0' }}>
                        {lang === 'fr' ? <span>Nouveau sur InnovFin ? <br />Créez un compte ci-dessous pour vous tenir au courant de nos activités.</span> : 
                        <span>New to InnovFin? <br />Create an account below to keep up to date with us. </span>}
                    </p>
                </React.Fragment>
                <Container className="registerContainerWithBorder" >
                    <Formik
                        validationSchema={lang === 'fr' ? RegisterSchemaFr : RegisterSchema}
                        onSubmit={(data, { setSubmitting }) => {
                            setSubmitting(true);
                            signUp(data);
                            // console.log("Submit: ", data);
                            setSubmitting(false);
                        }}
                        initialValues={{
                            username: '',
                            password: '',
                            firstname: '',
                            lastname: '',
                            email: '',
                        }}

                    >
                        {({
                            handleSubmit,
                            handleChange,
                            isSubmitting,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors,
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <Row className="mb-6">
                                    {/*  F Name  */}
                                    <Form.Group as={Col} md="6">
                                        {lang === 'fr' ? <Form.Text>Prénom</Form.Text> : <Form.Text>First name</Form.Text>}
                                        <Form.Control type="text" name="firstname" value={values.firstname} onChange={handleChange} isValid={touched.firstname && !errors.firstname} id="firstname" />
                                        <Form.Control.Feedback type="invalid"> {errors.firstname} </Form.Control.Feedback>
                                    </Form.Group>

                                    {/* L Name */}
                                    <Form.Group as={Col} md="6">
                                        {lang === 'fr' ? <Form.Text>Nom de famille</Form.Text> : <Form.Text>Last name</Form.Text>}
                                        <Form.Control type="text" name="lastname" value={values.lastname} onChange={handleChange} isValid={touched.lastname && !errors.lastname} id="lastname" />
                                        <Form.Control.Feedback type="invalid"> {errors.lastname} </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row className="mb-6">
                                    {/* <!-- Email --> */}
                                    <Form.Group as={Col} md="6">
                                        {lang === 'fr' ? <Form.Text>Courriel</Form.Text> : <Form.Text>Email</Form.Text>}
                                        <Form.Control type="email" name="email" value={values.email} onChange={handleChange} isValid={touched.email && !errors.email} isInvalid={!!errors.email} id="email" />
                                        <Form.Control.Feedback type="invalid"> {errors.email} </Form.Control.Feedback>
                                    </Form.Group>

                                    {/*    UserName  */}
                                    <Form.Group as={Col} md="6">
                                        {lang === 'fr' ? <Form.Text>Nom d'utilisateur</Form.Text> : <Form.Text>Username</Form.Text>}
                                        <Form.Control type="text" name="username" value={values.username} onChange={handleChange} isValid={touched.username && !errors.username} isInvalid={!!errors.username} id="userName" />
                                        <Form.Control.Feedback type="invalid"> {errors.username} </Form.Control.Feedback>
                                    </Form.Group>

                                </Row>

                                <Row className="mb-6">

                                    {/*     Password  */}
                                    <Form.Group as={Col} md="6">
                                        {lang === 'fr' ? <Form.Text>Mot de passe</Form.Text> : <Form.Text>Password</Form.Text>}
                                        <Form.Control type="password" name="password" value={values.password} onChange={handleChange} isValid={touched.password && !errors.password} isInvalid={!!errors.password} id="password" />
                                        <Form.Control.Feedback type="invalid"> {errors.password} </Form.Control.Feedback>
                                    </Form.Group>
                                    {/*  Confirm Password */}
                                    <Form.Group as={Col} md="6">
                                        {lang === 'fr' ? <Form.Text>Confirmer le mot de passe</Form.Text> : <Form.Text>Confirm Password</Form.Text>} 
                                        <Form.Control type="password" value={values.confirmPassword} onChange={handleChange} isValid={touched.confirmPassword && !errors.confirmPassword} isInvalid={!!errors.confirmPassword} id="confirmPassword" />
                                        <Form.Control.Feedback type="invalid"> {errors.confirmPassword} </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Button
                                    // onClick={testSend} value={JSON.stringify(values)} 
                                    disabled={isSubmitting} className="loginBtn" type="submit">{lang === 'fr' ? 'S\'inscrire' : 'Sign up'}</Button>
                            </Form>
                        )}
                    </Formik>

                    <div className="bottomSection">
                        <Row>
                            <Col xs={12} sm={6} md={6} ><Form.Text className="text-muted">
                                {lang === 'fr' ? 'Vous avez déjà un compte?' :  'Already have an account?'}<br/></Form.Text>
                                <button onClick={() => {
                                    setFormState(() => ({ ...formState, formType: "signIn" }));
                                    window.location.reload();
                                }
                                } className="btn-link signUpLbl">{lang === 'fr' ? 'Connexion' : 'Login'}</button></Col>
                            <Col xs={12} sm={6} md={6}><Form.Text className="text-muted">
                                    {lang === 'fr' ? 'Mot de passe oublié?' : 'Forgot your password?'}<br/></Form.Text>
                                <button onClick={() => {
                                    setFormState(() => ({ ...formState, formType: "forgotPass" }));
                                    window.location.reload();
                                }
                                } className="btn-link signUpLbl">{lang === 'fr' ? 'Réinitialiser le mot de passe' : 'Reset Password'}</button></Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} md={6} ><Form.Text className="text-muted">
                            {lang === 'fr' ? 'Vous avez besoin de confirmer votre compte?' : 'Need to confirm your account?'}<br/></Form.Text>
                                <button onClick={() => {
                                    setFormState(() => ({ ...formState, formType: "confirmSignUp" }));
                                    window.location.reload();
                                }
                                } className="btn-link signUpLbl">{lang === 'fr' ? 'Confirmer' : 'Confirm'}</button></Col>
                        </Row>
                    </div>

                </Container>
            </div>
        </Container>
    )
}

export default Register;