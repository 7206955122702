import React, { useEffect, useState } from 'react';
import '../styling/Services.css';
import { Button, Card, CardGroup, Container, Row } from 'react-bootstrap';
import metaData from "../json_files/metadata.json";
import HelmetMetaData from '../components/HelmetMetaData';
import { listServices } from "../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import PopupComponent from '../components/PopupComponent';

function Strategy() {
    const { lang } = useParams();
    // For the meta data stuff
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [keywords, setKeywords] = useState("");
    // Get location metadata from JSON file, this system maybe used in the future so that
    // non-developers can edit metadata.
    const getMeta = (data) => {
        if (data.url === window.location.pathname.split("/")[2]) {
            setTitle(data.title);
            setDescription(data.description);
            setKeywords(data.keywords);
        }
    };

    //the below is used for storing the content that will be grabbed from the API
    //is loaded is to check if the api is loaded
    const [serviceContent, setServiceContent] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const setContentAPI = () => {
        API.graphql(graphqlOperation(listServices))
            .then((data) => {
                const modifiedData2 = data["data"]["listServices"]["items"];

                modifiedData2.map((item) => {
                    item.content = parse(item.content);
                    item.contentFr = parse(item.contentFr);
                    return item;
                });

                setServiceContent(modifiedData2);
                setIsLoaded(true);
                // console.log("content education: ", serviceContent.find(find => find.id === "4c769797-8af0-4a49-b30a-6d577e8eb9d4"));
            })
            .catch((err) => {
                console.log(err);
            });
    }

     //list of class names to disable right-click
     const imageClasses = [".card-img-dropshadow", ".primaryElement-img"];

     //remove the context menu for the images
     const removeRightClick = () =>{
         imageClasses.forEach(imgClass => {
             const noContextMenu = document.querySelectorAll(imgClass);
             noContextMenu.forEach(data => {
                 data.addEventListener("contextmenu", e => e.preventDefault());
             })
         })
     }

    useEffect(() => {
        setContentAPI();
        removeRightClick();
        metaData.forEach(data => {
            getMeta(data);
        });
    }, []);
    return (
        <Container>
            <HelmetMetaData
                title={title}
                description={description}
                keywords={keywords}>
            </HelmetMetaData>
            <div className='infoContainer' id='serviceContainer'>
                <h1>
                    {
                        !isLoaded ?
                            (lang === 'fr' ?
                                'Services de stratégie'
                                : 'Strategy Services'
                            )
                            :
                            (lang === 'fr' ?
                                serviceContent.find(find => find.id === "a3ce6131-d33a-4d2d-aa13-36ecfc42737c").titleFr
                                : serviceContent.find(find => find.id === "a3ce6131-d33a-4d2d-aa13-36ecfc42737c").title
                            )
                    }
                </h1>
                <div className="infoContainer-description">
                    {
                        !isLoaded ?
                            (lang === 'fr' ?
                                <p>
                                    Notre équipe d'experts en Blockchain s'engage à vous aider à développer et à intégrer une stratégie Blockchain gagnante pour atteindre votre mission et votre vision. Contactez-nous dès aujourd'hui pour voir comment nous pouvons vous aider.
                                </p>
                                : <p>
                                    Our team of Blockchain experts is committed to helping you develop and integrate a winning Blockchain Strategy to reach your mission and vision. Contact us today to see how we can help.
                                </p>
                            )
                            :
                            (lang === 'fr' ?
                                serviceContent.find(find => find.id === "a3ce6131-d33a-4d2d-aa13-36ecfc42737c").contentFr
                                : serviceContent.find(find => find.id === "a3ce6131-d33a-4d2d-aa13-36ecfc42737c").content
                            )
                    }
                </div>

                {/* Container for the cards */}
                <Container className="serviceContainer" style={{ paddingLeft: '1%', paddingRight: '1%' }}>
                    <Row className="justify-content-md-center">
                        <CardGroup className="justify-content-md-center">

                            <Card className="infoCard">
                                <Card.Body>
                                    <h1>
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    'Session de conception'
                                                    : 'Design Session'
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    serviceContent.find(find => find.id === "ed914533-0db6-4144-bfde-2ddadace9bc6").titleFr
                                                    : serviceContent.find(find => find.id === "ed914533-0db6-4144-bfde-2ddadace9bc6").title
                                                )
                                        }
                                    </h1>
                                    <Card.Img className="card-img-dropshadow" variant="top" src={!isLoaded ? "../assets/Strategy Services Assets/DesignSession.png" : serviceContent.find(find => find.id === "ed914533-0db6-4144-bfde-2ddadace9bc6").image} />
                                    {
                                        !isLoaded ?
                                            (lang === 'fr' ?
                                                <p>
                                                    <span>
                                                        Vous envisagez d'intégrer la blockchain dans votre entreprise mais avez besoin d'aide pour peaufiner votre idée ? Ne le faites pas seul!  Nous sommes là pour vous aider!
                                                        <br /><br />
                                                        Contactez-nous dès aujourd'hui pour planifier une séance de conception avec nous!
                                                    </span>
                                                </p>
                                                : <p>
                                                    <span>
                                                        Are you planning to integrate Blockchain into your business but need help fine-tuning your idea? Don't do it alone! We are here to help!
                                                        <br /><br />
                                                        Contact us today to schedule a Design Session with us!
                                                    </span>
                                                </p>
                                            )
                                            :
                                            (lang === 'fr' ?
                                                serviceContent.find(find => find.id === "ed914533-0db6-4144-bfde-2ddadace9bc6").contentFr
                                                : serviceContent.find(find => find.id === "ed914533-0db6-4144-bfde-2ddadace9bc6").content
                                            )
                                    }
                                </Card.Body>
                                <Card.Footer>
                                    <Button href={`/${lang}/contact-us/`} className='button'>
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    'Contactez-nous'
                                                    : 'Contact Us'
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    serviceContent.find(find => find.id === "ed914533-0db6-4144-bfde-2ddadace9bc6").buttonFr
                                                    : serviceContent.find(find => find.id === "ed914533-0db6-4144-bfde-2ddadace9bc6").button
                                                )
                                        }
                                    </Button>
                                </Card.Footer>
                            </Card>

                            <Card className="infoCard">
                                <Card.Body>
                                    <h1>
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    'Rédaction du plan d\'affaires'
                                                    : 'Business Plan Writing'
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    serviceContent.find(find => find.id === "f21c69d9-1f8c-43b2-a2e8-d0b4a6e27f66").titleFr
                                                    : serviceContent.find(find => find.id === "f21c69d9-1f8c-43b2-a2e8-d0b4a6e27f66").title
                                                )
                                        }
                                    </h1>
                                    <Card.Img className="card-img-dropshadow" variant="top" src={!isLoaded ? "../assets/Strategy Services Assets/BusinessPlanWriting.png" : serviceContent.find(find => find.id === "f21c69d9-1f8c-43b2-a2e8-d0b4a6e27f66").image}/>
                                    {
                                        !isLoaded ?
                                            (lang === 'fr' ?
                                                <p>
                                                    <span>
                                                        Vous avez choisi une idée de blockchain mais vous avez besoin d'aide pour rédiger un plan d'affaires gagnant pour obtenir une adhésion interne ou externe?
                                                        <br /><br />
                                                        Contactez-nous dès aujourd'hui pour obtenir l'aide d'un expert en blockchain!
                                                    </span>
                                                </p>
                                                : <p>
                                                    <span>
                                                        You have settled on a Blockchain idea but need help to write a winning Business Plan for internal or external buy-in?
                                                        <br /><br />
                                                        Contact us today to get the help of a Blockchain expert!
                                                    </span>
                                                </p>
                                            )
                                            :
                                            (lang === 'fr' ?
                                                serviceContent.find(find => find.id === "f21c69d9-1f8c-43b2-a2e8-d0b4a6e27f66").contentFr
                                                : serviceContent.find(find => find.id === "f21c69d9-1f8c-43b2-a2e8-d0b4a6e27f66").content
                                            )
                                    }
                                </Card.Body>
                                <Card.Footer>
                                    <Button href={`/${lang}/contact-us/`} className='button'>
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    'Contactez-nous'
                                                    : 'Contact Us'
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    serviceContent.find(find => find.id === "f21c69d9-1f8c-43b2-a2e8-d0b4a6e27f66").buttonFr
                                                    : serviceContent.find(find => find.id === "f21c69d9-1f8c-43b2-a2e8-d0b4a6e27f66").button
                                                )
                                        }
                                    </Button>
                                </Card.Footer>
                            </Card>

                            <Card className="infoCard">
                                <Card.Body>
                                    <h1>
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    'Rédaction de livres blancs'
                                                    : 'White Paper Writing'
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    serviceContent.find(find => find.id === "ce8ee862-64cb-4d4e-8344-21b5e174f9df").titleFr
                                                    : serviceContent.find(find => find.id === "ce8ee862-64cb-4d4e-8344-21b5e174f9df").title
                                                )
                                        }
                                    </h1>
                                    <Card.Img className="card-img-dropshadow" variant="top" src={!isLoaded ? "../assets/Strategy Services Assets/WhitePaperWriting.png" : serviceContent.find(find => find.id === "ce8ee862-64cb-4d4e-8344-21b5e174f9df").image} />
                                    {
                                        !isLoaded ?
                                            (lang === 'fr' ?
                                                <p>
                                                    <span>
                                                        Votre plan d\'affaires est prêt, mais vous avez besoin d\'un livre blanc qui aborde les aspects techniques de votre projet? Laissez-nous vous aider!
                                                        <br /><br />
                                                        Contactez-nous dès aujourd\'hui pour obtenir l\'aide d\'un expert technique en blockchain!
                                                    </span>
                                                </p>
                                                : <p>
                                                    <span>
                                                        Your business plan is all set, but do you need a White Paper that dives into the technical nitty-gritty of your project? Let us help you!
                                                        <br /><br />
                                                        Contact us today to get the help of a Blockchain technical expert!
                                                    </span>
                                                </p>
                                            )
                                            :
                                            (lang === 'fr' ?
                                                serviceContent.find(find => find.id === "ce8ee862-64cb-4d4e-8344-21b5e174f9df").contentFr
                                                : serviceContent.find(find => find.id === "ce8ee862-64cb-4d4e-8344-21b5e174f9df").content
                                            )
                                    }
                                </Card.Body>
                                <Card.Footer>
                                    <Button href={`/${lang}/contact-us/`} className='button'>
                                        {
                                            !isLoaded ?
                                                (lang === 'fr' ?
                                                    'Contactez-nous'
                                                    : 'Contact Us'
                                                )
                                                :
                                                (lang === 'fr' ?
                                                    serviceContent.find(find => find.id === "ce8ee862-64cb-4d4e-8344-21b5e174f9df").buttonFr
                                                    : serviceContent.find(find => find.id === "ce8ee862-64cb-4d4e-8344-21b5e174f9df").button
                                                )
                                        }
                                    </Button>
                                </Card.Footer>
                            </Card>
                            
                        </CardGroup>
                    </Row>
                </Container>
            </div>
            {/* The following is used to show any popup at this location, there may not be any popups to show */}
            <PopupComponent lang={lang} location="strategy"/>
        </Container>
    )
}

export default Strategy;