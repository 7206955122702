import * as Yup from 'yup';

const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .required("Username is required"),
    password: Yup.string()
    .min(5, "Password must be 5 characters minimum")
    .required("Password is required")
})

export default LoginSchema;