import React, { useEffect, useState } from 'react';
import './styling/Services.css'
import { Container } from 'react-bootstrap';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import faqSheet from './json_files/FAQ.json';
import faqSheetFr from './json_files/FAQFr.json';
import parse from 'html-react-parser';
import metaData from "./json_files/metadata.json";
import HelmetMetaData from './components/HelmetMetaData';
import { API, graphqlOperation } from "aws-amplify";
import { listFAQposts } from "./graphql/queries";
import { useParams } from "react-router-dom";
import PopupComponent from './components/PopupComponent';

function FAQ() {
    const { lang } = useParams();
    // For the meta data stuff
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [keywords, setKeywords] = useState("");
    // Get location metadata from JSON file, this system maybe used in the future so that
    // non-developers can edit metadata.
    const getMeta = (data) => {
        if (data.url === window.location.pathname.split("/")[2]) {
            setTitle(data.title);
            setDescription(data.description);
            setKeywords(data.keywords);
        }
    };

    const [isLoaded, setIsLoaded] = useState(false);
    const [faqpost, setFaqpost] = useState([]);
    //we will remove the "Frequently asked quesion" header from the FAQpost array, so use these
    //variables to set header
    const [header, setHeader] = useState([]);
    const [headerText, setHeaderText] = useState([]);

    useEffect(() => {
        API.graphql(graphqlOperation(listFAQposts))
            .then((data) => {
                const modifiedData2 = data["data"]["listFAQposts"]["items"];
                // console.log(modifiedData2)
                modifiedData2.map((item) => {
                    item.answer = parse(item.answer);
                    item.answerFr = parse(item.answerFr);
                    if (item.id === "46aff8cb-ca24-4aca-b655-e93d9da7efb7") {
                        header.push(item.question)
                        headerText.push(item.answer)
                        header.push(item.questionFr)
                        headerText.push(item.answerFr)
                        modifiedData2.splice(modifiedData2.indexOf(item), 1)
                    }

                    return item;
                });
                // modifiedData2.splice(modifiedData2.indexOf(),1)

                //sorts posts by date created
                modifiedData2.sort(
                    function (a, b) {
                        // console.log("a ", a)
                        return a.createdAt.replace(/-/g, "").replaceAll(":", "").replaceAll("T", "").substr(0, 15)
                            - b.createdAt.replace(/-/g, "").replaceAll(":", "").replaceAll("T", "").substr(0, 15)
                    }
                )
                setFaqpost(modifiedData2);
                setIsLoaded(true);
            })
            .catch((err) => {
                console.log(err);
            });

        metaData.forEach(data => {
            getMeta(data);
        });
    }, []);
    return (
        <Container>
            <HelmetMetaData
                title={title}
                description={description}
                keywords={keywords}>
            </HelmetMetaData>
            <div className='infoContainer'>
                {!isLoaded ? (
                    <div>
                        <h1>
                            {lang === 'fr' ?
                                'Questions fréquemment posées'
                                : 'Frequently Asked Questions'
                            }
                        </h1>
                        <p className="infoContainer-description">
                            {lang === 'fr' ?
                                'Vous avez une question à laquelle vous cherchez une réponse? Vous trouverez ci-dessous une liste de questions fréquemment posées !'
                                : 'Got a question that needs answering? Find a list of frequently asked questions below!'
                            }
                        </p>
                    </div>
                ) : (
                    lang === "fr" ?
                        <div className="faqContainer">
                            <h1>
                                {/* {faqpost.find(find => find.id === "46aff8cb-ca24-4aca-b655-e93d9da7efb7").questionFr} */}
                                {header[1]}
                            </h1>
                            {headerText[1]}
                        </div>
                        : <div className="faqContainer">
                            <h1>
                                {header[0]}
                                {/* {faqpost.find(find => find.id === "46aff8cb-ca24-4aca-b655-e93d9da7efb7").question} */}
                            </h1>
                            {headerText[0]}
                        </div>
                )}


                {/* Container for the cards */}

                <Container id="accordion" className="serviceContainer">

                    {//Check if data is loaded from the API, set in useEffect, if not the page will load the default JSON, else it will load the data from API
                        !isLoaded ? (
                            lang === "fr" ?
                                faqSheetFr.map((content, i) => (
                                    <Accordion key={i} style={{ backgroundColor: '#e7e7e7' }}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <p style={{ fontWeight: '900', margin: '0' }}> {content.question} </p>
                                        </AccordionSummary>
                                        <AccordionSummary>
                                            <p> {parse(JSON.stringify(content.answer).substring(1, content.answer.length + 1))} </p>
                                        </AccordionSummary>
                                    </Accordion>
                                ))
                                : faqSheet.map((content, i) => (
                                    <Accordion key={i} style={{ backgroundColor: '#e7e7e7' }}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <p style={{ fontWeight: '900', margin: '0' }}> {content.question} </p>
                                        </AccordionSummary>
                                        <AccordionSummary>
                                            <p style={{ paddingBottom: '2em' }}> {parse(JSON.stringify(content.answer).substring(1, content.answer.length + 1))}</p>
                                        </AccordionSummary>
                                    </Accordion>
                                ))
                        ) : (
                            lang === "fr" ?
                                faqpost.map((post, i) => {
                                    return (
                                        <Accordion key={i} style={{ backgroundColor: '#e7e7e7' }}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <p style={{ fontWeight: '900', margin: '0' }}> {post.questionFr} </p>
                                            </AccordionSummary>
                                            <AccordionSummary>
                                                <div style={{ paddingBottom: '2em' }}> {post.answerFr}</div>
                                            </AccordionSummary>
                                        </Accordion>
                                    )
                                })
                                :
                                faqpost.map((post, i) => {
                                    return (
                                        <Accordion key={i} style={{ backgroundColor: '#e7e7e7' }}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <p style={{ fontWeight: '900', margin: '0' }}> {post.question} </p>
                                            </AccordionSummary>
                                            <AccordionSummary>
                                                <div style={{ paddingBottom: '2em' }}> {post.answer}</div>
                                            </AccordionSummary>
                                        </Accordion>
                                    )
                                })
                        )
                    }
                </Container>
            </div>
            {/* The following is used to show any popup at this location, there may not be any popups to show */}
            <PopupComponent lang={lang} location="faq"/>
        </Container>
    )
}

export default FAQ;