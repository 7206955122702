/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:3f0ac24c-ecaa-4835-b0ca-cfc9b6267327",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_dnS7DeJEU",
    "aws_user_pools_web_client_id": "51d4oarvefa450b1u19s8t1m6f",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "restapi",
            "endpoint": "https://wj71jdm9ge.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        },
        {
            "name": "AdminQueries",
            "endpoint": "https://xm3il5avf1.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://7d77uaafh5dyxklpduj7vopzvm.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-kh7sjalejrgvjbxyggioqj5xk4",
    "aws_mobile_analytics_app_id": "6c1b3faf603d42959321cb77c4ac4293",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_user_files_s3_bucket": "blogimages110601-dev",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ]
};


export default awsmobile;
