import React, { useEffect, useState } from 'react';
import Login from './Account/Login';
import Register from './Account/Register';
import RegisterConfirm from './Account/RegisterConfirm';
import { Auth, Hub } from 'aws-amplify';
import { Button, Col, Container, Row } from 'react-bootstrap';
import ForgotPassword from './Account/ForgotPassword';
import ForgotPasswordConfirm from './Account/ForgotPasswordConfirm';
import metaData from "./json_files/metadata.json";
import HelmetMetaData from './components/HelmetMetaData';
import { useParams } from "react-router-dom";
import PopupComponent from './components/PopupComponent';

const initialFormState = {
    firstname: '', lastname: '', username: '', password: '', email: '', authCode: '', formType: 'signIn'
}

function Account() {
    const { lang } = useParams();
    // For the meta data stuff
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [keywords, setKeywords] = useState("");
    // Get location metadata from JSON file, this system maybe used in the future so that
    // non-developers can edit metadata.
    const getMeta = (data) => {
        if (data.url === window.location.pathname.split("/")[2]) {
            setTitle(data.title);
            setDescription(data.description);
            setKeywords(data.keywords);
        }
    };

    var useObj = initialFormState;
    if (localStorage.getItem("formState") === null) {
        useObj = initialFormState;
    } else {
        useObj = JSON.parse(localStorage.getItem("formState"));
    }
    // const storedState = localStorage.getItem("formState");
    const [formState, setFormState] = useState(useObj);
    // var storedForm = localStorage.getItem('formState')
    // const [formState, setFormState] = useState(storedState)

    const [user, updateUser] = useState(null)


    useEffect(() => {
        localStorage.setItem("formState", JSON.stringify(formState))
        // setRedirect(true);
    }, [formState])

    useEffect(() => {
        checkUser()
        setAuthListener()
        metaData.forEach(data => {
            getMeta(data);
        });
    }, [])

    async function setAuthListener() {
        Hub.listen('auth', (data) => {
            switch (data.payload.event) {
                case 'signOut':
                    // console.log('data from event: ', data);
                    setFormState(() => ({ ...formState, formType: "signIn" }));
                    break;
                default:
                    break;
            }
        })
    }

    async function checkUser() {
        try {
            const user = await Auth.currentAuthenticatedUser()
            updateUser(user)
            // console.log('user: ', user)
            setFormState(() => ({ ...formState, formType: "signedIn" }))
        } catch (e) {
            console.log('error: ', e)
        }
    }

    const { formType } = formState
    return (
        <div>
            <HelmetMetaData
                title={title}
                description={description}
                keywords={keywords}>
            </HelmetMetaData>
            {/* {console.log("A " + formType)} */}
            {/* 
            Forms for different page types to be use depending on the state of user authentication
            if the user has not signed up, they can access either sign up or a login page, or a confirm account page
            once they have signed it, this page will display the users profile information
            */}
            {
                formType === 'signUp' && (
                    <div>
                        <HelmetMetaData
                            title="Sign up for a new InnovFin account"
                            description="Sign up with for a new InnovFin account to keep up to date with us and recieve other perks and benefits too!"
                            keywords={keywords}>
                        </HelmetMetaData>
                        <Register lang={lang} />
                    </div>
                )
            }
            {
                formType === 'confirmSignUp' && (
                    <div>
                        <HelmetMetaData
                            title="Confirm your signup"
                            description="Check your email to confirm the creation of your InnovFin account"
                            keywords={keywords}>
                        </HelmetMetaData>
                        <RegisterConfirm lang={lang} />
                    </div>
                )
            }
            {
                formType === 'signIn' && (
                    <div>
                        <HelmetMetaData
                            title="Login to your InnovFin account"
                            description="Login to your innovfin account to access infoormation related to you and keep up to date with InnovFin"
                            keywords={keywords}>
                        </HelmetMetaData>
                        <Login lang={lang} />
                    </div>
                )
            }
            {
                formType === 'signedIn' && (
                    <Container>
                        <div className="infoContainer">
                            {/* Needs the === null as the user information will not load before the first render causing an issue */}
                            {user === null ? <p>Loading...</p> :
                                <div>
                                    <h1>{user.attributes['custom:firstname']}'s Profile</h1>
                                    <p>Here is the information related to your account.</p>
                                </div>}
                            <Container className="loginContainerWithBorder" >
                                <HelmetMetaData
                                    title={user === null ? "User account" : user.attributes['custom:firstname'] + "'s Account"}
                                    description="InnovFin user's account details."
                                    keywords={keywords}>
                                </HelmetMetaData>
                                <Row>
                                    <Col xs={12} lg={6}>
                                        {user === null ? <p>Loading...</p> : <p>First Name: {user.attributes['custom:firstname']}</p>}
                                        {user === null ? <p>Loading...</p> : <p>Last Name: {user.attributes['custom:lastname']}</p>}

                                    </Col>
                                    <Col xs={12} lg={6}>
                                        {user === null ? <p>Loading...</p> : <p>Username: {user.username}</p>}
                                        {user === null ? <p>Loading...</p> : <p>Email: {user.attributes['email']}</p>}
                                    </Col>
                                </Row>
                                <Row>
                                    <Button onClick={() => Auth.signOut()}
                                        className="button">Sign out</Button>
                                </Row>
                            </Container>
                        </div>
                    </Container>


                )
            }
            {
                formType === 'forgotPass' && (
                    <div>
                        <HelmetMetaData
                            title="Reset password"
                            description="Reset your password with an email confirmation code"
                            keywords={keywords}>
                        </HelmetMetaData>
                        <ForgotPassword lang={lang} />
                    </div>
                )
            }
            {
                formType === 'forgotPassConf' && (
                    <div>
                        <HelmetMetaData
                            title="Reset password confirmation"
                            description="Reset your password with an email confirmation code"
                            keywords={keywords}>
                        </HelmetMetaData>
                        <ForgotPasswordConfirm lang={lang} />
                    </div>
                )
            }
            {/* The following is used to show any popup at this location, there may not be any popups to show */}
            <PopupComponent lang={lang} location="Account" />
        </div>
    )
}

export default Account;