import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listTerms } from "../graphql/queries";
import parse from "html-react-parser";
import { Container, Spinner } from "react-bootstrap";

function PrivatePolicy({ lang }) {
    const [policyData, setPolicyData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const setContentAPI = () => {
        //calls the api to qurey the data using the given query
        API.graphql(graphqlOperation(listTerms))
            .then((data) => {
                //stores list of data in a variable to be manipulated later
                const modifiedData2 = data["data"]["listTerms"]["items"];
                //following map function to parse the content field into a usable form
                modifiedData2.map((item) => {
                    item.content = parse(item.content);
                    item.contentFr = parse(item.contentFr);
                    return item;
                });
                //set the data grabbed from the api and set the variable used later
                setPolicyData(modifiedData2);
                //lets the app know that the data has loaded and can be rendered
                setIsLoaded(true);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        setContentAPI();
    }, []);
    return (
        <div className="TermAndPolicy">
            {!isLoaded ?
                <Container>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </Container>
                :
                lang === "fr" ?
                    (<Container>
                        <h1>{policyData.find(find => find.id === "56ad8b3b-8fcc-4422-8521-9e6148ada933").titleFr}</h1>
                        {policyData.find(find => find.id === "56ad8b3b-8fcc-4422-8521-9e6148ada933").contentFr}
                    </Container>)
                    :
                    (<Container>
                        <h1>{policyData.find(find => find.id === "56ad8b3b-8fcc-4422-8521-9e6148ada933").title}</h1>
                        {policyData.find(find => find.id === "56ad8b3b-8fcc-4422-8521-9e6148ada933").content}
                    </Container>)

            }
        </div>
    )
}

export default PrivatePolicy;