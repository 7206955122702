import React, { useState, useEffect } from "react";
// import axios from "axios";
import { Col, Container, Row, Spinner, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { getPost, listPosts, listAuthors, listIconImages } from "./graphql/queries";
import "./App.css";
import "./styling/BlogPost.css";
import HelmetMetaData from "./components/HelmetMetaData";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import parse from "html-react-parser";
import MailchimpForm from "./mailchimp-form";
import ReactDomServer from "react-dom/server"
import PopupComponent from "./components/PopupComponent";


function Blog() {
  //gets the slug from the url
  let { lang, slug } = useParams();
  //variables to store the post's id when retrieved using slug
  const [postId, setPostId] = useState();
  //for checking if id is loaded
  const [idLoaded, setIdLoaded] = useState(false);
  const [nextToken, setNextToken] = useState(null);

  //function to grab the id using the slug
  const getId = (token) => {
    API.graphql(graphqlOperation(listPosts, { nextToken: token }))
      .then((data) => {
        const modifiedData = data["data"]["listPosts"]["items"];
        setNextToken(data["data"]["listPosts"]["nextToken"]);
        //find the post from modified data with a slug value which is the same as the slug from the url
        //encodeURIComponent is to make sure the old french slugs with values such as %C2%A0 are encoded to match the slug in the url
        setPostId(modifiedData.find(find => find.slug === encodeURIComponent(slug)).id);
        setIdLoaded(true);
      }).catch((err) => {
        // console.log(err + " in getID");
      });
  }

  const [postItem, setPostItem] = useState([]);
  const [postItemSources, setPostItemSources] = useState([]);
  const [postItemCategory, setPostItemCategories] = useState();
  const [authors, setAuthors] = useState([]);
  const [authorLoaded, setAuthorLoaded] = useState(false);
  const [Posts, setPosts] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoaded2, setIsLoaded2] = useState(false);

  const getAllPosts = async (token) => {
    // Advanced post filtering, grabs posts that are scheduled to be published or post that have do not have a schedule publish date.
    // Also gets posts that have a post status of PUBLISH (true)
    let filter = {
      and: [
        {
          or: [
            { publish_at: { lt: new Date().toISOString() } },
            { publish_at: { eq: "" } },
          ],
        },
        { status: { eq: true } },
      ],
    };
    API.graphql(graphqlOperation(listPosts, { filter: filter, nextToken: token }))
      .then((data) => {
        let modifiedData = data["data"]["listPosts"]["items"];

        if (lang === "en" || lang === null) {
          modifiedData = modifiedData.filter((post) => post.lang.includes("english"))
        } else {
          modifiedData = modifiedData.filter((post) => post.lang.includes("french"))
        }

        modifiedData.map((item) => {
          item.content = parse(item.content);
          item.date = new Date(item.publish_at);
          //probably a more efficient way to do this but I couldn't figure it out
          switch (item.date.getMonth() + 1) {
            case 1:
              item.date = dateSuffix(item.date);
              item.date = fullDayName(item.date);
              item.date = item.date.replace("Jan", "January");
              break;
            case 2:
              item.date = dateSuffix(item.date);
              item.date = fullDayName(item.date);
              item.date = item.date.replace("Feb", "February");
              break;
            case 3:
              item.date = dateSuffix(item.date);
              item.date = fullDayName(item.date);
              item.date = item.date.replace("Mar", "March");
              break;
            case 4:
              item.date = dateSuffix(item.date);
              item.date = fullDayName(item.date);
              item.date = item.date.replace("Apr", "April");
              break;
            case 5:
              item.date = dateSuffix(item.date);
              item.date = fullDayName(item.date);
              item.date = item.date.replace("May", "May");
              break;
            case 6:
              item.date = dateSuffix(item.date);
              item.date = fullDayName(item.date);
              item.date = item.date.replace("June", "June");
              break;
            case 7:
              item.date = dateSuffix(item.date);
              item.date = fullDayName(item.date);
              item.date = item.date.replace("July", "July");
              break;
            case 8:
              item.date = dateSuffix(item.date);
              item.date = fullDayName(item.date);
              item.date = item.date.replace("Aug", "August");
              break;
            case 9:
              item.date = dateSuffix(item.date);
              item.date = fullDayName(item.date);
              item.date = item.date.replace("Sep", "September");
              break;
            case 10:
              item.date = dateSuffix(item.date);
              item.date = fullDayName(item.date);
              item.date = item.date.replace("Oct", "October");
              break;
            case 11:
              item.date = dateSuffix(item.date);
              item.date = fullDayName(item.date);
              item.date = item.date.replace("Nov", "November");
              break;
            case 12:
              item.date = dateSuffix(item.date);
              item.date = fullDayName(item.date);
              item.date = item.date.replace("Dec", "December");
              break;
            default:
              break;
          };
          item.categories.forEach(cat => {
            categoriesList.push(cat)
          })
          return item;
        });

        //Put data into Posts variable
        modifiedData.forEach(data => {
          Posts.push(data);
        })

        //sorts posts by date created
        Posts.sort(function (a, b) {
          return (
            //format date into a number and check which post should go first
            b.publish_at.replace(/-/g, "").replaceAll(":", "").replaceAll("T", "").substr(0, 15) -
            a.publish_at.replace(/-/g, "").replaceAll(":", "").replaceAll("T", "").substr(0, 15)
          );
        });
        if (data["data"]["listPosts"]["nextToken"] !== null) {
          getAllPosts(data["data"]["listPosts"]["nextToken"]);
        }
        setCategoriesList([...new Set(categoriesList)])
        setIsLoaded2(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getPosts = async () => {
    const items = API.graphql(graphqlOperation(getPost, { id: postId }));
    items
      .then((data) => {
        const modifiedData = data["data"]["getPost"];
        //checks if a header tag is followed by "sources", if so, seperate the sources from the main content
        if (modifiedData.content.substr(modifiedData.content.indexOf("<h"), modifiedData.content.indexOf("<h") + 30).includes("Sources")) {
          setPostItemSources(parse(modifiedData.content.substr(modifiedData.content.indexOf("<h"))))
          modifiedData.content = parse(modifiedData.content.substr(0, modifiedData.content.indexOf("<h")));
        } else {
          modifiedData.content = parse(modifiedData.content);
        }
        setPostItemCategories(modifiedData.categories.join(","));
        setPostItem(modifiedData);
        // if (modifiedData.lang !== "undefined"){
        //   // console.log(modifiedData.lang)
        //   checkLang(postItem);
        // }
        // checkLang(modifiedData.lang);
        checkLang(modifiedData);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });

    //get the authors
    await API.graphql(graphqlOperation(listAuthors))
      .then((data) => {
        const modifiedData = data["data"]["listAuthors"]["items"];
        setAuthors(modifiedData);
        setAuthorLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function dateSuffix(date) {
    switch (date.getDate()) {
      case 1:
      case 21:
      case 31:
        return date.toString().substr(0, 10).replace(/0\d/g, date.getDate()) + "st" + date.toString().substr(10, date.toString().length - 5)
      case 2:
      case 22:
        return date.toString().substr(0, 10).replace(/0\d/g, date.getDate()) + "nd" + date.toString().substr(10, date.toString().length - 5)
      case 3:
      case 23:
        return date.toString().substr(0, 10).replace(/0\d/g, date.getDate()) + "rd" + date.toString().substr(10, date.toString().length - 5)
      default:
        return date.toString().substr(0, 10).replace(/0\d/g, date.getDate()) + "th" + date.toString().substr(10, date.toString().length - 5)
    }
  }

  function fullDayName(date) {
    switch (date.substr(0, 3)) {
      case "Sun":
        return date.replace("Sun", "Sunday");
      case "Mon":
        return date.replace("Mon", "Monday");
      case "Tue":
        return date.replace("Tue", "Tuesday");
      case "Wed":
        return date.replace("Wed", "Wednesday");
      case "Thu":
        return date.replace("Thu", "Thursday");
      case "Fri":
        return date.replace("Fri", "Friday");
      case "Sat":
        return date.replace("Sat", "Saturday");
      default:
        return date
    }
  }

  //for grabbing the icons
  const [icon, setIcon] = useState([]);
  const [isIconLoaded, setIsIconLoaded] = useState(false);

  //sets up the api to grab data from the database, dynamoDB in this case
  const setIconAPI = () => {
    //calls the api to qurey the data using the given query
    API.graphql(graphqlOperation(listIconImages))
      .then((data) => {
        //stores list of data in a variable to be manipulated later
        const modifiedData2 = data["data"]["listIconImages"]["items"];
        //set the data grabbed from the api and set the variable used later
        setIcon(modifiedData2);
        //lets the app know that the data has loaded and can be rendered
        setIsIconLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const checkLang = (post) => {
    if (window.location.pathname.split("/")[1].includes("en") && post.lang === "french") {
      window.location.href = `/en/Blog/f/${post.postLink}`
      // window.location.reload();
    } else if (window.location.pathname.split("/")[1].includes("fr") && post.lang === "english") {
      window.location.href = `/fr/Blog/f/${post.postLink}`
      // window.location.reload();
    }
  }

  useEffect(() => {
    getAllPosts(nextToken);
    getId(nextToken);
    setIconAPI();
  }, []);

  useEffect(() => {
    getPosts();
  }, [idLoaded]);

  useEffect(() => {
    if (postId === undefined) {
      getId(nextToken);
    }
  }, [nextToken]);

  function printCat(cat) {
    var newArr = [];
    for (let i = 0; i < cat.length; i++) {
      newArr.push(" #" + cat[i].replace(" ", ""));
    }
    return newArr;
  }

  return (
    <Container className="blogContainer">
      {/* <HelmetMetaData></HelmetMetaData> */}
      <div className="blogPostContainer">
        <h1>{lang === "fr" ? "Article de blogue" : "Blog post"}</h1>
        <p id="header-txt">
          {lang === "fr"
            ? "Nous publions des blogs hebdomadaires pour vous aider à vous tenir au courant de l'actualité technologique émergente et perturbatrice. Vous trouverez des articles sur la blockchain au quotidien."
            : "We post weekly blogs to help you keep updated with emerging and disruptive technological news. Everyday Blockchain reads."}
        </p>
        <Container style={{ padding: "0" }} className="containerWithBorder">
          <div>
            <Row style={{ margin: "0" }}>
              {!isLoaded ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                <Col className="blog-content" md={12} lg={9}>
                  <HelmetMetaData
                    title={"InnovFin Blog Post: " + postItem.title}
                    description={postItem.content}
                    keywords={postItemCategory}
                    image={postItem.image}
                  ></HelmetMetaData>
                  <Container>
                    {!isLoaded2 ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      Posts.map((post, idx) => {
                        if (Posts.length > 1) {
                          if (postItem.id === post.id && idx === 0) {
                            return (
                              <Row key={idx}>
                                <Col xs={6} lg={6}>
                                  <div className="prev-btn">
                                    <a href={`/${lang}/Blog/f/` + Posts[idx + 1].slug}>
                                      <Row className="align-items-center">
                                        <Col lg="auto">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-arrow-left-short align-item-center" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                                          </svg>
                                        </Col>
                                        <Col lg="auto">
                                          {postItem.lang === "french" ? (
                                            <p><strong>Article précédent</strong></p>
                                          ) : (
                                            <p><strong>Previous Post</strong></p>
                                          )}
                                        </Col>
                                      </Row>
                                    </a>
                                  </div>
                                </Col>
                                <Col xs={6} lg={6}>
                                  <p style={{ textAlign: "right" }}></p>
                                </Col>
                              </Row>
                            )
                          } else if (postItem.id === post.id && idx === Posts.length - 1) {
                            return (
                              <Row key={idx}>
                                <Col xs={6} lg={6}>
                                  <p></p>
                                </Col>
                                <Col xs={6} lg={6}>
                                  <div className="next-btn">
                                    <a href={`/${lang}/Blog/f/` + Posts[idx - 1].slug}>
                                      <Row className="align-items-center">
                                        <Col lg="auto">
                                          {postItem.lang === "french" ? (
                                            <p><strong>Article suivant</strong></p>
                                          ) : (
                                            <p><strong>Next Post</strong></p>
                                          )}
                                        </Col>
                                        <Col lg="auto">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                          </svg>
                                        </Col>
                                      </Row>
                                    </a>
                                  </div>
                                </Col>
                              </Row>
                            )
                          } else if (postItem.id === post.id) {
                            return (
                              <Row className="align-items-center" key={idx}>
                                <Col xs={6} lg={6}>
                                  <div className="prev-btn">
                                    <a href={`/${lang}/Blog/f/` + Posts[idx + 1].slug}>
                                      <Row className="align-items-center">
                                        <Col lg="auto">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-arrow-left-short align-item-center" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                                          </svg>
                                        </Col>
                                        <Col lg="auto">
                                          {postItem.lang === "french" ? (
                                            <p><strong>Article précédent</strong></p>
                                          ) : (
                                            <p><strong>Previous Post</strong></p>
                                          )}
                                        </Col>
                                      </Row>
                                    </a>
                                  </div>
                                </Col>
                                <Col xs={6} lg={6}>
                                  <div className="next-btn">
                                    <a href={`/${lang}/Blog/f/` + Posts[idx - 1].slug}>
                                      <Row className="align-items-center">
                                        <Col lg="auto">
                                          {postItem.lang === "french" ? (
                                            <p><strong>Article suivant</strong></p>
                                          ) : (
                                            <p><strong>Next Post</strong></p>
                                          )}
                                        </Col>
                                        <Col lg="auto">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                          </svg>
                                        </Col>
                                      </Row>
                                    </a>
                                  </div>
                                </Col>
                              </Row>
                            )
                          }
                        }
                      })
                    )
                    }
                  </Container>
                  <Col lg={12}>
                    {postItem.video === null ? (
                      <img
                        onContextMenu={(e) => e.preventDefault()}
                        className="img-center-size"
                        src={postItem.image}
                        alt=""
                      />
                    ) : (
                      <iframe
                        title="Video"
                        width="100%"
                        height="450px"
                        src={postItem.video.replace("watch?v=", "embed/") + "?rel=0"}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen />
                    )}
                  </Col>
                  <Col lg={12} className="blogPostContent">
                    <h1 className="blogPost-title">{postItem.title}</h1>
                    <p
                      className="featuredPostLead"
                    >
                      <span>{postItem.createdAt.slice(0, 10)}</span>
                      <span style={{ display: "wrap" }}>
                        {lang === "fr" ? "Catégories" : "Categories"} {printCat(postItem.categories)}
                      </span>
                    </p>
                    <div
                      className="blogPost-Description"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {postItem.content}
                      {!authorLoaded ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        authors.some(item => item.author === postItem.author) ? (
                          <div>
                            <p style={{ margin: "0" }}>
                              {postItem.lang === "french" ? (
                                <strong>
                                  Partager cet article de blogue
                                </strong>
                              ) : (
                                <strong>
                                  Share this Blog Post
                                </strong>
                              )}
                              &nbsp; &nbsp; &nbsp;
                              <FacebookShareButton
                                url={window.location.href}
                                quote={"InnovFin - Blog - " + postItem.title}
                              >
                                <FacebookIcon size={36} />
                              </FacebookShareButton>
                              &nbsp; &nbsp; &nbsp;
                              <TwitterShareButton
                                url={window.location.href}
                              >
                                <TwitterIcon size={36} />
                              </TwitterShareButton>
                              &nbsp; &nbsp; &nbsp;
                              <LinkedinShareButton
                                url={window.location.href}
                                title={
                                  "Check out this InnovFin Blog post - " + postItem.title
                                }
                                summary={postItem.content}
                                source={"InnovFin Consulting Blog"}
                              >
                                <LinkedinIcon size={36} />
                              </LinkedinShareButton>
                              &nbsp; &nbsp; &nbsp;
                              <EmailShareButton
                                url={window.location.href}
                                subject={
                                  "Check out this blog by InnovFin! - " + postItem.title
                                }
                                body={
                                  "Check out this blog post I found on InnovFin's blog page:\n " +
                                  ReactDomServer.renderToStaticMarkup(postItem.content[0]).replace(/<[^>]+>/g, '') + " " +
                                  ReactDomServer.renderToStaticMarkup(postItem.content[1]).replace(/<[^>]+>/g, '') +
                                  "... follow the link to read!"
                                }
                                separator={"\n\nFollow this link for more information: "}
                              >
                                <EmailIcon size={36} />
                              </EmailShareButton>
                              {postItem.postLink !== null ? (
                                lang === "fr" ? (
                                  <Button
                                    className="button"
                                    id="author-button"
                                    onClick={() => {
                                      localStorage.setItem("lang", "en");
                                      window.location.href = `/en/Blog/f/${postItem.postLink}`;
                                    }}
                                  >
                                    Lire en anglais!
                                  </Button>
                                ) : (
                                  <Button
                                    className="button"
                                    id="author-button"
                                    onClick={() => {
                                      localStorage.setItem("lang", "fr");
                                      window.location.href = `/fr/Blog/f/${postItem.postLink}`;
                                    }}
                                  >
                                    Read in French!
                                  </Button>)
                              ) : (
                                <>
                                </>
                              )}
                            </p>
                            <Row className="align-items-center author-row">
                              <Col lg={3}>
                                <img
                                  onContextMenu={(e) => e.preventDefault()}
                                  className="img-center-size card-img-dropshadow"
                                  id="author-img"
                                  alt="Author"
                                  src={authors.find(find => find.author === postItem.author).image} />

                              </Col>
                              <Col className="author-text" lg={9}>
                                <h1 style={{ padding: "0" }}>
                                  {postItem.lang === "french" ? (
                                    <strong>
                                      Auteur: {authors.find(find => find.author === postItem.author).author}
                                    </strong>
                                  ) : (
                                    <strong>
                                      Author: {authors.find(find => find.author === postItem.author).author}
                                    </strong>
                                  )}
                                </h1>
                                <h2>{authors.find(find => find.author === postItem.author).description}</h2>

                                <div>
                                  {postItem.lang === "french" ? (
                                    "Suivez-nous sur nos réseaux sociaux"
                                  ) : (
                                    "Follow us on social media"
                                  )}
                                  &nbsp;
                                  {!isIconLoaded ? (
                                    <div>
                                      <a href='https://www.linkedin.com/company/innovfin'><img width="25px" src='../assets/Social Icons/LinkedIn.png' alt='Innovfin LinkedIn' /></a>
                                      &nbsp;
                                      <a href='https://www.youtube.com/channel/UCY7zGRalYuGaAykCFXIyObw'><img width="25px" src='../assets/Social Icons/YouTube.png' alt='Innovfin Youtube' /></a>
                                      &nbsp;
                                      <a href='https://www.facebook.com/InnovFinConsulting/'><img width="25px" src='../assets/Social Icons/Facebook.png' alt='Innovfin Facebook' /></a>
                                      &nbsp;
                                      <a href='https://twitter.com/InnovFinI'><img width="25px" src='../assets/Social Icons/Twitter.png' alt='Innovfin Twitter' /></a>
                                      &nbsp;
                                      <a href='https://join.slack.com/t/innovfinedtech/shared_invite/zt-u70pg874-JXRdvazWBrNWikoo5k1t4Q'><img width="25px" src='../assets/Social Icons/Slack.png' alt='Innovfin Slack' /></a>
                                      &nbsp;
                                      <a href='https://innovfinconsultingsspace.quora.com/'><img width="25px" src='../assets/Social Icons/Quora.png' alt='Innovfin Quora' /></a>
                                      {/* disabled reddit for now
                                  &nbsp;
                                  <a href='https://www.reddit.com/r/InnovFin/ '><img width="25px" src='../assets/Social Icons/Reddit.png' alt='Innovfin Reddit' /></a> */}

                                    </div>
                                  ) : (
                                    <div>
                                      {icon.map((icon, idx) => {
                                        return (
                                          icon.title === "user" ? ""
                                            : <a id={idx} href={icon.link}>
                                              <img width="30px" src={icon.image} alt={icon.title} />
                                              &nbsp;
                                            </a>
                                        )
                                      })}
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>

                            {postItem.lang === "french" ? (
                              <p>
                                <strong>
                                  Inscrivez-vous pour recevoir les mises à jour du blogue !
                                </strong>
                                <br />
                                Inscrivez-vous sur notre liste de diffusion pour recevoir des mises à jour et des informations.
                              </p>
                            ) : (
                              <p>
                                <strong>
                                  Sign up for blog updates!
                                </strong>
                                <br />
                                Join our email list to receive updates and information.
                              </p>
                            )}

                            <MailchimpForm lang={lang} />
                          </div>
                        ) : (
                          <div>
                            <p style={{ margin: "0" }}>
                              {postItem.lang === "french" ? (
                                <strong>
                                  Partager cet article de blogue
                                </strong>
                              ) : (
                                <strong>
                                  Share this Blog Post
                                </strong>
                              )}
                              &nbsp;&nbsp;&nbsp;
                              <FacebookShareButton
                                url={window.location.href}
                                quote={"InnovFin - Blog - " + postItem.title}
                              >
                                <FacebookIcon size={36} />
                              </FacebookShareButton>
                              &nbsp;&nbsp;&nbsp;
                              <TwitterShareButton
                                url={window.location.href}
                              >
                                <TwitterIcon size={36} />
                              </TwitterShareButton>
                              &nbsp;&nbsp;&nbsp;
                              <LinkedinShareButton
                                url={window.location.href}
                                title={
                                  "Check out this InnovFin Blog post - " + postItem.title
                                }
                                summary={postItem.content}
                                source={"InnovFin Consulting Blog"}
                              >
                                <LinkedinIcon size={36} />
                              </LinkedinShareButton>
                              &nbsp;&nbsp;&nbsp;
                              <EmailShareButton
                                url={window.location.href}
                                subject={
                                  "Check out this blog by InnovFin! - " + postItem.title
                                }
                                body={
                                  "Check out this blog post I found on InnovFin's blog page:\n " +
                                  ReactDomServer.renderToStaticMarkup(postItem.content[0]).replace(/<[^>]+>/g, '') + " " +
                                  ReactDomServer.renderToStaticMarkup(postItem.content[1]).replace(/<[^>]+>/g, '') +
                                  "... follow the link to read!"
                                }
                                separator={"\n\nFollow this link for more information: "}
                              >
                                <EmailIcon size={36} />
                              </EmailShareButton>
                            </p>
                            <Row className="align-items-center author-row">
                              <Col lg={3}>
                                <img
                                  onContextMenu={(e) => e.preventDefault()}
                                  className="img-center-size card-img-dropshadow"
                                  alt="Author"
                                  src="../assets/Strategy Services Assets/BusinessPlanWriting.png" />

                              </Col>
                              <Col className="author-text" lg={9}>
                                <h1>
                                  {postItem.lang === "french" ? (
                                    <strong>
                                      Auteur: InnovFin
                                    </strong>
                                  ) : (
                                    <strong>
                                      Author: InnovFin
                                    </strong>
                                  )}
                                </h1>

                                <div>
                                  {postItem.lang === "french" ? (
                                    "Suivez-nous sur nos réseaux sociaux"
                                  ) : (
                                    "Follow us on social media"
                                  )}
                                  {!isIconLoaded ? (
                                    <div>
                                      <a href='https://www.linkedin.com/company/innovfin'><img width="25px" src='../assets/Social Icons/LinkedIn.png' alt='Innovfin LinkedIn' /></a>
                                      &nbsp;
                                      <a href='https://www.youtube.com/channel/UCY7zGRalYuGaAykCFXIyObw'><img width="25px" src='../assets/Social Icons/YouTube.png' alt='Innovfin Youtube' /></a>
                                      &nbsp;
                                      <a href='https://www.facebook.com/InnovFinConsulting/'><img width="25px" src='../assets/Social Icons/Facebook.png' alt='Innovfin Facebook' /></a>
                                      &nbsp;
                                      <a href='https://twitter.com/InnovFinI'><img width="25px" src='../assets/Social Icons/Twitter.png' alt='Innovfin Twitter' /></a>
                                      &nbsp;
                                      <a href='https://join.slack.com/t/innovfinedtech/shared_invite/zt-u70pg874-JXRdvazWBrNWikoo5k1t4Q'><img width="25px" src='../assets/Social Icons/Slack.png' alt='Innovfin Slack' /></a>
                                      &nbsp;
                                      <a href='https://innovfinconsultingsspace.quora.com/'><img width="25px" src='../assets/Social Icons/Quora.png' alt='Innovfin Quora' /></a>
                                      {/* disabled reddit for now
                                  &nbsp;
                                  <a href='https://www.reddit.com/r/InnovFin/ '><img width="25px" src='../assets/Social Icons/Reddit.png' alt='Innovfin Reddit' /></a> */}

                                    </div>
                                  ) : (
                                    <div>
                                      {icon.map((icon, idx) => {
                                        return (
                                          icon.title === "user" ? ""
                                            : <a id={idx} href={icon.link}>
                                              <img width="30px" src={icon.image} alt={icon.title} />
                                              &nbsp;
                                            </a>
                                        )
                                      })}
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            {postItem.lang === "french" ? (
                              <p>
                                <strong>
                                  Inscrivez-vous pour recevoir les mises à jour du blogue !
                                </strong>
                                <br />
                                Inscrivez-vous sur notre liste de diffusion pour recevoir des mises à jour et des informations.
                              </p>
                            ) : (
                              <p>
                                <strong>
                                  Sign up for blog updates!
                                </strong>
                                <br />
                                Join our email list to receive updates and information.
                              </p>
                            )}
                            <MailchimpForm lang={lang} />
                          </div>
                        )
                      )}
                      <hr />
                      {postItemSources}
                    </div>
                    <br />
                    {!isLoaded2 ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      Posts.map((post, idx) => {
                        if (Posts.length > 1) {
                          if (postItem.id === post.id && idx === 0) {
                            return (
                              <Row key={idx}>
                                <Col xs={6} lg={6}>
                                  <div className="prev-btn">
                                    <a href={`/${lang}/Blog/f/` + Posts[idx + 1].slug}>
                                      <Row className="align-items-center">
                                        <Col lg="auto">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-arrow-left-short align-item-center" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                                          </svg>
                                        </Col>
                                        <Col lg="auto">
                                          {postItem.lang === "french" ? (
                                            <p><strong>Article précédent</strong></p>
                                          ) : (
                                            <p><strong>Previous Post</strong></p>
                                          )}
                                        </Col>
                                      </Row>
                                    </a>
                                  </div>
                                </Col>
                                <Col xs={6} lg={6}>
                                  <p style={{ textAlign: "right" }}></p>
                                </Col>
                              </Row>
                            )
                          } else if (postItem.id === post.id && idx === Posts.length - 1) {
                            return (
                              <Row key={idx}>
                                <Col xs={6} lg={6}>
                                  <p></p>
                                </Col>
                                <Col xs={6} lg={6}>
                                  <div className="next-btn">
                                    <a href={`/${lang}/Blog/f/` + Posts[idx - 1].slug}>
                                      <Row className="align-items-center">
                                        <Col lg="auto">
                                          {postItem.lang === "french" ? (
                                            <p><strong>Article suivant</strong></p>
                                          ) : (
                                            <p><strong>Next Post</strong></p>
                                          )}
                                        </Col>
                                        <Col lg="auto">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                          </svg>
                                        </Col>
                                      </Row>
                                    </a>
                                  </div>
                                </Col>
                              </Row>
                            )
                          } else if (postItem.id === post.id) {
                            return (
                              <Row className="align-items-center" key={idx}>
                                <Col xs={6} lg={6}>
                                  <div className="prev-btn">
                                    <a href={`/${lang}/Blog/f/` + Posts[idx + 1].slug}>
                                      <Row className="align-items-center">
                                        <Col lg="auto">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-arrow-left-short align-item-center" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                                          </svg>
                                        </Col>
                                        <Col lg="auto">
                                          {postItem.lang === "french" ? (
                                            <p><strong>Article précédent</strong></p>
                                          ) : (
                                            <p><strong>Previous Post</strong></p>
                                          )}
                                        </Col>
                                      </Row>
                                    </a>
                                  </div>
                                </Col>
                                <Col xs={6} lg={6}>
                                  <div className="next-btn">
                                    <a href={`/${lang}/Blog/f/` + Posts[idx - 1].slug}>
                                      <Row className="align-items-center">
                                        <Col lg="auto">
                                          {postItem.lang === "french" ? (
                                            <p><strong>Article suivant</strong></p>
                                          ) : (
                                            <p><strong>Next Post</strong></p>
                                          )}
                                        </Col>
                                        <Col lg="auto">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                          </svg>
                                        </Col>
                                      </Row>
                                    </a>
                                  </div>
                                </Col>
                              </Row>
                            )
                          }
                        }
                      }))
                    }
                  </Col>
                </Col>
              )}
              {!isLoaded2 ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                <Col md={12} lg={3} className="postSidebar">
                  <div style={{ padding: "0em 1em 2em 1em" }}>
                    <h1 className="postSidebarTitle">
                      {lang === "fr"
                        ? "Partager cet article de blogue"
                        : "Share this Blog Post"}
                    </h1>
                    {!isLoaded ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      <Row style={{ padding: "1em 0em" }} lg={4}>
                        <Col id="share-button">
                          <FacebookShareButton
                            url={window.location.href}
                            quote={"InnovFin - Blog - " + postItem.title}
                          >
                            <FacebookIcon size={36} />
                          </FacebookShareButton>
                        </Col>
                        <Col id="share-button">
                          <TwitterShareButton
                            url={window.location.href}
                          >
                            <TwitterIcon size={36} />
                          </TwitterShareButton>
                        </Col>
                        <Col id="share-button">
                          <LinkedinShareButton
                            url={window.location.href}
                            title={
                              "Check out this InnovFin Blog post - " + postItem.title
                            }
                            summary={postItem.content}
                            source={"InnovFin Consulting Blog"}
                          >
                            <LinkedinIcon size={36} />
                          </LinkedinShareButton>
                        </Col>
                        <Col id="share-button">
                          <EmailShareButton
                            url={window.location.href}
                            subject={
                              "Check out this blog by InnovFin! - " + postItem.title
                            }
                            body={
                              "Check out this blog post I found on InnovFin's blog page:\n " +
                              ReactDomServer.renderToStaticMarkup(postItem.content[0]).replace(/<[^>]+>/g, '') + " " +
                              ReactDomServer.renderToStaticMarkup(postItem.content[1]).replace(/<[^>]+>/g, '') +
                              "... follow the link to read!"
                            }
                            separator={"\n\nFollow this link for more information: "}
                          >
                            <EmailIcon size={36} />
                          </EmailShareButton>
                        </Col>
                      </Row>
                    )}
                  </div>
                  {/* Social images go here */}
                  <div style={{ padding: "0em 1em 2em 1em" }}>
                    <p className="postSidebarTitle">
                      {lang === "fr"
                        ? "Recevez des notifications !"
                        : "Receive Notifications!"}
                    </p>
                    <p className="postSidebarLead">
                      {lang === "fr"
                        ? "Inscrivez-vous pour être informé des nouveaux blogs sur les applications révolutionnaires de la blockchain!"
                        : "Subscribe to be notified of new blogs on revolutionary blockchain applications!"}
                    </p>

                    <MailchimpForm lang={lang} />

                  </div>
                  {postItem.postLink !== null ? (
                    <div style={{ padding: "0em 1em 2em 1em" }}>
                      {postItem.lang === "french"
                        ?
                        <div>
                          <p className="postSidebarTitle">
                            Read in English!
                          </p>
                          <Button
                            className="button"
                            id="button-side"
                            onClick={() => {
                              localStorage.setItem("lang", "en");
                              window.location.href = `/en/Blog/f/${postItem.postLink}`;
                            }}
                          >
                            Read in English!
                          </Button>
                        </div>
                        : <div>
                          <p className="postSidebarTitle">
                            Lisez en français!
                          </p>
                          <Button
                            className="button"
                            id="button-side"
                            onClick={() => {
                              localStorage.setItem("lang", "fr");
                              window.location.href = `/fr/Blog/f/${postItem.postLink}`;
                            }}
                          >
                            Lisez en français!
                          </Button>
                        </div>
                      }
                    </div>
                  ) : (
                    <></>
                  )}
                  <div style={{ padding: "0em 1em 1em 1em" }}>
                    <p className="postSidebarTitle">{lang === "fr" ? "Catégories" : "Categories"} </p>
                    <ul className="categoryList">
                      {categoriesList.map((text, _idx) => {
                        return (
                          <li className="categoryListItem" key={_idx}>
                            <a href={`/Blog?category=${text}`}>{text}</a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  <div style={{ padding: "0em 1em 1em 1em" }}>
                    <p className="postSidebarTitle">
                      {lang === "fr" ? (
                        "Posts récents"
                      ) : (
                        "Recent Posts"
                      )}
                    </p>
                    {Posts.slice(0, 3).map((post, index) => {
                      return (
                        <div key={index}>
                          <a className="recentPostLink" href={`/${lang}/Blog/f/${post.slug}`}>
                            <img
                              onContextMenu={(e) => e.preventDefault()}
                              className="featuredPostImg-sideBar"
                              alt={"innov blog " + index}
                              src={post.image} />
                            <p className="recentPostTitle">{post.title}</p>
                          </a>
                          <p className="sidebar-category">{post.date.substr(0, post.date.length - 43)} | {printCat(post.categories)}</p>
                        </div>
                      )
                    })}
                  </div>
                </Col>
              )}
            </Row>
          </div >
        </Container >
      </div >
      {/* The following is used to show any popup at this location, there may not be any popups to show */}
      <PopupComponent lang={lang} location="Blog post" />
    </Container >
  );
}

export default Blog;
