import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Card,
  Spinner,
  CardGroup,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { listPosts } from "./graphql/queries";
// import categories from "./json_files/categories.json";
import "./App.css";
import "./styling/Blog.css";
import metaData from "./json_files/metadata.json";
import HelmetMetaData from "./components/HelmetMetaData";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import PopupComponent from "./components/PopupComponent";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function printCat(cat) {
  var newArr = [];
  for (let i = 0; i < cat.length; i++) {
    newArr.push(" #" + cat[i].replace(" ", ""));
  }
  return newArr;
}

function Blog() {
  const { lang } = useParams();
  let query = useQuery();
  const [Posts, setPosts] = useState([]);
  const [featuredPost, setFeaturedPost] = useState();
  const [featuredLoaded, setFeaturedLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [category, setCategory] = useState(query.get("category"));
  const [categoriesList, setCategoriesList] = useState([]);

  // For the meta data stuff
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState("");
  // Get location metadata from JSON file, this system maybe used in the future so that
  // non-developers can edit metadata.
  const getMeta = (data) => {
    if (data.url === window.location.pathname.split("/")[2]) {
      setTitle(data.title);
      setDescription(data.description);
      setKeywords(data.keywords);
    }
  };
  // Advanced post filtering, grabs posts that are scheduled to be published or post that have do not have a schedule publish date.
  // Also gets posts that have a post status of PUBLISH (true)
  let filter = {
    and: [
      {
        or: [
          { publish_at: { lt: new Date().toISOString() } },
          { publish_at: { eq: "" } },
        ],
      },
      { status: { eq: true } },
    ],
  };

  const getAllPosts = async (token) => {
    API.graphql(graphqlOperation(listPosts, { filter: filter, limit: 1000, nextToken: token }))
      .then((data) => {
        // console.log(data);
        let modifiedData = data["data"]["listPosts"]["items"];
        // console.log(data["data"]["listPosts"]["nextToken"]);

        if (lang === "en" || lang === null || lang === "null") {
          modifiedData = modifiedData.filter((post) =>
            post.lang.includes("english")
          );
        } else {
          modifiedData = modifiedData.filter((post) =>
            post.lang.includes("french")
          );
        }

        modifiedData.map((item) => {
          item.content = parse(item.content);
          item.categories.forEach((cat) => {
            categoriesList.push(cat);
          });
          return item;
        });

        if (category) {
          // setPosts(
          //   modifiedData.filter((post) => post.categories.includes(category))
          // );
          modifiedData.filter((post) => post.categories.includes(category)).forEach(data => {
            Posts.push(data)
          })
        } else {
          // setPosts(modifiedData);
          // console.log(Posts)
          modifiedData.forEach(data => {
            Posts.push(data);
          })
        }
        //sort the posts after adding posts
        Posts.sort(function (a, b) {
          return (
            //format date into a number and check which post should go first
            b.publish_at.replace(/-/g, "").replaceAll(":", "").replaceAll("T", "").substr(0, 15) -
            a.publish_at.replace(/-/g, "").replaceAll(":", "").replaceAll("T", "").substr(0, 15)
          );
        });
        if(Posts.filter((post) => post.featured).length === 0){
          // console.log(Posts)
          setFeaturedPost(Posts[0]);
        } else {
          setFeaturedPost(Posts.filter((post) => post.featured)[0]);
        }
        
        if (data["data"]["listPosts"]["nextToken"] !== null) {
          getAllPosts(data["data"]["listPosts"]["nextToken"]);
        }
        setCategoriesList([...new Set(categoriesList)]);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getAllPosts(null);
    metaData.forEach((data) => {
      getMeta(data);
    });
  }, []);

  useEffect(() => {
    if (featuredPost !== undefined) {
      setFeaturedLoaded(true);
    }
  }, [featuredPost]);

  return (
    <Container className="blogContainer">
      <HelmetMetaData
        title={title}
        description={description}
        keywords={keywords}
      ></HelmetMetaData>
      <div className="infoContainer">
        <h1 className="headerTitle">{lang === "fr" ? "Blogue" : "Blog"}</h1>
        <p className="infoContainer-description">
          {lang === "fr"
            ? "Nous publions des blogs hebdomadaires pour vous aider à vous tenir au courant de l'actualité technologique émergente et perturbatrice. Vous trouverez des articles sur la blockchain au quotidien."
            : "We post weekly blogs to help you keep updated with emerging and disruptive technological news. Everyday Blockchain reads."}
        </p>
        <Container className="containerWithBorder">
          <div className="sectionContainer">
            {!featuredLoaded ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <Row className="align-items-center">
                <Col
                  id="text-element"
                  xs={12}
                  lg={6}
                  className="featuredPostContent"
                >
                  <label className="titleSection-blog">
                    {lang === "fr"
                      ? "Article de blogue en vedette"
                      : "Featured Blog Post"}
                  </label>
                  <p className="featuredPostSubtitle">{featuredPost.title}</p>
                  <p className="featuredPostLead">
                    <span>{featuredPost.publish_at.slice(0, 10)}</span>
                    {printCat(featuredPost.categories)}
                  </p>
                  <div className="featuredPostDescription">
                    {featuredPost.content}
                  </div>
                  <br />

                  {lang === "fr"
                    ? <p>
                      Faites défiler l'écran pour lire ou cliquez sur "Continuer la lecture" pour accéder à l'article du blogue.
                    </p>
                    : <p>
                      Scroll to read or press continue reading to go to the blog
                      post.
                    </p>
                  }

                  <br />

                  {featuredPost.postLink !== null ? (
                    <Row>
                      <Col xs={12} lg={4}>
                        <Button
                          className="button btn-2 featured-btn"
                          href={`/${lang}/Blog/f/${featuredPost.slug}`}
                        >
                          {lang === "fr"
                            ? "Continuer la lecture"
                            : "Continue Reading"}
                        </Button>
                      </Col>
                      <Col xs={12} lg={4}>
                        {lang === "fr" ? (
                          <Button
                            className="button featured-btn"
                            onClick={() => {
                              localStorage.setItem("lang", "en");
                              window.location.href = `/en/Blog/f/${featuredPost.postLink}`;
                            }}
                          >
                            Lire en anglais
                          </Button>
                        ) : (
                          <Button
                            className="button featured-btn"
                            onClick={() => {
                              localStorage.setItem("lang", "fr");
                              window.location.href = `/fr/Blog/f/${featuredPost.postLink}`;
                            }}
                          >
                            Read in French
                          </Button>
                        )}
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col xs={12} lg={4}>
                        <Button
                          className="button btn-2 featured-btn"
                          href={`/${lang}/Blog/f/${featuredPost.slug}`}
                        >
                          {lang === "fr"
                            ? "Continuer la lecture"
                            : "Continue Reading"}
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col id="image-element" xs={12} lg={6}>
                  <img
                    onContextMenu={(e) => e.preventDefault()}
                    className="featuredPostImg"
                    src={featuredPost.image}
                    alt="Featured Blog post"
                  />
                </Col>
              </Row>
            )}
          </div>
        </Container>
      </div>
      <div className="categoriesContainer">
        <h1>{lang === "fr" ? "Nos articles de blogue" : "Our Blog Posts"}</h1>
        <div className="categoriesFilter">
          <div className="categoryLinks">
            <span className="categoriesFilterName">
              {lang === "fr" ?
                "Catégories: "
                : "Categories: "}
            </span>
            {categoriesList.map((text, idx) => {
              return (
                <a
                  className={`categoryLink ${text === category ? "categoryLinkClicked" : ""
                    }`}
                  href={`?category=${text}`}
                  key={idx}
                >
                  {"\t" + text}
                </a>
              );
            })}
            <br />
            <br />
            <a
              className="categoryLink"
              style={{ fontSize: "18px" }}
              href={`/${lang}/Blog/`}
            >
              {lang === "fr" ?
                "Supprimer tous les filtres"
                : "Clear All Filters"}
            </a>
          </div>
        </div>
      </div>
      <Container className="postsContainer">
        <Row>
          <CardGroup className="featured-cardgroup-blog">
            {!isLoaded ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              Posts.map((post, idx) => (
                <Col style={{ display: "flex" }} lg={6}>
                  <Card className="blogPostCard" key={idx}>
                    <Card.Body>
                      <Card.Img onContextMenu={(e) => e.preventDefault()} variant="top" src={post.image} />
                      <Card.Title className="blogPostCard-title">
                        <h1 className="featuredPostSubtitle">{post.title}</h1>
                      </Card.Title>
                      <div className="blogPostCard-body">
                        <p
                          className="featuredPostLead"
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          <span>{post.publish_at.slice(0, 10)}</span>
                          {/* {post.categories.join(", ").slice(0, 100)} */}
                          {printCat(post.categories)}
                        </p>
                        <div className="featuredPostDescription">
                          {post.content}
                        </div>
                        <hr />
                        {lang === "fr"
                          ? <p>
                            Remarque: Faites défiler pour lire ou continuez à lire sur la page du blogue en utilisant le bouton ci-dessous.
                          </p>
                          : <p>
                            Note: Scroll to read or continue reading on blog page
                            using the button below.
                          </p>}

                      </div>
                    </Card.Body>
                    <Card.Footer>

                      {post.postLink !== null ? (

                        lang === "fr" ? (
                          <div>
                            <Button href={`/${lang}/Blog/f/${post.slug}`} className="button btn-2">
                              Continuer la lecture
                            </Button>
                            <Button
                              style={{ marginLeft: "1em" }}
                              className="button"
                              onClick={() => {
                                localStorage.setItem("lang", "en");
                                window.location.href = `/en/Blog/f/${post.postLink}`;
                              }}
                            >
                              Read in English
                              {/* Lire en anglais */}
                            </Button>
                          </div>
                        ) : (
                          <div>
                            <Button href={`/${lang}/Blog/f/${post.slug}`} className="button btn-2">
                              Continue Reading
                            </Button>
                            <Button
                              style={{ marginLeft: "1em" }}
                              className="button"
                              onClick={() => {
                                localStorage.setItem("lang", "fr");
                                window.location.href = `/fr/Blog/f/${post.postLink}`;
                              }}
                            >
                              Lisez en français
                            </Button>
                          </div>
                        )
                      ) : (
                        <Button href={`/${lang}/Blog/f/${post.slug}`} className="button">
                          {lang === "fr"
                            ? "Continuer la lecture"
                            : "Continue Reading"}
                        </Button>
                      )}
                    </Card.Footer>
                    {/* </a> */}
                  </Card>
                </Col>
              ))
            )}
          </CardGroup>
        </Row>
      </Container>
      {/* The following is used to show any popup at this location, there may not be any popups to show */}
      <PopupComponent lang={lang} location="Blog" />
    </Container >
  );
}

export default Blog;
