import React from 'react'
// Since we are injecting raw html to embed mailchimp's subscription form, sanitize input for security

// Copied from mailchimp
const rawHTML = `<!-- Begin Mailchimp Signup Form -->
<div id="mc_embed_signup">
<form action="https://innovfin.us1.list-manage.com/subscribe/post?u=34587b89d13c27baf1ad24fd4&amp;id=5555a41fc3" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
    <div id="mc_embed_signup_scroll">
	  <input type="email" value="" name="EMAIL" class="email" size="32" id="mce-EMAIL" placeholder="Email Address" required>
    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_34587b89d13c27baf1ad24fd4_5555a41fc3" tabindex="-1" value=""></div>
    <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
    </div>
</form>
</div>
<!--End mc_embed_signup-->`

// French translation -- might want to change mailing list to french one in the future
const frenchHTML = `<!-- Begin Mailchimp Signup Form -->
<div id="mc_embed_signup">
<form action="https://innovfin.us1.list-manage.com/subscribe/post?u=34587b89d13c27baf1ad24fd4&amp;id=5555a41fc3" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
    <div id="mc_embed_signup_scroll">
	  <input type="email" value="" name="EMAIL" class="email" size="32" id="mce-EMAIL" placeholder="Courriel" required>
    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_34587b89d13c27baf1ad24fd4_5555a41fc3" tabindex="-1" value=""></div>
    <div class="clear"><input type="submit" value="Abonnez-vous" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
    </div>
</form>
</div>

<!--End mc_embed_signup-->`

function MailchimpForm({ lang }) {
  return (
    <div>
      {<div dangerouslySetInnerHTML={{ __html: lang === 'fr' ? frenchHTML : rawHTML }} />}
    </div>
  )
}

export default MailchimpForm
