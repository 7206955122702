import * as Yup from 'yup';

const ForgotPasswordConfirmSchema = Yup.object().shape({
    code: Yup.string()
        .required("Confirmation code is required"),
    password: Yup.string()
        .min(8, "Password must be 8 characters minimum")
        .required("Password is required"),
    username: Yup.string()
        .required('Username is required'),
})

export default ForgotPasswordConfirmSchema;