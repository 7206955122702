import React, { useEffect, useState } from 'react';
import '../styling/Services.css';
import { Button, Col, Container, Row } from 'react-bootstrap';
import metaData from "../json_files/metadata.json";
import HelmetMetaData from '../components/HelmetMetaData';
import { listServices } from "../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import PopupComponent from '../components/PopupComponent';

function BookSpeaker() {
    const { lang } = useParams();
    // For the meta data stuff
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [keywords, setKeywords] = useState("");
    // Get location metadata from JSON file, this system maybe used in the future so that
    // non-developers can edit metadata.
    const getMeta = (data) => {
        if (data.url === window.location.pathname.split("/")[2]) {
            setTitle(data.title);
            setDescription(data.description);
            setKeywords(data.keywords);
        }
    };

    //the below is used for storing the content that will be grabbed from the API
    //is loaded is to check if the api is loaded
    const [serviceContent, setServiceContent] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const setContentAPI = () => {
        API.graphql(graphqlOperation(listServices))
            .then((data) => {
                const modifiedData2 = data["data"]["listServices"]["items"];

                modifiedData2.map((item) => {
                    item.content = parse(item.content);
                    item.contentFr = parse(item.contentFr);
                    return item;
                });

                setServiceContent(modifiedData2);
                setIsLoaded(true);
                // console.log("content education: ", serviceContent.find(find => find.id === "4c769797-8af0-4a49-b30a-6d577e8eb9d4"));
            })
            .catch((err) => {
                console.log(err);
            });
    }

     //list of class names to disable right-click
     const imageClasses = [".primaryElement-img"];

     //remove the context menu for the images
     const removeRightClick = () =>{
         imageClasses.forEach(imgClass => {
             const noContextMenu = document.querySelectorAll(imgClass);
             noContextMenu.forEach(data => {
                 data.addEventListener("contextmenu", e => e.preventDefault());
             })
         })
     }

    useEffect(() => {
        setContentAPI();
        removeRightClick();
        metaData.forEach(data => {
            getMeta(data);
        });
    }, []);
    return (
        <Container>
            <HelmetMetaData
                title={title}
                description={description}
                keywords={keywords}>
            </HelmetMetaData>
            <div className='infoContainer'>
                <h1>
                    {
                        !isLoaded ?
                            (lang === 'fr' ?
                                'Réserver un conférencier spécialiste de la blockchain'
                                : 'Book a Blockchain Speaker'
                            )
                            :
                            (lang === 'fr' ?
                                serviceContent.find(find => find.id === "bd7e3e4d-1b6a-41e0-b5fc-a4b277f086ba").titleFr
                                : serviceContent.find(find => find.id === "bd7e3e4d-1b6a-41e0-b5fc-a4b277f086ba").title
                            )
                    }
                </h1>
                <div className="infoContainer-description">
                    {
                        !isLoaded ?
                            (lang === 'fr' ?
                                <p>
                                    Installez-vous et trouvez InnovFin pour aider votre entreprise ou organisation à comprendre les technologies émergentes et perturbatrices avec notre fondatrice et PDG, Othalia Doe-Bruce, dans un discours complet sur Blockchain.
                                </p>
                                : <p>
                                    Settle down and find InnovFin to help your company or organization understand emerging and disruptive technologies with our Founder and CEO, Othalia Doe-Bruce, on an all-encompassing speech about Blockchain.
                                </p>
                            )
                            :
                            (lang === 'fr' ?
                                serviceContent.find(find => find.id === "bd7e3e4d-1b6a-41e0-b5fc-a4b277f086ba").contentFr
                                : serviceContent.find(find => find.id === "bd7e3e4d-1b6a-41e0-b5fc-a4b277f086ba").content
                            )
                    }
                </div>

                {/* Container for the cards */}
                <Container className="bookSpeaker serviceContainer">
                    <Row className="align-items-center">
                        <Col className="bookSpeaker-content" id="text-element" lg={6}>
                            <h1>
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            'Réserver un conférencier spécialiste de la blockchain'
                                            : 'Book a Blockchain Speaker'
                                        )
                                        :
                                        (lang === 'fr' ?
                                            serviceContent.find(find => find.id === "c3b9af89-81db-4a16-b679-4b8f68f03828").titleFr
                                            : serviceContent.find(find => find.id === "c3b9af89-81db-4a16-b679-4b8f68f03828").title
                                        )
                                }
                            </h1>
                            <div>
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            <p>
                                                <span>
                                                    Vous avez besoin d'un expert en blockchain pour votre événement ?
                                                    Réservez notre PDG, Othalia Doe-Bruce, pour votre prochain événement !
                                                    <br /><br />
                                                    Othalia Doe-Bruce est une entrepreneure primée, une conférencière internationale et une auteure dont on a parlée dans le Globe & Mail, avec des apparitions qui incluent le HCR, le Blockchain Hackathon du gouvernement du Canada, la Canadian Screen Awards Week, Radio Canada, l'IGF, l'Université de Californie Irvine et plus encore....
                                                </span>
                                            </p>
                                            : <p>
                                                <span>
                                                    Do you need to book a Blockchain expert to speak at your event? Invite our CEO, Othalia Doe-Bruce, for your next event!
                                                    <br /><br />
                                                    Othalia Doe-Bruce is an award-winning entrepreneur, international speaker, and author featured on the Globe &amp; Mail,
                                                    with appearances that include the UNHCR, Government of Canada Blockchain Hackathon, Canadian Screen Awards Week, Radio Canada, FMI, University of California Irvine, and more...
                                                </span>
                                            </p>
                                        )
                                        :
                                        (lang === 'fr' ?
                                            serviceContent.find(find => find.id === "c3b9af89-81db-4a16-b679-4b8f68f03828").contentFr
                                            : serviceContent.find(find => find.id === "c3b9af89-81db-4a16-b679-4b8f68f03828").content
                                        )
                                }
                            </div>
                            <Button href={`/${lang}/about-othalia-doe-bruce-1/`} className='button' style={{ float: 'left' }}>
                                {
                                    !isLoaded ?
                                        (lang === 'fr' ?
                                            'Pour en savoir plus sur Othalia, cliquez ici'
                                            : 'Find Out More About Othalia Here'
                                        )
                                        :
                                        (lang === 'fr' ?
                                            serviceContent.find(find => find.id === "c3b9af89-81db-4a16-b679-4b8f68f03828").buttonFr
                                            : serviceContent.find(find => find.id === "c3b9af89-81db-4a16-b679-4b8f68f03828").button
                                        )
                                }
                            </Button>
                        </Col>
                        <Col id="image-element" lg={6}>
                            <div className="primaryElement-img">
                                <img
                                    src={!isLoaded ? "../assets/Book a Blockchain Speaker Assets/BookAblockchainSpeaker.png" : serviceContent.find(find => find.id === "c3b9af89-81db-4a16-b679-4b8f68f03828").image} alt='Booking a Speaker InnovFin'
                                    className="card-img-dropshadow bookSpeaker-img"
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* The following is used to show any popup at this location, there may not be any popups to show */}
            <PopupComponent lang={lang} location="Book Speaker"/>
        </Container>
    )
}

export default BookSpeaker;