import React, { useState, useEffect } from 'react'
import { Button, Card, CardGroup, Carousel, Spinner } from 'react-bootstrap';
import { API, graphqlOperation } from "aws-amplify";
import { listPosts, listHomes } from "../graphql/queries";
import parse from "html-react-parser";

function FeaturedBlog({ lang }) {
    const [Posts, setPosts] = useState([]);
    // isLoaded is for the page text
    // isBlogLoaded is for the blog posts
    const [isLoaded, setIsLoaded] = useState(false);
    const [isBlogLoaded, setIsBlogLoaded] = useState(false);
    const [homeContent, setHomeContent] = useState([]);

    const setContentAPI = () => {
        API.graphql(graphqlOperation(listHomes))
            .then((data) => {
                const modifiedData2 = data["data"]["listHomes"]["items"];

                modifiedData2.map((item) => {
                    item.content = parse(item.content);
                    item.contentFr = parse(item.contentFr);
                    return item;
                });

                setHomeContent(modifiedData2);
                setIsLoaded(true);
                // console.log("content blog: ", homeContent.find(find => find.id === "73f1fbbf-4286-4916-8d73-a7271787fe49").title);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        // Advanced post filtering, grabs posts that are scheduled to be published or post that have do not have a schedule publish date.
        // Also gets posts that have a post status of PUBLISH (true)
        let filter = {
            and: [
                {
                    or: [
                        { publish_at: { lt: new Date().toISOString() } },
                        { publish_at: { eq: "" } },
                    ],
                },
                { status: { eq: true } },
            ],
        };
        setContentAPI();
        API.graphql(graphqlOperation(listPosts, { filter: filter }))
            .then((data) => {
                let modifiedData = data["data"]["listPosts"]["items"];

                if (lang === "en" || lang === null) {
                    modifiedData = modifiedData.filter((post) => post.lang.includes("english"))
                } else {
                    modifiedData = modifiedData.filter((post) => post.lang.includes("french"))
                }

                modifiedData.map((item) => {
                    item.content = parse(item.content);
                    return item;
                });
                //sorts posts by date created
                modifiedData.sort(function (a, b) {
                    return (
                        //format date into a number and check which post should go first
                        b.publish_at.replace(/-/g, "").replaceAll(":", "").replaceAll("T", "").substr(0, 15) -
                        a.publish_at.replace(/-/g, "").replaceAll(":", "").replaceAll("T", "").substr(0, 15)
                    );
                });
                setPosts(modifiedData);

                // console.log(data["data"]["listPosts"].items);
                setIsBlogLoaded(true);
                // console.log("mod ", modifiedData)
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <div>
            <div className='sect2' id="featuredBlog">
                <h1>
                    {
                        !isLoaded ?
                            (lang === 'fr' ?
                                'Articles de blog en vedette'
                                : 'Featured Blog Posts'
                            )
                            :
                            (lang === 'fr' ?
                                homeContent.find(find => find.id === "158ed738-18b1-44be-a9d0-3d838fe06b6e").titleFr
                                : homeContent.find(find => find.id === "158ed738-18b1-44be-a9d0-3d838fe06b6e").title
                            )
                    }
                </h1>
                {
                    !isLoaded ?
                        (lang === 'fr' ?
                            <p>Nous publions des blogs hebdomadaires pour vous aider à vous tenir au courant de l'actualité technologique émergente et perturbatrice. Lectures quotidiennes de Blockchain.</p>
                            : <p>We post weekly blogs to help you keep updated with emerging and disruptive technological news. Everyday Blockchain reads.</p>
                        )
                        :
                        (lang === 'fr' ?
                            homeContent.find(find => find.id === "158ed738-18b1-44be-a9d0-3d838fe06b6e").contentFr
                            : homeContent.find(find => find.id === "158ed738-18b1-44be-a9d0-3d838fe06b6e").content
                        )
                }

                {/* The carousel here is for the featured blog posts. It will have two pages displaying the 6 newest blog posts*/}
                {!isBlogLoaded ? (
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                ) : (
                    (Posts.length > 3 ? (
                        <Carousel variant='dark' interval={null} nextLabel={""} prevLabel={""}>
                            <Carousel.Item>
                                <CardGroup className="featured-cardgroup">
                                    {Posts.slice(0, 3).map((post, idx) => (
                                        <Card className="infoCard-blog" key={post.id}>
                                            <Card.Body>
                                                <Card.Img variant="top" src={post.image} />
                                                <h1>{post.title}</h1>
                                                <div className="featuredPostDescription-home">
                                                    {post.content}
                                                </div>
                                                <hr />
                                                <p>
                                                    {lang === "fr" ?
                                                        "Remarque: Faites défiler pour lire ou continuez à lire sur la page du blog en utilisant le bouton ci-dessous."
                                                        : "Note: Scroll to read or continue reading on blog page using the button below."
                                                    }
                                                </p>
                                            </Card.Body>
                                            <Card.Footer>
                                                <Button href={`/${lang}/Blog/f/` + post.slug} className="button">
                                                    {lang === "fr" ?
                                                        "Continuer la lecture"
                                                        : "Continue Reading"
                                                    }
                                                </Button>
                                            </Card.Footer>
                                        </Card>
                                    ))}
                                </CardGroup>
                            </Carousel.Item>
                            <Carousel.Item>
                                <CardGroup className="featured-cardgroup">
                                    {Posts.slice(3, 6).map((post, idx) => (
                                        <Card className="infoCard-blog" key={post.id}>
                                            <Card.Body>
                                                <Card.Img variant="top" src={post.image} />
                                                <h1>{post.title}</h1>
                                                <div className="featuredPostDescription-home">
                                                    {post.content}
                                                </div>
                                                <hr />
                                                <p>
                                                    {lang === "fr" ?
                                                        "Remarque: Faites défiler pour lire ou continuez à lire sur la page du blog en utilisant le bouton ci-dessous."
                                                        : "Note: Scroll to read or continue reading on blog page using the button below."
                                                    }
                                                </p>
                                            </Card.Body>
                                            <Card.Footer>
                                                <Button href={`/${lang}/Blog/f/` + post.slug} className="button">
                                                    {lang === "fr" ?
                                                        "Continuer la lecture"
                                                        : "Continue Reading"
                                                    }
                                                </Button>
                                            </Card.Footer>
                                        </Card>
                                    ))}
                                </CardGroup>
                            </Carousel.Item>
                        </Carousel>
                    ) : (
                        <Carousel variant='dark' nextLabel={""} prevLabel={""}>
                            <Carousel.Item>
                                <CardGroup className="featured-cardgroup">
                                    {Posts.slice(0, 3).map((post, idx) => (
                                        <Card className="infoCard-blog" key={post.id}>
                                            <Card.Body>
                                                <Card.Img variant="top" src={post.image} />
                                                <h1>{post.title}</h1>
                                                <div className="featuredPostDescription-home">
                                                    {post.content}
                                                </div>
                                                <hr />
                                                <p>
                                                    {lang === "fr" ?
                                                        "Remarque: Faites défiler pour lire ou continuez à lire sur la page du blog en utilisant le bouton ci-dessous."
                                                        : "Note: Scroll to read or continue reading on blog page using the button below."
                                                    }
                                                </p>
                                            </Card.Body>
                                            <Card.Footer>
                                                <Button href={`/${lang}/Blog/f` + post.slug} className="button">
                                                    {lang === "fr" ?
                                                        "Continuer la lecture"
                                                        : "Continue Reading"
                                                    }
                                                </Button>
                                            </Card.Footer>
                                        </Card>
                                    ))}
                                </CardGroup>
                            </Carousel.Item>
                        </Carousel>
                    ))
                )}

            </div>
        </div>
    )
}

export default FeaturedBlog;