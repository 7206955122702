import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import Auth from '@aws-amplify/auth';
import ForgotPasswordSchema from './Schema/ForgotPasswordSchema';
import ForgotPasswordSchemaFr from './Schema/ForgotPasswordSchemaFr';

function ForgotPassword({ lang }) {
    const storedForm = JSON.parse(localStorage.getItem('formState'))
    const [formState, setFormState] = useState(storedForm)

    useEffect(() => {
        localStorage.setItem("formState", JSON.stringify(formState))
    }, [formState])

    async function resetPass(data) {
        try {

            const { username } = data
            await Auth.forgotPassword(username)
            setFormState(() => ({ ...formState, formType: "forgotPassConf" }))
            window.location.reload();
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <Container>
            <div className="infoContainer" >
                <React.Fragment>
                    <h1>{lang === 'fr' ? 'Mot de passe oublié' : 'Forgot my Password'}</h1>
                    <p style={{ padding: '0' }}>
                        {lang === 'fr' ? <span>Vous avez oublié votre mot de passe? <br />Nous vous enverrons un courriel pour réinitialiser votre mot de passe.</span>
                            : <span>Forget your password?<br />We will send you an email to reset your password.</span>}
                    </p>
                </React.Fragment>
                <Container className="registerContainerWithBorder" >
                    <Formik
                        validationSchema={ lang === 'fr' ? ForgotPasswordSchemaFr : ForgotPasswordSchema}
                        onSubmit={(data, { setSubmitting }) => {
                            setSubmitting(true);
                            resetPass(data);
                            setSubmitting(false);
                        }}
                        initialValues={{
                            username: '',
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            isSubmitting,
                            values,
                            errors,
                            touched,
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                {/* <Row className="mb-6"> */}
                                {/*    UserName  */}
                                <Form.Group md="6">
                                    <Form.Text>{lang === 'fr' ? 'Nom d\'utilisateur' : 'Username'}</Form.Text>
                                    <Form.Control type="text" name="username" value={values.username} onChange={handleChange} isValid={touched.username && !errors.username} isInvalid={!!errors.username} id="username" />
                                    <Form.Control.Feedback type="invalid"> {errors.username} </Form.Control.Feedback>
                                </Form.Group>
                                {/* </Row> */}

                                <Button disabled={isSubmitting} className="loginBtn" type="submit" style={{ margin: '12px 0 12px 0' }} >
                                    {lang === 'fr' ? 'Envoyer un courriel' : 'Send Email'}
                                </Button>
                                <Row>
                                    <Col xs={12} sm={6} md={6} >
                                        <Form.Text className="text-muted">
                                            {lang === 'fr' ? 'Retournez à la page d\'inscription :' : 'Go back to the sign in page:'}<br />
                                        </Form.Text>
                                        <button onClick={() => {
                                            setFormState(() => ({ ...formState, formType: "signIn" }));
                                            window.location.reload();
                                        }
                                        } className="btn-link signUpLbl"> {lang === 'fr' ? 'Connexion' : 'Login'}</button>
                                    </Col>
                                    <Col xs={12} sm={6} md={6}>
                                        <Form.Text className="text-muted">
                                            {lang === 'fr' ? 'Vous avez déjà reçu un code de confirmation par courriel?' : 'Already got a confirmation code email?'}<br />
                                        </Form.Text>
                                        <button onClick={() => {
                                            setFormState(() => ({ ...formState, formType: "forgotPassConf" }));
                                            window.location.reload();
                                        }
                                        } className="btn-link signUpLbl">{lang === 'fr' ? 'Confirmer la réinitialisation' : 'Confirm reset'}</button>
                                    </Col>
                                </Row>

                            </Form>
                        )}
                    </Formik>

                </Container>
            </div>
        </Container>
    )
}

export default ForgotPassword;