import React from 'react'
import '../styling/logoSlider.css'

function LogoSlider(props) {
    return (
        <div className='slider' id={props.logoId}>
            <ul className='slider-content'>
                {
                    props.list.map((item) => {
                        return (
                            <li key={item.name}>
                                <img className="card-img-dropshadow" src={item.src} alt={item.name}/>
                            </li>
                        )

                    })
                }
                {/* render twice for looping animation */}
                {
                    props.list.map((item) => {
                        return (
                            <li key={item.name}>
                                <img className="card-img-dropshadow" src={item.src} alt={item.name}/>
                            </li>
                        )

                    })
                }
            </ul>
        </div>
    );
}

export default LogoSlider;