import React, { useEffect, useState } from 'react'
import './styling/AboutInnovFin.css'
import { Button, Col, Container, Row } from 'react-bootstrap'
import TeamMember from './TeamMember'
import AboutCheckList from './AboutCheckList'
import specializesList from './json_files/SpecializeList.json'
import Approach from './json_files/Approach.json'
import whyus1 from './json_files/WhyUs1.json'
import whyus2 from './json_files/WhyUs2.json'
import TeamMemberList from './json_files/TeamMember.json'
import specializesListFr from './json_files/SpecializeListFr.json'
import ApproachFr from './json_files/ApproachFr.json'
import whyus1Fr from './json_files/WhyUs1Fr.json'
import whyus2Fr from './json_files/WhyUs2Fr.json'
import TeamMemberListFr from './json_files/TeamMemberFr.json'
import SocialMedia from './components/SocialMedia'
import metaData from "./json_files/metadata.json";
import HelmetMetaData from './components/HelmetMetaData'
import { listAboutContents, listVideoEmbeds } from "./graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import PopupComponent from './components/PopupComponent'

function AboutInnovFin() {
  const { lang } = useParams();

  // For the meta data stuff
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState("");

  // Get location metadata from JSON file, this system maybe used in the future so that
  // non-developers can edit metadata.
  const getMeta = (data) => {
    if (data.url === window.location.pathname.split("/")[2]) {
      setTitle(data.title);
      setDescription(data.description);
      setKeywords(data.keywords);
    }
  };

  //the below is used for storing the content that will be grabbed from the API
  //is loaded is to check if the api is loaded
  const [aboutContent, setAboutContent] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isVidLoaded, setIsVidLoaded] = useState(false);
  const [videoEmbed, setVideoEmbed] = useState([]);

  const setContentAPI = () => {
    API.graphql(graphqlOperation(listAboutContents))
      .then((data) => {
        const modifiedData2 = data["data"]["listAboutContents"]["items"];

        modifiedData2.map((item) => {
          item.content = parse(item.content);
          item.contentFr = parse(item.contentFr);
          return item;
        });

        setAboutContent(modifiedData2);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // needs separate api function or a crash is caused
  const setVideoAPI = () => {
    API.graphql(graphqlOperation(listVideoEmbeds))
      .then((data) => {
        const modifiedData3 = data["data"]["listVideoEmbeds"]["items"];

        setVideoEmbed(modifiedData3);
        setIsVidLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

   //list of class names to disable right-click
   const imageClasses = [".card-img-dropshadow-heavy", ".primaryElement-img", ".card-img-dropshadow",".card-img-dropshadow-heavy2"];

   //remove the context menu for the images
   const removeRightClick = () =>{
       imageClasses.forEach(imgClass => {
           const noContextMenu = document.querySelectorAll(imgClass);
           noContextMenu.forEach(data => {
               data.addEventListener("contextmenu", e => e.preventDefault());
           })
       })
   }

  useEffect(() => {
    setContentAPI();
    setVideoAPI();
    removeRightClick();
    metaData.forEach(data => {
      getMeta(data);
    });
  }, []);

  return (
    <div>
      <HelmetMetaData
        title={title}
        description={description}
        keywords={keywords}>
      </HelmetMetaData>
      <Container>
        <div className='infoContainer' id='AboutContainer'>
          <h1>{
            !isLoaded ?
              (lang === 'fr' ?
                "À propos d'InnovFin"
                : 'About InnovFin'
              )
              :
              (lang === 'fr' ?
                aboutContent.find(find => find.id === "79c623dc-a2e5-4d71-81c3-a9f55c6bd08f").titleFr
                : aboutContent.find(find => find.id === "79c623dc-a2e5-4d71-81c3-a9f55c6bd08f").title
              )
          }</h1>
          <div className="infoContainer-description">
            {
              !isLoaded ?
                (lang === 'fr' ?
                  <p>
                    <span>
                      InnovFin est une société de conseil en technologies émergentes
                      et disruptives, <br />
                      avec une spécialisation dans le secteur de la finance.
                    </span>
                  </p>
                  :
                  <p>
                    <span>
                      InnovFin is an emerging and disruptive technologies consulting company, with a specialization in the Finance industry,
                      with capabilities in various other industries such as logistics, manufacturing, procurement, food, agriculture and more!
                    </span>
                  </p>
                )
                :
                (lang === 'fr' ?
                  aboutContent.find(find => find.id === "79c623dc-a2e5-4d71-81c3-a9f55c6bd08f").contentFr
                  : aboutContent.find(find => find.id === "79c623dc-a2e5-4d71-81c3-a9f55c6bd08f").content
                )
            }
          </div>

          <Container className='containerWithBorder'>
            {/* Our Experience */}
            <div className='sectionContainer'>
              <Row className="align-items-center">
                <Col xs={12} md={12} lg={6}>
                  <img
                    className='img-center-size card-img-dropshadow-heavy'
                    src={!isLoaded ? "../assets/About InnovFin Assets/OurExperience.png" : aboutContent.find(find => find.id === "61fd928c-7f14-417c-8dab-ce623313ed8f").image}
                    alt='InnovFin Experience'
                  />
                </Col>
                <Col id="text-element-about" xs={12} md={12} lg={6}>
                  <label className='titleSection'>
                    {
                      !isLoaded ?
                        (lang === 'fr' ?
                          'Notre expérience'
                          : 'Our Experience'
                        )
                        :
                        (lang === 'fr' ?
                          aboutContent.find(find => find.id === "61fd928c-7f14-417c-8dab-ce623313ed8f").titleFr
                          : aboutContent.find(find => find.id === "61fd928c-7f14-417c-8dab-ce623313ed8f").title
                        )
                    }
                  </label>
                  <div className="checkList">
                    {
                      !isLoaded ?
                        (lang === 'fr' ?
                          <div>
                            <p>
                              <span>
                                Après 10 ans dans le secteur de la finance, nous avons
                                décidé de changer de direction. Aujourd'hui, nous partageons notre
                                passion en aidant les sociétés financières à intégrer les technologies
                                de rupture dans leurs activités. InnovFin est spécialisée dans:
                              </span>
                            </p>
                            <AboutCheckList
                              list={specializesListFr}
                            />
                            <p>
                              Contactez-nous dès aujourd'hui pour savoir comment nous pouvons soutenir votre croissance et vous mettre sur la voie du succès et du profit.
                            </p>
                          </div>
                          : <div>
                            <p>
                              <span>
                                After 10 years in the finance industry, we decided to alter direction.
                                Now, we share our passion by helping various industries, including top finance companies, integrate
                                disruptive technologies into their business.
                                InnovFin specializes in:
                              </span>
                            </p>
                            <AboutCheckList
                              list={specializesList}
                            />
                            <p>
                              Talk to us today about how we can support your growth and put you on a solid track to success and profit.
                            </p>
                          </div>
                        )
                        :
                        (lang === 'fr' ?
                          aboutContent.find(find => find.id === "61fd928c-7f14-417c-8dab-ce623313ed8f").contentFr
                          : aboutContent.find(find => find.id === "61fd928c-7f14-417c-8dab-ce623313ed8f").content
                        )
                    }
                  </div>
                </Col>
              </Row>
            </div>

            {/* Our Aproach */}
            <div className='sectionContainer'>
              <Row className="align-items-center">
                <Col id="text-element" xs={12} md={12} lg={6}>
                  <label className='titleSection'>
                    {
                      !isLoaded ?
                        (lang === 'fr' ?
                          'Notre méthode'
                          : 'Our Approach'
                        )
                        :
                        (lang === 'fr' ?
                          aboutContent.find(find => find.id === "85f5cf3a-2d02-4b0f-823f-32aa543c4256").titleFr
                          : aboutContent.find(find => find.id === "85f5cf3a-2d02-4b0f-823f-32aa543c4256").title
                        )
                    }
                  </label>
                  <div className="checkList">
                    {
                      !isLoaded ?
                        (lang === 'fr' ?
                          <div>
                            <p>
                              <span>
                                InnovFin vous aide à accéder à de nouveaux marchés en
                                exploitant nos réseaux mondiaux et en tirant profit de
                                la puissance des technologies émergentes :
                              </span>
                            </p>
                            <AboutCheckList
                              list={ApproachFr}
                            />
                            <p>
                              Nous proposons un ensemble de services de qualité qui vous aideront à y parvenir rapidement et sans heurts. C\'est ainsi que nous assurons votre succès!
                            </p>
                          </div>
                          :
                          <div>
                            <p>
                              <span>
                                InnovFin helps you access new markets by tapping into
                                our worldwide networks and leveraging the power
                                of emerging technologies:
                              </span>
                            </p>
                            <AboutCheckList
                              list={Approach}
                            />
                            <p>
                              We offer a suite of quality services that will help you get there quickly and smoothly. That’s how we ensure your success!
                            </p>
                          </div>
                        )
                        :
                        (lang === 'fr' ?
                          aboutContent.find(find => find.id === "85f5cf3a-2d02-4b0f-823f-32aa543c4256").contentFr
                          : aboutContent.find(find => find.id === "85f5cf3a-2d02-4b0f-823f-32aa543c4256").content
                        )
                    }
                  </div>
                </Col>
                <Col id="image-element" xs={12} md={12} lg={6}>
                  <img
                    style={{ padding: "0em 2em" }}
                    src={!isLoaded ? "../assets/About InnovFin Assets/OurApproach.png" : aboutContent.find(find => find.id === "85f5cf3a-2d02-4b0f-823f-32aa543c4256").image}
                    className='img-center-size card-img-dropshadow-heavy'
                    alt='InnovFin Approach'
                  />
                </Col>
              </Row>
            </div>

            {/* Why Us */}
            <div className='sectionContainer'>
              <Row className="align-items-center">
                <Col xs={12} md={12} lg={6}>
                  <img
                    src={!isLoaded ? "../assets/About InnovFin Assets/WhyUs.png" : aboutContent.find(find => find.id === "09573467-9429-47d0-98a4-870ec8f0ca4c").image}
                    className='img-center-size card-img-dropshadow-heavy'
                    alt='InnovFin Why Us'
                  />
                </Col>
                <Col id="text-element-about" xs={12} md={12} lg={6}>
                  <label className='titleSection'>
                    {
                      !isLoaded ?
                        (lang === 'fr' ?
                          'Pourquoi nous'
                          : 'Why Us'
                        )
                        :
                        (lang === 'fr' ?
                          aboutContent.find(find => find.id === "09573467-9429-47d0-98a4-870ec8f0ca4c").titleFr
                          : aboutContent.find(find => find.id === "09573467-9429-47d0-98a4-870ec8f0ca4c").title
                        )
                    }
                  </label>
                  <div className="checkList">
                    {
                      !isLoaded ?
                        (lang === 'fr'
                          ? <div>
                            <p>
                              Un guichet unique avec accès aux bons outils et partenaires pour s'intégrer parfaitement dans la réalisation de votre projet :
                            </p>
                            <AboutCheckList list={whyus1Fr} />
                            <p>
                              Atteindre vos objectifs pour :
                            </p>
                            <AboutCheckList list={whyus2Fr} />
                            <p>
                              Pour vous aider à maintenir votre compétitivité dans un monde des affaires qui se digitalise rapidement, nous avons les outils en place pour atteindre vos objectifs. Ensemble, nous allons créer et affiner votre plan de réussite !
                            </p>
                          </div>
                          : <div>
                            <p>
                              We are the popular one-stop-shop, who can give you access to the right tools and partners to begin the realization of your project:
                            </p>
                            <AboutCheckList list={whyus1} />
                            <p>
                              Our goal is to:
                            </p>
                            <AboutCheckList list={whyus2} />
                            <p>
                              Our mission is to help you maintain your competitiveness in today’s rapidly digitalizing business world. We have the tools in place to assist you in achieving your goals. Together, we will create and refine your plan for success!
                            </p>
                          </div>
                        )
                        :
                        (lang === 'fr' ?
                          aboutContent.find(find => find.id === "09573467-9429-47d0-98a4-870ec8f0ca4c").contentFr
                          : aboutContent.find(find => find.id === "09573467-9429-47d0-98a4-870ec8f0ca4c").content
                        )
                    }
                  </div>
                </Col>
              </Row>
            </div>
          </Container>

          {/*Innov-Edu Platform*/}
          <div className='sect2'>
            <Container
              className='primaryElement  primaryElement-Var1'
              id='aboutTeamVid'
            >
              <Row className="align-items-center">
                {/* Rows containing the React-Player component */}
                <Col md={12} lg={6}>
                  <div className='player-wrapper'>
                    <video
                      onContextMenu={(e) => e.preventDefault()}
                      className="react-player"
                      poster={!isVidLoaded ? null : videoEmbed.find(find => find.id === "884c18f6-5589-4990-9c62-aa3c9c40344e").thumbnail}
                      title="Video"
                      width="100%"
                      height="100%"
                      src={!isVidLoaded ? 'https://www.youtube.com/watch?v=4qhGefsF9-4' : videoEmbed.find(find => find.id === "884c18f6-5589-4990-9c62-aa3c9c40344e").url.replace("watch?v=", "embed/") + "?rel=0"}
                      frameBorder="0"
                      // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      controls="controls"
                      controlsList="nodownload"
                    />
                  </div>
                </Col>
                <Col className='sect2-txt' md={12} lg={6}>
                  <h1>
                    {
                      !isLoaded ?
                        (lang === 'fr' ?
                          "Plate-forme d'apprentissage interactive Innov-Edu"
                          : 'Innov-Edu Interactive Learning Platform'
                        )
                        :
                        (lang === 'fr' ?
                          aboutContent.find(find => find.id === "f87d1a32-0b70-4809-bdef-380f46769865").titleFr
                          : aboutContent.find(find => find.id === "f87d1a32-0b70-4809-bdef-380f46769865").title
                        )}
                  </h1>
                  {
                    !isLoaded ?
                      (lang === 'fr' ?
                        <p>
                          <span>
                            Innov-Edu est la plateforme de choix pour les employeurs
                            qui cherchent à améliorer les compétences de leurs
                            employés ou de les réorienter vers une nouvelle
                            formation avec les compétences essentielles de
                            l'industrie 4.0.
                            <br />
                            <br />
                            Nous construisons une liste étendue de cours pour
                            équiper les employés avec les savoir-faire
                            technologiques émergents, afin que les organisations
                            puissent innover, automatiser, et motiver.
                            <br />
                            <br />
                            Tous nos cours sont disponibles en ligne, à la demande,
                            et sont accessibles depuis votre ordinateur portable,
                            votre tablette, votre téléphone et sur votre casque de
                            réalité virtuelle!
                          </span>
                        </p>
                        : <p>
                          <span>
                            Innov-Edu is the platform of choice for employers looking to upskill and reskill their employees with Industry 4.0 essential skill-sets.
                            <br /><br />
                            We are building an extensive list of courses to equip employees with emerging tech know-how, so organizations can innovate, automate, and motivate.
                            <br /><br />
                            All our courses are available online and on-demand! Courses can be accessed from your laptop, tablet, phone, or your virtual reality headset!
                          </span>
                        </p>
                      )
                      :
                      (lang === 'fr' ?
                        aboutContent.find(find => find.id === "f87d1a32-0b70-4809-bdef-380f46769865").contentFr
                        : aboutContent.find(find => find.id === "f87d1a32-0b70-4809-bdef-380f46769865").content
                      )
                  }
                  <div style={{ paddingTop: '2em' }}>
                    <Button href="/contact-us/" className='button' >
                      {
                        !isLoaded ?
                          (lang === 'fr' ?
                            'En savoir plus'
                            : 'Learn More'
                          )
                          :
                          (lang === 'fr' ?
                            aboutContent.find(find => find.id === "f87d1a32-0b70-4809-bdef-380f46769865").buttonFr
                            : aboutContent.find(find => find.id === "f87d1a32-0b70-4809-bdef-380f46769865").button
                          )
                      }
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {/* Our Team */}
          <Container className='teamContainer'>
            <div className='titleSection2'>
              <label>
                {
                  !isLoaded ?
                    (lang === 'fr' ?
                      'Notre équipe'
                      : 'Our Team'
                    )
                    :
                    (lang === 'fr' ?
                      aboutContent.find(find => find.id === "49f2a9a3-b2ff-4552-a632-4b93420c9e6e").titleFr
                      : aboutContent.find(find => find.id === "49f2a9a3-b2ff-4552-a632-4b93420c9e6e").title
                    )
                }
              </label>
              <div>
                {
                  !isLoaded ?
                    (lang === 'fr' ?
                      <p>
                        <span>
                          Rencontrez notre équipe dévouée à InnovFin!
                        </span>
                      </p>
                      : <p>
                        <span>
                          Meet our dedicated team at InnovFin!
                          <br /> We strive for quality and professionalism in
                          everything we do.
                        </span>
                      </p>
                    )
                    :
                    (lang === 'fr' ?
                      aboutContent.find(find => find.id === "49f2a9a3-b2ff-4552-a632-4b93420c9e6e").contentFr
                      : aboutContent.find(find => find.id === "49f2a9a3-b2ff-4552-a632-4b93420c9e6e").content
                    )
                }
              </div>
            </div>

            <Container className='sectionContainer'>
              <Row className="align-items-center">
                <Col md={12} lg={6}>
                  <img
                    className='img-center-size card-img-dropshadow-heavy'
                    src={!isLoaded ? "../assets/About InnovFin Assets/Team Images/Othalia.png" : aboutContent.find(find => find.id === "edc993e1-eac9-4a68-ba73-9e17fd116b52").image}
                    alt='Othalia'
                  />
                </Col>
                <Col md={12} lg={6}>
                  <h1 id="othalia-title" style={{ padding: 0 }}>
                    <strong>
                      {
                        !isLoaded ?
                          (lang === 'fr' ?
                            <span>
                              Othalia Doe-Bruce, MBA, CIPM, CED Fondatrice &amp; PDG
                            </span>
                            : <span>
                              Othalia Doe-Bruce, MBA, CIPM, CED Founder &amp; CEO
                            </span>
                          )
                          :
                          (lang === 'fr' ?
                            aboutContent.find(find => find.id === "edc993e1-eac9-4a68-ba73-9e17fd116b52").titleFr
                            : aboutContent.find(find => find.id === "edc993e1-eac9-4a68-ba73-9e17fd116b52").title
                          )
                      }
                    </strong>
                  </h1>
                  <br />
                  <div>
                    {
                      !isLoaded ?
                        (lang === 'fr' ?
                          <p>
                            <span>
                              Notre équipe se compose d'un groupe de consultants
                              experts issus de divers milieux des technologies
                              émergentes.
                              <br /> <br />
                              L'équipe est dirigée par notre fondatrice&amp; PDG,
                              Othalia Doe-Bruce.
                              <br /><br />
                              Nous avons également la chance d'avoir le soutien de
                              conseillers chevronnés et respectés qui aident à
                              orienter notre organisation dans la bonne direction!
                            </span>
                          </p>
                          : <p>
                            <span>
                              Our team consists of expert consultants from various emerging technology backgrounds.
                              <br /><br />
                              The team is led by our Founder & CEO, Othalia Doe-Bruce.
                              <br /><br />
                              We are also lucky to have the support of a seasoned and well-respected advisor, who constantly strives to steer our organization with quality and excellence!
                            </span>
                          </p>
                        )
                        :
                        (lang === 'fr' ?
                          aboutContent.find(find => find.id === "edc993e1-eac9-4a68-ba73-9e17fd116b52").contentFr
                          : aboutContent.find(find => find.id === "edc993e1-eac9-4a68-ba73-9e17fd116b52").content
                        )
                    }
                  </div>
                </Col>
              </Row>
            </Container>

            {/* Team member List. */}
            <div style={{ padding: '3em 0em 0em 0em' }}>
              <Row className='justify-content-md-center'>
                {
                  !isLoaded ?
                    (lang === 'fr'
                      ? TeamMemberListFr &&
                      TeamMemberListFr.map(list => (
                        <TeamMember
                          key={list.name}
                          name={list.name}
                          position={list.position}
                          image={list.image}
                          rowNo={4}
                        />
                      ))
                      : TeamMemberList &&
                      TeamMemberList.map(list => (
                        <TeamMember
                          key={list.name}
                          name={list.name}
                          position={list.position}
                          image={list.image}
                          rowNo={4}
                        />
                      ))
                    )
                    :
                    // Here are some instructions to add more teammates
                    // 1. Enable editing for the admin UI and add an entry with title "Team Member"
                    // 2. Content has to have the same format as the other "Team Member" entries
                    // 3. the rowNo may have to be changed to fix the styling
                    (lang === 'fr' ?
                      aboutContent.filter(data => data.title === "Team Member").map((list, index) => (
                        <TeamMember
                          key={index}
                          name={list.contentFr[0]}
                          position={list.contentFr[1]}
                          image={list.image}
                          rowNo={
                            aboutContent.filter(data => data.title === "Team Member").length > 3 ?
                              4
                              : 12 / aboutContent.filter(data => data.title === "Team Member").length
                          }
                        />
                      ))
                      : aboutContent.filter(data => data.title === "Team Member").map((list, index) => (
                        <TeamMember
                          key={index}
                          name={list.content[0]}
                          position={list.content[1]}
                          image={list.image}
                          rowNo={
                            aboutContent.filter(data => data.title === "Team Member").length > 3 ?
                              4
                              : 12 / aboutContent.filter(data => data.title === "Team Member").length
                          }
                        />
                      ))
                    )
                }
              </Row>
            </div>

            {/* Our Advisors*/}
            <Container>
              <div className='titleSection2'>
                <label>
                  {
                    !isLoaded ?
                      (lang === 'fr' ?
                        'Nos conseillers'
                        : 'Our Advisors'
                      )
                      :
                      (lang === 'fr' ?
                        aboutContent.find(find => find.id === "f3a146e2-81a7-434f-8a96-ae40013e0a4b").titleFr
                        : aboutContent.find(find => find.id === "f3a146e2-81a7-434f-8a96-ae40013e0a4b").title
                      )
                  }
                </label>
                <div>
                  {
                    !isLoaded ?
                      (lang === 'fr'
                        ? 'Apprenez-en davantage sur nos excellents conseillers, qui apportent des solutions et de la valeur à nos clients et partenaires.'
                        : 'Learn more about our excellent advisors, who bring solutions and value to our clients  and partners.'
                      )
                      :
                      (lang === 'fr'
                        ? aboutContent.find(find => find.id === "f3a146e2-81a7-434f-8a96-ae40013e0a4b").contentFr
                        : aboutContent.find(find => find.id === "f3a146e2-81a7-434f-8a96-ae40013e0a4b").content
                      )
                  }
                </div>
              </div>

              <Container className='advisorContainer employee-info'>
                <Row>
                  <Col xs={0} md={2} />
                  <Col style={{ padding: "0em 1.75em" }} xs={12} sm={6} md={4}>
                    <Row>
                      <img
                        src={!isLoaded ? "../assets/About InnovFin Assets/Rosalea Terry.png" : aboutContent.find(find => find.id === "ae998840-ac8b-45e0-a556-e4de36214bb8").image}
                        width='400px'
                        alt='Rosalea Terry'
                        className="card-img-dropshadow-heavy"
                      />
                    </Row>
                    <br />
                    <Row>
                      <h1>{!isLoaded ? "Rosalea Terry"
                        : aboutContent.find(find => find.id === "ae998840-ac8b-45e0-a556-e4de36214bb8").title}</h1>
                      {!isLoaded ?
                        <p>
                          {lang === 'fr'
                            ? "Chef d'entreprise primé,"
                            : 'Award Winning Business Leader,'}
                          <br />
                          {lang === 'fr'
                            ? 'expert en marketing et philanthrope'
                            : 'Marketing Expert and Philanthropist'}
                          <br />
                        </p>
                        : aboutContent.find(find => find.id === "ae998840-ac8b-45e0-a556-e4de36214bb8").content
                      }
                    </Row>
                  </Col>
                  <Col style={{ padding: "0em 1.75em" }} xs={12} sm={6} md={4}>
                    <Row>
                      <img
                        src={!isLoaded ? '../assets/About InnovFin Assets/John.png' : aboutContent.find(find => find.id === "671ec513-7a8a-4c23-84c5-69892cfcd996").image}
                        width='400px'
                        alt='John Gillis'
                        className="card-img-dropshadow-heavy"
                      />
                    </Row>
                    <br />
                    <Row>
                      <h1>{!isLoaded ? "John Gillis"
                        : aboutContent.find(find => find.id === "671ec513-7a8a-4c23-84c5-69892cfcd996").title}</h1>
                      {!isLoaded ?
                        <p>
                          {lang === 'fr'
                            ? 'Cleantech, développement des affaires,'
                            : 'Cleantech, Business Development,'}
                          <br />
                          {lang === 'fr'
                            ? 'entrepreneur en série multimillionnaire'
                            : 'Multi-million Serial Entrepreneur'}
                          <br />
                        </p>
                        : aboutContent.find(find => find.id === "671ec513-7a8a-4c23-84c5-69892cfcd996").content
                      }

                    </Row>
                  </Col>
                  <Col xs={0} md={2} />
                </Row>
              </Container>
            </Container>
          </Container>

          {/* Community Engagement */}
          <Container className='communityEngagement'>
            <Row className='communityEngagementRow align-items-center'>
              <Col id="text-element" className='communityEngagementCol' xs={12} md={12} lg={6}>
                <h1 className="communityEngagementLbl">
                  {
                    !isLoaded ?
                      (lang === 'fr'
                        ? 'Engagement communautaire'
                        : 'Community Engagement'
                      )
                      :
                      (lang === 'fr'
                        ? aboutContent.find(find => find.id === "c36feca3-2db4-4516-a825-5d0b21945b9c").titleFr
                        : aboutContent.find(find => find.id === "c36feca3-2db4-4516-a825-5d0b21945b9c").title
                      )
                  }
                </h1>
                <div>
                  {
                    !isLoaded ?
                      (lang === 'fr' ?
                        <p>
                          <span>
                            <i>The Innovative Financier</i> (TIF) est un centre
                            d'information à but non lucratif au carrefour de la
                            finance, de l'investissement et de l'innovation!
                            <br />
                            <br />
                            Au TIF, nous créons une communauté d'entrepreneurs
                            fintech, de développeurs, de professionnels et d'étudiants
                            visant à apprendre, à réseauter et à partager sur l'impact
                            des technologies émergentes sur le secteur de la finance
                            (y compris l'investissement). Nous prenons nos convictions
                            et nous les transformons en action.
                            <br />
                            <br />
                            TIF recherche également des bénévoles pour faire partie de
                            notre communauté innovante, et apporter plus
                            d'opportunités de réseautage pour les personnes dans le
                            monde des avancées technologiques constantes.
                            <br />
                            <br />
                            Vous pensez que vous seriez un bon élément pour la
                            communauté TIF? Consultez notre formulaire ci-dessous pour
                            plus d'informations!
                          </span>
                        </p>
                        : <p>
                          <span>
                            The Innovative Financier (TIF) is a Not-For-Profit information hub at the nexus of finance, investing, and innovation!
                            <br /><br />
                            At TIF, we are building a community of fintech entrepreneurs, developers, professionals, and students aiming to learn, network,
                            and share the impact of emerging technologies on the Finance (and Investment) industry. We take our convictions and turn them into action.
                            <br /><br />
                            TIF is also looking for volunteers to be part of our innovative community.
                            TIF is an excellent community with plenty of networking opportunities, suitable for individuals who are passionate about fintech,
                            innovation, and new technologies!
                            <br /><br />
                            Think you would be a good fit for the TIF community? See our form below for more information!
                          </span>
                        </p>
                      )
                      :
                      (lang === 'fr' ?
                        aboutContent.find(find => find.id === "c36feca3-2db4-4516-a825-5d0b21945b9c").contentFr
                        : aboutContent.find(find => find.id === "c36feca3-2db4-4516-a825-5d0b21945b9c").content
                      )
                  }
                </div>
                <br />
                <Button href="https://theinnovativefinancier.ca/" className='button' onMouseOver={() => { }}>
                  {
                    !isLoaded ?
                      (lang === "fr" ?
                        "En Savoir Plus"
                        : "Learn More"
                      ) :
                      (lang === "fr" ?
                        aboutContent.find(find => find.id === "c36feca3-2db4-4516-a825-5d0b21945b9c").buttonFr
                        : aboutContent.find(find => find.id === "c36feca3-2db4-4516-a825-5d0b21945b9c").button
                      )
                  }
                </Button>
              </Col>

              <Col id="image-element" xs={12} md={12} lg={6}>
                <img
                  className='img-center-size card-img-dropshadow-heavy2'
                  src={!isLoaded ? "../assets/About InnovFin Assets/TheInnovativeFinancier.png" : aboutContent.find(find => find.id === "c36feca3-2db4-4516-a825-5d0b21945b9c").image}
                  alt='InnovFin The Innovative Financier'
                />
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
      <div>
        <SocialMedia />
      </div>
      <PopupComponent lang={lang} location="about innovfin" />
    </div>
  )
}

export default AboutInnovFin
