import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { API, graphqlOperation } from "aws-amplify";
import { listPopups } from "../graphql/queries";
import parse from "html-react-parser";

function PopupComponent({ lang, location }) {
    //These variables are for determining if cookie popups should be shown or not
    const [show, setShow] = useState(false);
    // if we ever need to remove cookies in the future, make the hook
    // [cookies, setCookies, removeCookie] and use remove cookie
    const [cookies, setCookies] = useCookies('popup');
    const [popupData, setPopupData] = useState([])
    const [popupLoaded, setPopupLoaded] = useState(false);

    const handleShowPopup = () => setShow(true);

    //handles clicking the close button on cookie popups
    const handleClosePopup = () => {
        //API to get popups for setting the data
        API.graphql(graphqlOperation(listPopups))
            .then((data) => {
                //gets an array of data from the data that is retrieved from the api and store it in "modified data" for manipulation
                let modifiedData = data["data"]["listPopups"]["items"];
                //Filter the popup data by including location of popup and whether or not the popup is enabled or not
                modifiedData = modifiedData.filter((popup) => popup.location.toLowerCase() === location);
                modifiedData = modifiedData.filter((popup) => popup.enable === true);

                //sort by newest post that is enabled
                modifiedData.sort(function (a, b) {
                    return (
                        //format date into a number and check which post should go first
                        b.createdAt.replace(/-/g, "").replaceAll(":", "").replaceAll("T", "").substr(0, 15) -
                        a.createdAt.replace(/-/g, "").replaceAll(":", "").replaceAll("T", "").substr(0, 15)
                    );
                });
                // sets cookie with value of the popup's title to check when featured post is updated
                // max age: 604800 is one week in seconds
                if (lang === "fr") {
                    setCookies('popup', (modifiedData[0]).titleFr, { sameSite: 'Strict', maxAge: 604800 });
                } else {
                    setCookies('popup', (modifiedData[0]).title, { sameSite: 'Strict', maxAge: 604800 });
                }
                // console.log(popupData)
                setShow(false)
            }).catch((err) => {
                console.log(err);
            });

    };

    //function used to check if the popup needs to be shown or not
    const checkPopup = () => {
        API.graphql(graphqlOperation(listPopups))
            .then((data) => {
                let modifiedData2 = data["data"]["listPopups"]["items"];
                modifiedData2.map((item) => {
                    item.content = parse(item.content);
                    item.contentFr = parse(item.contentFr);
                    return item;
                });
                // modifiedData2 = modifiedData2.filter((popup) => popup.location.includes("Home"))
                modifiedData2 = modifiedData2.filter((popup) => popup.location.toLowerCase() === location)
                modifiedData2 = modifiedData2.filter((popup) => popup.enable === true)

                //sort by newest post that is enabled
                modifiedData2.sort(function (a, b) {
                    return (
                        //format date into a number and check which post should go first
                        b.createdAt.replace(/-/g, "").replaceAll(":", "").replaceAll("T", "").substr(0, 15) -
                        a.createdAt.replace(/-/g, "").replaceAll(":", "").replaceAll("T", "").substr(0, 15)
                    );
                });
                // checks pop up cookie value to see if it matches the latest popup that has been enabled
                // if not, set the popup data that will be shown
                if (lang === "fr") {
                    if (cookies.popup !== modifiedData2[0].titleFr) {
                        setPopupData(modifiedData2);
                        setPopupLoaded(true);
                        handleShowPopup();
                    } else {
                        console.log("Featured Blog not shown because cookie is set");
                    }
                } else {
                    if (cookies.popup !== modifiedData2[0].title) {
                        setPopupData(modifiedData2);
                        setPopupLoaded(true);
                        handleShowPopup();
                    } else {
                        console.log("Featured Blog not shown because cookie is set");
                    }
                }
            }).catch((err) => {
                //if no posts are available, just return a message
                if (err.toString().includes("undefined")) {
                    console.log("No popup data available");
                } else {
                    console.log(err);
                }
            });
    };

    useEffect(() => {
        checkPopup();
    }, [])


    return (
        <Modal
            animation={false}
            // size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            dialogClassName="popup-dialog-size">
            <Modal.Header className="popup-innovEdu-header">
                {/* <Modal.Title className="popup-header">{popupData[0]}</Modal.Title> */}
                <Modal.Title className="popup-header">
                    {!popupLoaded ? (
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    ) : (
                        lang === "fr" ?
                            // popupData.find(find => find.id === "a1d958f0-87e7-46ca-8438-99c47e59e31f").titleFr
                            popupData[0].titleFr
                            : popupData[0].title
                        // popupData.find(find => find.id === "a1d958f0-87e7-46ca-8438-99c47e59e31f").title
                    )}
                </Modal.Title>
                <button className="btn-link" onClick={handleClosePopup}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="black" className="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </button>

            </Modal.Header>
            <Modal.Body className="popup-innovEdu-body">
                <Row className="align-items-center">
                    <Col md={7} sm={12}>
                        <div style={{ whiteSpace: "pre-line" }}>
                            {!popupLoaded ? (
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            ) : (
                                lang === "fr" ?
                                    // popupData.find(find => find.id === "a1d958f0-87e7-46ca-8438-99c47e59e31f").contentFr
                                    popupData[0].contentFr
                                    : popupData[0].content
                                // popupData.find(find => find.id === "a1d958f0-87e7-46ca-8438-99c47e59e31f").content
                            )}
                            <br />
                            {!popupLoaded ? (
                                <Button href="" style={{ marginTop: "1em" }} className="btn-2">
                                    Waiting on data to load...
                                </Button>
                            ) : (
                                <Button
                                    href={lang === "fr" ?
                                        // popupData.find(find => find.id === "a1d958f0-87e7-46ca-8438-99c47e59e31f").linkFr
                                        popupData[0].linkFr
                                        : popupData[0].link
                                        // popupData.find(find => find.id === "a1d958f0-87e7-46ca-8438-99c47e59e31f").link
                                    }
                                    style={{ marginTop: "1em" }} className="btn-2"
                                >
                                    {lang === "fr" ?
                                        // popupData.find(find => find.id === "a1d958f0-87e7-46ca-8438-99c47e59e31f").buttonFr
                                        popupData[0].buttonFr
                                        : popupData[0].button
                                        // popupData.find(find => find.id === "a1d958f0-87e7-46ca-8438-99c47e59e31f").button
                                    }
                                </Button>
                            )}
                        </div>
                    </Col>
                    <Col md={5} sm={6}>
                        <img
                            className='img-center-size'
                            src={!popupLoaded ? (
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            ) : (
                                // popupData.find(find => find.id === "a1d958f0-87e7-46ca-8438-99c47e59e31f").image
                                popupData[0].image
                            )}
                            alt="Featured Blog"
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="popup-innovEdu-footer">
            </Modal.Footer>
        </Modal>
    )

}
export default PopupComponent;