import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import RegisterConfirmSchema from './Schema/RegisterConfirmSchema';
import RegisterConfirmSchemaFr from './Schema/RegisterConfirmSchemaFr';
import Auth from '@aws-amplify/auth';

function RegisterConfirm({ lang }) {
    const storedForm = JSON.parse(localStorage.getItem('formState'))
    const [formState, setFormState] = useState(storedForm)

    useEffect(() => {
        localStorage.setItem("formState", JSON.stringify(formState))
    }, [formState])

    async function confirmSignUp(data) {
        try {
            const { username, authCode } = data
            await Auth.confirmSignUp(username, authCode)
            setFormState(() => ({ ...formState, formType: "signIn" }))
            window.location.reload();
        } catch (e) {
            alert("There was an error, please check your username and confirmation code and try again!")
        }

    }
    return (
        <Container>
            <div className="infoContainer" >
                <React.Fragment>
                    <h1>{lang === 'fr' ? 'Confirmez votre compte' : 'Confirm your account'}</h1>
                    <p style={{ padding: '0' }}>
                        {lang === 'fr' ? <span> Confirmez votre compte!<br />Vérifiez votre courriel pour voir si vous avez reçu un courriel de confirmation. </span> :
                            <span>Confirm Your Account!<br />Check your email to see if you have recieved a confirmation email.</span>}
                    </p>
                </React.Fragment>
                <Container className="registerContainerWithBorder" >
                    <Formik
                        validationSchema={lang === 'fr' ? RegisterConfirmSchemaFr : RegisterConfirmSchema}
                        onSubmit={(data, { setSubmitting }) => {
                            setSubmitting(true);
                            confirmSignUp(data);
                            setSubmitting(false);
                        }}
                        initialValues={{
                            username: '',
                            authCode: '',
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            isSubmitting,
                            values,
                            errors,
                            touched,
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <Row className="mb-6">
                                    {/*    UserName  */}
                                    <Form.Group as={Col} md="6">
                                        {lang === 'fr' ? <Form.Text>Nom d'utilisateur</Form.Text> : <Form.Text>Username</Form.Text>}
                                        <Form.Control type="text" name="username" value={values.username} onChange={handleChange} isValid={touched.username && !errors.username} isInvalid={!!errors.username} id="userName" />
                                        <Form.Control.Feedback type="invalid"> {errors.username} </Form.Control.Feedback>
                                    </Form.Group>

                                    {/*  Confirmation Code  */}
                                    <Form.Group as={Col} md="6">
                                        {lang === 'fr' ? <Form.Text>Code de confirmation</Form.Text> : <Form.Text>Confirmation Code</Form.Text>}
                                        <Form.Control type="text" name="authCode" value={values.authCode} onChange={handleChange} isValid={touched.authCode && !errors.authCode} isInvalid={!!errors.authCode} id="authCode" />
                                        <Form.Control.Feedback type="invalid"> {errors.authCode} </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Button disabled={isSubmitting} className="loginBtn" type="submit" >{lang === 'fr' ? 'Confirmer' : 'Confirm'}</Button>
                                <Col xs={12} sm={6} md={6} ><Form.Text className="text-muted">{lang === 'fr' ? 'Retournez à la page d\'inscription.' : 'Go back to the sign in page.'}<br /></Form.Text>
                                    <button className="btn-link signUpLbl" onClick={() => {
                                        setFormState(() => ({ ...formState, formType: "signIn" }));
                                        window.location.reload();
                                    }
                                    } >{lang === 'fr' ? 'Connexion' : 'Login'}</button>
                                </Col>
                            </Form>
                        )}
                    </Formik>

                </Container>
            </div>
        </Container>
    )
}

export default RegisterConfirm;