import * as Yup from 'yup';

//Yup validation schema
const RegisterSchemaFr = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address format (Format de courriel invalide)")
    .required("Email is required (Le courriel est requis)"),
  firstname: Yup.string()
    .min(2, 'Le prénom est trop court')
    .max(150, 'Le prénom est trop long')
    .required('Le prénom est requis'),
  lastname: Yup.string()
    .min(2, 'Le nom est trop court')
    .max(150, 'Le nom est trop long')
    .required('Le nom est requis'),
  password: Yup.string()
    .min(8, "Le mot de passe doit comporter 8 caractères minimum")
    .required("Le mot de passe est requis"),
  confirmPassword: Yup.string()
    .required('La confirmation du mot de passe est requise')
    .oneOf([Yup.ref('password'), null], 'Les mots de passe doivent être identiques'),
  username: Yup.string()
    .required('Le nom d\'utilisateur est requis'),
})

export default RegisterSchemaFr;