import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import Auth from '@aws-amplify/auth';
import ForgotPasswordConfirmSchema from './Schema/ForgotPasswordConfirmSchema';
import ForgotPasswordConfirmSchemaFr from './Schema/ForgotPasswordConfirmSchemaFr';

function ForgotPassword({ lang }) {
    const storedForm = JSON.parse(localStorage.getItem('formState'))
    const [formState, setFormState] = useState(storedForm)

    useEffect(() => {
        localStorage.setItem("formState", JSON.stringify(formState))
    }, [formState])

    // function onChange(e) {
    //     e.persist()
    //     setFormState(() => ({ ...formState, [e.target.name]: e.target.value }))
    // }
    async function resetPass(data) {
        try {
            const { username, code, password } = data
            await Auth.forgotPasswordSubmit(username, code, password)
                .catch(err => console.log(err));
            setFormState(() => ({ ...formState, formType: "signIn" }))
            window.location.reload();
        } catch (e) {
            console.log(e)
        }

    }
    return (
        <Container>
            <div className="infoContainer" >
                <React.Fragment>
                    <h1>{lang === 'fr' ? 'Réinitialiser votre mot de passe' : 'Reset your password'}</h1>
                    <p style={{ padding: '0' }}>
                        {lang === 'fr' ? <span> Vous avez oublié votre mot de passe ? <br />Réinitialisez votre mot de passe avec le code qui vous a été envoyé par courriel. </span> :
                            <span>Forget your password?<br />Reset your password with the code that was sent to your email.</span>}
                    </p>
                </React.Fragment>
                <Container className="registerContainerWithBorder" >
                    <Formik
                        validationSchema={lang === 'fr' ? ForgotPasswordConfirmSchemaFr : ForgotPasswordConfirmSchema}
                        onSubmit={(data, { setSubmitting }) => {
                            setSubmitting(true);
                            resetPass(data);
                            setSubmitting(false);
                        }}
                        initialValues={{
                            username: '',
                            code: '',
                            password: '',
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            isSubmitting,
                            values,
                            errors,
                            touched,
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <Row className="mb-6">
                                    {/*    UserName  */}
                                    <Form.Group as={Col} md="6">
                                        <Form.Text>
                                            {lang === 'fr' ? 'Nom d\'utilisateur' : 'Username'}
                                        </Form.Text>
                                        <Form.Control type="text" name="username" value={values.username} onChange={handleChange} isValid={touched.username && !errors.username} isInvalid={!!errors.username} id="userName" />
                                        <Form.Control.Feedback type="invalid"> {errors.username} </Form.Control.Feedback>
                                    </Form.Group>

                                    {/*    Code  */}
                                    <Form.Group as={Col} md="6">
                                        <Form.Text>{lang === 'fr' ? 'Code de confirmation' : 'Confirmation Code'}</Form.Text>
                                        <Form.Control type="text" name="code" value={values.code} onChange={handleChange} isValid={touched.code && !errors.code} isInvalid={!!errors.code} id="code" />
                                        <Form.Control.Feedback type="invalid"> {errors.code} </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row>
                                    {/*    Password  */}
                                    <Form.Group as={Col} md="6">
                                        <Form.Text>{lang === 'fr' ? 'Mot de passe' : 'Password'}</Form.Text>
                                        <Form.Control type="password" name="password" value={values.password} onChange={handleChange} isValid={touched.password && !errors.password} isInvalid={!!errors.password} id="password" />
                                        <Form.Control.Feedback type="invalid"> {errors.password} </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Button disabled={isSubmitting} className="loginBtn" type="submit" style={{ margin: '12px 0 12px 0' }} >{lang === 'fr' ? 'Réinitialiser le mot de passe' : 'Reset Password'}</Button>
                                <Col xs={12} sm={6} md={6} >
                                    <Form.Text className="text-muted">
                                        {lang === 'fr' ? 'Retournez à la page d\'inscription.' : 'Go back to the sign in page.'}<br />
                                    </Form.Text>
                                    <button onClick={() => {
                                        setFormState(() => ({ ...formState, formType: "signIn" }));
                                        window.location.reload();
                                    }
                                    } className="btn-link signUpLbl">
                                        {lang === 'fr' ? 'Connexion' : 'Login'}
                                    </button>
                                </Col>
                            </Form>
                        )}
                    </Formik>

                </Container>
            </div>
        </Container>
    )
}

export default ForgotPassword;