import * as Yup from 'yup';

const ForgotPasswordConfirmSchemaFr = Yup.object().shape({
    code: Yup.string()
        .required("Le code de confirmation est requis"),
    password: Yup.string()
        .min(8, "Le mot de passe doit comporter 8 caractères minimum")
        .required("Le mot de passe est requis"),
    username: Yup.string()
        .required('Le nom d\'utilisateur est requis'),
})

export default ForgotPasswordConfirmSchemaFr;