import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Card, CardGroup } from 'react-bootstrap';
// import MatCard from '@mui/material/Card'
// import MatCardActionArea from '@mui/material/CardActionArea'
// import MatCardActions from '@mui/material/CardActions'
// import MatCardContent from '@mui/material/CardContent'
// import MatCardMedia from '@mui/material/CardMedia'
// import { Grid } from '@material-ui/core'
import '../styling/SocialMedia.css'
import { Timeline } from 'react-twitter-widgets';
import { LinkedinCompanyProfile, LinkedinProfile } from 'react-social-plugins';

function SocialMedia({ lang }) {
    // const imgLocation = '../assets/Social Feed/';
    const [videos, setVideos] = useState([]);
    const chanId = 'UCY7zGRalYuGaAykCFXIyObw';
    const baseUrl = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.youtube.com%2Ffeeds%2Fvideos.xml%3Fchannel_id%3D';

    useEffect(() => {
        const scriptFB = document.createElement('script');
        const scriptLI = document.createElement('script');
        scriptFB.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v12.0';
        scriptLI.src = "https://widget.taggbox.com/embed.min.js";
        scriptLI.type = "text/javascript";
        scriptFB.nonce = 'TrsQ8cFd';
        scriptFB.crossorigin = "anonymous";
        scriptFB.async = true;
        document.body.appendChild(scriptFB);
        document.body.appendChild(scriptLI);
        (async () => {
            if (chanId) {
                try {
                    const data = await fetch(`${baseUrl}${chanId}`).then(response => response.json());
                    setVideos(data.items);
                } catch (e) {
                    console.log("error", e);
                }
            }
        })();
        return () => {
            document.body.removeChild(scriptFB);
            document.body.removeChild(scriptLI);
        }
    }, []);

    return (

        <div className="socialMedia">
            <Container>
                <h1 id="keepConnected">{lang === 'fr' ? 'Connectez-vous avec nous ci-dessous' : 'Keep Connected With Us Below'}</h1>
                {/* moved buttons to footer */}
                {/* <div className="btn-social">
                    <a href='https://www.linkedin.com/company/innovfin'><img src='../assets/Social Icons/LinkedIn.png' alt='' /></a>
                    <a href='https://www.youtube.com/channel/UCY7zGRalYuGaAykCFXIyObw'><img src='../assets/Social Icons/YouTube.png' alt='' /></a>
                    <a href='https://www.facebook.com/InnovFinConsulting/'><img src='../assets/Social Icons/Facebook.png' alt='' /></a>
                    <a href='https://twitter.com/InnovFinI'><img src='../assets/Social Icons/Twitter.png' alt='' /></a>
                    <a href='https://join.slack.com/t/innovfinedtech/shared_invite/zt-u70pg874-JXRdvazWBrNWikoo5k1t4Q'><img src='../assets/Social Icons/Slack.png' alt='' /></a>
                </div> */}
            </Container>
            <CardGroup>
                {
                    videos.slice(0, 2).map((video, index) => (
                        <Card className="infoCard" key={video.title}>
                            <a href={video.link}>
                            <Card.Img variant="top" src={`https://i4.ytimg.com/vi/${video.guid.split(':')[2]}/mqdefault.jpg`} id="video-img" />
                            </a>
                            <Card.Body style={{
                            }}>
                                <Row className="align-items-center" style={{padding:"1em"}}>
                                    <Col md={12} lg={8}>
                                        <span>
                                            <p style={{
                                                marginTop: '0',
                                                float: 'left',
                                                width: '100%',
                                                lineHeight: '20px'
                                            }}>
                                                {lang === 'fr' ? 'Regardez cette vidéo' : 'Check out this video:'}
                                                <br />
                                                {video.title}
                                                <br />
                                                <a href={video.link}>
                                                    {lang === 'fr' ? 'Regardez cette vidéo sur YouTube' : 'Watch this video on YouTube'}
                                                </a>
                                            </p>
                                        </span>
                                    </Col>
                                    <Col md={12} lg={4}>
                                        <a href={video.link}>
                                            <img
                                                src='/assets/Social Icons/YouTube.png'
                                                height='auto'
                                                alt='InnovFin Youtube'
                                                style={{ padding: '0' }}
                                            />
                                        </a>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    ))
                }
            </CardGroup>
            <CardGroup>
                <Card className="infoCard" key="twitter">
                    <Card.Body>
                        <Timeline
                            dataSource={{ sourceType: "profile", screenName: "InnovFinI" }}
                            options={{ chrome: "noheader, nofooter", width: "400", height: "auto" }}
                        />
                    </Card.Body>
                    <Card.Body>
                        <Row className="align-items-center">
                            <Col md={12} lg={8}>
                                <span>
                                    <p style={{
                                        marginTop: '0',
                                        float: 'left',
                                        width: '100%',
                                        lineHeight: '20px'
                                    }}>
                                        {lang === 'fr' ?
                                            'Faites défiler le widget pour voir nos derniers messages Twitter, ou cliquez sur le lien ci-dessous pour accéder à notre page Twitter et voir ce que nous avons fait!'
                                            : 'Scroll through the widget to take a look at our latest Twitter posts, or click the link below to go to our Twitter feed to see what we have been up to!'
                                        }
                                        <br /><a href="https://twitter.com/InnovFinI">{lang === 'fr' ? 'Accéder à notre Twitter' : 'Go to our Twitter'}</a>
                                    </p>
                                </span>
                            </Col>
                            <Col md={12} lg={4}>
                                <a href='https://twitter.com/InnovFinI'>
                                    <img
                                        src='/assets/Social Icons/Twitter.png'
                                        height='auto'
                                        alt='InnovFin Twitter'
                                        style={{ padding: '0' }}
                                    />
                                </a>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                {/* <Card className="infoCard" key="linkedin">
                    <Card.Body>
                        
                    </Card.Body>
                    <Card.Body>
                        <Row className="align-items-center">
                            <Col md={12} lg={8}>
                                <span>
                                    <p style={{
                                        marginTop: '0',
                                        float: 'left',
                                        width: '100%',
                                        lineHeight: '20px'
                                    }}>
                                        {lang === 'fr' ?
                                            'Faites défiler le widget pour voir nos derniers messages LinkedIn, ou cliquez sur le lien ci-dessous pour accéder à notre page LinkedIn et voir ce que nous avons fait!'
                                            : 'Scroll through the widget to take a look at our latest LinkedIn posts, or click the link below to go to our LinkedIn feed to see what we have been up to!'
                                        }
                                        <br /><a href="https://www.linkedin.com/company/innovfin/">{lang === 'fr' ? 'Accéder à notre LinkedIn' : 'Go to our LinkedIn'}</a>
                                    </p>
                                </span>
                            </Col>
                            <Col md={12} lg={4}>
                                <a href='https://www.linkedin.com/company/innovfin/'>
                                    <img
                                        src='../assets/Social Icons/LinkedIn.png'
                                        height='auto'
                                        alt='InnovFin LinkedIn'
                                        style={{ padding: '0' }}
                                    />
                                </a>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card> */}
                <Card className="infoCard" key="facebook">
                    <Card.Body>
                        <div
                            className="fb-page"
                            data-href="https://www.facebook.com/InnovFinConsulting"
                            data-tabs="timeline"
                            data-width="376px"
                            data-height="220px"
                            data-small-header="true"
                            data-adapt-container-width="true"
                            data-hide-cover="true"
                            data-show-facepile="false"
                        >
                            <blockquote cite="https://www.facebook.com/InnovFinConsulting" className="fb-xfbml-parse-ignore">
                                <a href="https://www.facebook.com/InnovFinConsulting">InnovFin Consulting Inc.</a>
                            </blockquote>
                        </div>
                    </Card.Body>
                    <Card.Body>
                        <Row className="align-items-center">
                            <Col md={12} lg={8}>
                                <span>
                                    <p style={{
                                        marginTop: '0',
                                        float: 'left',
                                        width: '100%',
                                        lineHeight: '20px'
                                    }}>
                                        {lang === 'fr' ?
                                            'Faites défiler le widget pour jeter un coup d\'œil à notre page Facebook, ou cliquez sur le lien ci-dessous pour visiter notre Facebook et apprendre à nous connaître!'
                                            : 'Scroll through the widget to take a look at our Facebook page, or click the link below to visit our Facebook and learn about us!'
                                        }
                                        <br /><a href="https://www.facebook.com/InnovFinConsulting/">{lang === 'fr' ? 'Accédez à notre page Facebook' : 'Go to our Facebook'}</a>
                                    </p>
                                </span>
                            </Col>
                            <Col md={12} lg={4}>
                                <a href='https://www.facebook.com/InnovFinConsulting/'>
                                    <img
                                        src='/assets/Social Icons/Facebook.png'
                                        height='auto'
                                        alt='InnovFin Facebook'
                                        style={{ padding: '0' }}
                                    />
                                </a>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </CardGroup>
        </div>
    )
}

export default SocialMedia;