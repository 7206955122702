import * as Yup from 'yup';

const LoginSchemaFr = Yup.object().shape({
  username: Yup.string()
    .required("Le nom d'utilisateur est requis"),
    password: Yup.string()
    .min(5, "Le mot de passe doit comporter 5 caractères minimum")
    .required("Le mot de passe est requis")
})

export default LoginSchemaFr;