import React from 'react';
import { Card, Col } from 'react-bootstrap';

function TeamMember(props) {
    const employeePerRow = 12/props.rowNo;
    return (
        <Col xs={12} sm={12} md={4} key={props.name}>
            <Card className="employee-info">
                <Card.Img variant="top" src={props.image} className="card-img-dropshadow" />
                <div>
                    <h1>{props.name}</h1>
                    {/* <Card.Subtitle style={{color:"black"}} className="mb-2 text-muted">{props.position}</Card.Subtitle> */}
                    <p>{props.position}</p>
                </div>
            </Card>
        </Col>
    )
}

export default TeamMember;