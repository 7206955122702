import * as Yup from 'yup';

//Yup validation schema
const RegisterSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address format")
    .required("Email is required"),
  firstname: Yup.string()
    .min(2, 'First Name is too short')
    .max(150, 'First Name is too long')
    .required('First Name is required'),
  lastname: Yup.string()
    .min(2, 'Last Name is too short')
    .max(150, 'Last Name is too long')
    .required('Last Name is required'),
  password: Yup.string()
    .min(8, "Password must be 8 characters minimum")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .required('Password confirm is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  username: Yup.string()
    .required('Username is required'),
})

export default RegisterSchema;