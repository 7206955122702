/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      slug
      image
      video
      title
      content
      author
      categories
      featured
      lang
      postLink
      sources
      createdAt
      updatedAt
      status
      publish_at
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      slug
      image
      video
      title
      content
      author
      categories
      featured
      lang
      postLink
      sources
      createdAt
      updatedAt
      status
      publish_at
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      slug
      image
      video
      title
      content
      author
      categories
      featured
      lang
      postLink
      sources
      createdAt
      updatedAt
      status
      publish_at
    }
  }
`;

export const createVideoEmbed = /* GraphQL */ `
  mutation CreateVideoEmbed(
    $input: CreateVideoEmbedInput!
    $condition: ModelVideoEmbedConditionInput
  ) {
    createVideoEmbed(input: $input, condition: $condition) {
      id
      pageLocation
      url
      thumbnail
    }
  }
`;
export const updateVideoEmbed = /* GraphQL */ `
  mutation UpdateVideoEmbed(
    $input: UpdateVideoEmbedInput!
    $condition: ModelVideoEmbedConditionInput
  ) {
    updateVideoEmbed(input: $input, condition: $condition) {
      id
      pageLocation
      url
      thumbnail
    }
  }
`;
export const deleteVideoEmbed = /* GraphQL */ `
  mutation DeleteVideoEmbed(
    $input: DeleteVideoEmbedInput!
    $condition: ModelVideoEmbedConditionInput
  ) {
    deleteVideoEmbed(input: $input, condition: $condition) {
      id
      pageLocation
      url
      thumbnail
    }
  }
`;


export const createFAQpost = /* GraphQL */ `
  mutation CreateFAQpost(
    $input: CreateFAQpostInput!
    $condition: ModelFAQpostConditionInput
  ) {
    createFAQpost(input: $input, condition: $condition) {
      id
      question
      questionFr
      answer
      answerFr
    }
  }
`;
export const updateFAQpost = /* GraphQL */ `
  mutation UpdateFAQpost(
    $input: UpdateFAQpostInput!
    $condition: ModelFAQpostConditionInput
  ) {
    updateFAQpost(input: $input, condition: $condition) {
      id
      question
      questionFr
      answer
      answerFr
    }
  }
`;
export const deleteFAQpost = /* GraphQL */ `
  mutation DeleteFAQpost(
    $input: DeleteFAQpostInput!
    $condition: ModelFAQpostConditionInput
  ) {
    deleteFAQpost(input: $input, condition: $condition) {
      id
      question
      questionFr
      answer
      answerFr
    }
  }
`;

export const createFAQpostfr = /* GraphQL */ `
  mutation CreateFAQpostfr(
    $input: CreateFAQpostfrInput!
    $condition: ModelFAQpostfrConditionInput
  ) {
    createFAQpostfr(input: $input, condition: $condition) {
      id
      question
      answer
    }
  }
`;
export const updateFAQpostfr = /* GraphQL */ `
  mutation UpdateFAQpostfr(
    $input: UpdateFAQpostfrInput!
    $condition: ModelFAQpostfrConditionInput
  ) {
    updateFAQpostfr(input: $input, condition: $condition) {
      id
      question
      answer
    }
  }
`;
export const deleteFAQpostfr = /* GraphQL */ `
  mutation DeleteFAQpostfr(
    $input: DeleteFAQpostfrInput!
    $condition: ModelFAQpostfrConditionInput
  ) {
    deleteFAQpostfr(input: $input, condition: $condition) {
      id
      question
      answer
    }
  }
`;

export const createHome = /* GraphQL */ `
  mutation CreateHome(
    $input: CreateHomeInput!
    $condition: ModelHomeConditionInput
  ) {
    createHome(input: $input, condition: $condition) {
      id
      image
      title
      content
      button
      titleFr
      contentFr
      buttonFr
    }
  }
`;
export const updateHome = /* GraphQL */ `
  mutation UpdateHome(
    $input: UpdateHomeInput!
    $condition: ModelHomeConditionInput
  ) {
    updateHome(input: $input, condition: $condition) {
      id
      image
      title
      content
      button
      titleFr
      contentFr
      buttonFr
    }
  }
`;
export const deleteHome = /* GraphQL */ `
  mutation DeleteHome(
    $input: DeleteHomeInput!
    $condition: ModelHomeConditionInput
  ) {
    deleteHome(input: $input, condition: $condition) {
      id
      image
      title
      content
      button
      titleFr
      contentFr
      buttonFr
    }
  }
`;

export const createService = /* GraphQL */ `
  mutation CreateService(
    $input: CreateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    createService(input: $input, condition: $condition) {
      id
      image
      title
      content
      button
      titleFr
      contentFr
      buttonFr
      link
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService(
    $input: UpdateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    updateService(input: $input, condition: $condition) {
      id
      image
      title
      content
      button
      titleFr
      contentFr
      buttonFr
      link
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService(
    $input: DeleteServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    deleteService(input: $input, condition: $condition) {
      id
      image
      title
      content
      button
      titleFr
      contentFr
      buttonFr
      link
    }
  }
`;


export const createAboutOthaliaContent = /* GraphQL */ `
  mutation CreateAboutOthaliaContent(
    $input: CreateAboutOthaliaContentInput!
    $condition: ModelAboutOthaliaContentConditionInput
  ) {
    createAboutOthaliaContent(input: $input, condition: $condition) {
      id
      image
      title
      content
      button
      titleFr
      contentFr
      buttonFr
    }
  }
`;
export const updateAboutOthaliaContent = /* GraphQL */ `
  mutation UpdateAboutOthaliaContent(
    $input: UpdateAboutOthaliaContentInput!
    $condition: ModelAboutOthaliaContentConditionInput
  ) {
    updateAboutOthaliaContent(input: $input, condition: $condition) {
      id
      image
      title
      content
      button
      titleFr
      contentFr
      buttonFr
    }
  }
`;
export const deleteAboutOthaliaContent = /* GraphQL */ `
  mutation DeleteAboutOthaliaContent(
    $input: DeleteAboutOthaliaContentInput!
    $condition: ModelAboutOthaliaContentConditionInput
  ) {
    deleteAboutOthaliaContent(input: $input, condition: $condition) {
      id
      image
      title
      content
      button
      titleFr
      contentFr
      buttonFr
    }
  }
`;

export const createAboutContent = /* GraphQL */ `
  mutation CreateAboutContent(
    $input: CreateAboutContentInput!
    $condition: ModelAboutContentConditionInput
  ) {
    createAboutContent(input: $input, condition: $condition) {
      id
      image
      title
      content
      button
      titleFr
      contentFr
      buttonFr
    }
  }
`;
export const updateAboutContent = /* GraphQL */ `
  mutation UpdateAboutContent(
    $input: UpdateAboutContentInput!
    $condition: ModelAboutContentConditionInput
  ) {
    updateAboutContent(input: $input, condition: $condition) {
      id
      image
      title
      content
      button
      titleFr
      contentFr
      buttonFr
    }
  }
`;
export const deleteAboutContent = /* GraphQL */ `
  mutation DeleteAboutContent(
    $input: DeleteAboutContentInput!
    $condition: ModelAboutContentConditionInput
  ) {
    deleteAboutContent(input: $input, condition: $condition) {
      id
      image
      title
      content
      button
      titleFr
      contentFr
      buttonFr
    }
  }
`;

export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      type
      title
      content
      titleFr
      contentFr
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      type
      title
      content
      titleFr
      contentFr
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      type
      title
      content
      titleFr
      contentFr
    }
  }
`;

export const createAuthor = /* GraphQL */ `
  mutation CreateAuthor(
    $input: CreateAuthorInput!
    $condition: ModelAuthorConditionInput
  ) {
    createAuthor(input: $input, condition: $condition) {
      id
      image
      author
      description
    }
  }
`;
export const updateAuthor = /* GraphQL */ `
  mutation UpdateAuthor(
    $input: UpdateAuthorInput!
    $condition: ModelAuthorConditionInput
  ) {
    updateAuthor(input: $input, condition: $condition) {
      id
      image
      author
      description
    }
  }
`;
export const deleteAuthor = /* GraphQL */ `
  mutation DeleteAuthor(
    $input: DeleteAuthorInput!
    $condition: ModelAuthorConditionInput
  ) {
    deleteAuthor(input: $input, condition: $condition) {
      id
      image
      author
      description
    }
  }
`;

export const createIconImage = /* GraphQL */ `
  mutation CreateIconImage(
    $input: CreateIconImageInput!
    $condition: ModelIconImageConditionInput
  ) {
    createIconImage(input: $input, condition: $condition) {
      id
      image
      title
      link
    }
  }
`;
export const updateIconImage = /* GraphQL */ `
  mutation UpdateIconImage(
    $input: UpdateIconImageInput!
    $condition: ModelIconImageConditionInput
  ) {
    updateIconImage(input: $input, condition: $condition) {
      id
      image
      title
      link
    }
  }
`;
export const deleteIconImage = /* GraphQL */ `
  mutation DeleteIconImage(
    $input: DeleteIconImageInput!
    $condition: ModelIconImageConditionInput
  ) {
    deleteIconImage(input: $input, condition: $condition) {
      id
      image
      title
      link
    }
  }
`;

export const createPopup = /* GraphQL */ `
  mutation CreatePopup(
    $input: CreatePopupInput!
    $condition: ModelPopupConditionInput
  ) {
    createPopup(input: $input, condition: $condition) {
      id
      image
      location
      title
      content
      button
      link
      titleFr
      contentFr
      buttonFr
      linkFr
      enable
    }
  }
`;
export const updatePopup = /* GraphQL */ `
  mutation UpdatePopup(
    $input: UpdatePopupInput!
    $condition: ModelPopupConditionInput
  ) {
    updatePopup(input: $input, condition: $condition) {
      id
      image
      location
      title
      content
      button
      link
      titleFr
      contentFr
      buttonFr
      linkFr
      enable
    }
  }
`;
export const deletePopup = /* GraphQL */ `
  mutation DeletePopup(
    $input: DeletePopupInput!
    $condition: ModelPopupConditionInput
  ) {
    deletePopup(input: $input, condition: $condition) {
      id
      image
      location
      title
      content
      button
      link
      titleFr
      contentFr
      buttonFr
      linkFr
      enable
    }
  }
`;

export const createTerm = /* GraphQL */ `
  mutation CreateTerm(
    $input: CreateTermInput!
    $condition: ModelTermConditionInput
  ) {
    createTerm(input: $input, condition: $condition) {
      id
      title
      content
      titleFr
      contentFr
    }
  }
`;
export const updateTerm = /* GraphQL */ `
  mutation UpdateTerm(
    $input: UpdateTermInput!
    $condition: ModelTermConditionInput
  ) {
    updateTerm(input: $input, condition: $condition) {
      id
      title
      content
      titleFr
      contentFr
    }
  }
`;
export const deleteTerm = /* GraphQL */ `
  mutation DeleteTerm(
    $input: DeleteTermInput!
    $condition: ModelTermConditionInput
  ) {
    deleteTerm(input: $input, condition: $condition) {
      id
      title
      content
      titleFr
      contentFr
    }
  }
`;