import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import ContactSchema from './ContactSchema';
import ContactSchemaFr from './ContactSchemaFr';
import metaData from "./json_files/metadata.json";
import HelmetMetaData from './components/HelmetMetaData';
import InquiryForm from './components/InquiryForm';
import ContactText from './json_files/ContactText.json'
import ContactTextFr from './json_files/ContactTextFr.json'
import { input } from '@aws-amplify/ui';
import { listContacts } from "./graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import PopupComponent from './components/PopupComponent';
// Email sending methods located in mailses.js
const aws = require('./mailses')

function Contact() {
  const { lang } = useParams();
  const [defaultTxt, setDefaultTxt] = useState("");
  const [initialVals, setInitialVals] = useState({
    name: '',
    email: '',
    phonenumber: '',
    inputDrop: '',
    inputText: "",
    notes: ""
  });
  const [onSuccess, setOnSuccess] = useState(false);

  // For the meta data stuff
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState("");
  // Get location metadata from JSON file, this system maybe used in the future so that
  // non-developers can edit metadata.
  const getMeta = (data) => {
    if (data.url === window.location.pathname.split("/")[2]) {
      setTitle(data.title);
      setDescription(data.description);
      setKeywords(data.keywords);
    }
  };

  //the below is used for storing the content that will be grabbed from the API
  //is loaded is to check if the api is loaded
  const [contactContent, setContactContent] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const setContentAPI = () => {
    API.graphql(graphqlOperation(listContacts))
      .then((data) => {
        const modifiedData2 = data["data"]["listContacts"]["items"];

        modifiedData2.map((item) => {
          item.content = parse(item.content);
          item.contentFr = parse(item.contentFr);
          return item;
        });

        setContactContent(modifiedData2);
        setIsLoaded(true);
        // console.log("content about: ", contactContent);
        // console.log("content: ", contactContent.filter(data => data.type === "[Question]"));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    setContentAPI();
    metaData.forEach(data => {
      getMeta(data);
    });
  }, []);

  return (
    <Container>
      <HelmetMetaData
        title={title}
        description={description}
        keywords={keywords}>
      </HelmetMetaData>
      <div className='infoContainer'>
        <h1 className="contactHead">
          {
            !isLoaded ?
              (lang === 'fr' ?
                'Contactez-nous'
                : 'Contact Us'
              )
              :
              (lang === 'fr' ?
                contactContent.find(find => find.id === "ec8ac303-8900-4059-9f41-3c7e3d011e6a").titleFr
                : contactContent.find(find => find.id === "ec8ac303-8900-4059-9f41-3c7e3d011e6a").title
              )
          }
        </h1>
        <p style={{ whiteSpace: "pre-wrap" }} className="infoContainer-description">
          {
            !isLoaded ?
              (lang === 'fr' ?
                <span>
                  Installez-vous et trouvez InnovFin pour aider votre entreprise ou organisation à comprendre les technologies émergentes et perturbatrices avec notre fondatrice et PDG, Othalia Doe-Bruce, dans un discours complet sur Blockchain.
                  <br /><br />
                  Vous avez besoin de plus d'informations?
                  <br />
                  Nous aimerions vous entendre! Écrivez-nous pour nous contacter!
                  <br /><br />
                </span>
                : <span>
                  Thank you for your interest in InnovFin. We help companies and businesses embrace emerging and disruptive technologies, the vision and mission of our Founder &amp; CEO, Othalia Doe-Bruce, the Blockchain Expert since 2015.
                  <br /><br />
                  Need more information?
                  <br />
                  We would love to hear from you! Drop us a line to get in touch!
                  <br /><br />
                </span>
              )
              :
              (lang === 'fr' ?
                contactContent.find(find => find.id === "ec8ac303-8900-4059-9f41-3c7e3d011e6a").contentFr
                : contactContent.find(find => find.id === "ec8ac303-8900-4059-9f41-3c7e3d011e6a").content
              )
          }
        </p>

        {/* Container for the cards */}
        <Container className='contactForm'>
          <Row className='align-items-center'>
            <Col id="text-element" className='contactForm-content' md={12} lg={6}>
              {/*
                How this Formik form works:
                1. it has inputs in the form of the Form component. Each field has an id which is used for identifying and storing data
                2. There are some validations that are done through the id's and the ContactSchema class which employs the Yup library
                3. once valid inputs are in each field, and the user presses submit, Formik runs everything in "onSubmit" passing the values that were in
                the form as "data"
                4. create a function and run it here
                5. For an example take a look at Login.js or Register.js in the "Accounts folder"
              */}
              {onSuccess === false && (<Formik
                enableReinitialize
                validationSchema={lang === 'fr' ? ContactSchemaFr : ContactSchema}
                onSubmit={(data, { setSubmitting }) => {
                  setSubmitting(true);
                  setSubmitting(false);
                  aws.sendContactUsEmail(data)
                  setOnSuccess(true);
                }}
                initialValues={initialVals}
              >
                {({
                  handleSubmit,
                  handleChange,
                  isSubmitting,
                  values,
                  errors,
                  touched,
                  setFieldValue
                }) => (
                  <Form onSubmit={handleSubmit}>
                    {/* name */}
                    <Form.Group
                      className='mb-3' /* controlId="formBasicEmail" */
                    >
                      <InputGroup className='mb-3'>
                        {lang === 'fr' ? (
                          <Form.Control
                            type='text'
                            placeholder='Prénom*'
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={!!errors.name}
                            id='name'
                          />
                        ) : (
                          <Form.Control
                            type='text'
                            placeholder='Name*'
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={!!errors.name}
                            id='name'
                          />
                        )}
                        <Form.Control.Feedback type='invalid'>
                          {' '}
                          {errors.name}{' '}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    {/* email */}
                    <Form.Group className='mb-3'>
                      <InputGroup className='mb-3'>
                        {lang === 'fr' ? (
                          <Form.Control
                            type='email'
                            name='email'
                            placeholder='Courriel*'
                            value={values.email}
                            onChange={handleChange}
                            isValid={touched.email && !errors.email}
                            isInvalid={!!errors.email}
                            id='email'
                          />
                        ) : (
                          <Form.Control
                            type='email'
                            name='email'
                            placeholder='Email*'
                            value={values.email}
                            onChange={handleChange}
                            isValid={touched.email && !errors.email}
                            isInvalid={!!errors.email}
                            id='email'
                          />
                        )}
                        <Form.Control.Feedback type='invalid'>
                          {' '}
                          {errors.email}{' '}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    {/* phone number */}
                    <Form.Group
                      className='mb-3' /* controlId="formBasicPassword" */
                    >
                      <InputGroup className='mb-3'>
                        {lang === 'fr' ? (
                          <Form.Control
                            placeholder='Téléphone'
                            value={values.phonenumber}
                            onChange={handleChange}
                            isInvalid={!!errors.phonenumber}
                            id='phonenumber'
                          />
                        ) : (
                          <Form.Control
                            placeholder='Phone'
                            value={values.phonenumber}
                            onChange={handleChange}
                            isInvalid={!!errors.phonenumber}
                            id='phonenumber'
                          />
                        )}
                      </InputGroup>
                    </Form.Group>

                    {/* Selection */}
                    <Form.Group className='mb-3'>
                      <InputGroup className='mb-3'>
                        {
                          !isLoaded ?
                            (lang === 'fr' ? (
                              <Form.Control
                                as="select"
                                type="text"
                                name="inputDrop"
                                onChange={handleChange}
                                isValid={touched.inputDrop && !errors.inputDrop}
                                isInvalid={!!errors.inputDrop}
                                id="inputDrop"
                                className="form-select"
                              >
                                <option value='' label="Je suis intéressé par...."></option>
                                {/* Removed these two choices as they are not ready
                            <option>Cours en ligne gratuits</option>
                            <option>Cours en ligne premium</option>
                             */}
                                <option>Éducation hors ligne</option>
                                <option>Session de conception</option>
                                <option>Rédaction du plan d'affaires</option>
                                <option>Rédaction de livres blancs</option>
                                <option>Blockchain produit de paiement de fidélisation</option>
                                <option>Produit de certification blockchain</option>
                                <option>Équipe de développement blockchain</option>
                                <option>Équipe chargée du marketing et des relations publiques pour la blockchain</option>
                                <option>Réservez un conférencier spécialiste de la blockchain</option>
                                <option>Autre</option>
                              </Form.Control>
                            ) : (
                              <Form.Control
                                as="select"
                                type="text"
                                name="inputDrop"
                                onChange={handleChange}
                                isValid={touched.inputDrop && !errors.inputDrop}
                                isInvalid={!!errors.inputDrop}
                                id="inputDrop"
                                className="form-select"
                              >
                                <option value='' label="I am interested in..."></option>
                                {/* Removed these two choices as they are not ready
                            <option>Free Online Courses</option>
                            <option>Premium Online Courses</option>
                             */}
                                <option>Offline Education</option>
                                <option>Design Session</option>
                                <option>Business Plan Writing</option>
                                <option>White Paper Writing</option>
                                <option>Blockchain Loyalty Payment Product</option>
                                <option>Blockchain Certification Product</option>
                                <option>Blockchain Development Team</option>
                                <option>Blockchain Marketing and PR Team</option>
                                <option>Book a Blockchain Speaker</option>
                                <option>Other</option>
                              </Form.Control>
                            )
                            )
                            :
                            (lang === 'fr' ? (
                              <Form.Control
                                as="select"
                                type="text"
                                name="inputDrop"
                                onChange={handleChange}
                                isValid={touched.inputDrop && !errors.inputDrop}
                                isInvalid={!!errors.inputDrop}
                                id="inputDrop"
                                className="form-select"
                              >
                                <option value='' label="Je suis intéressé par...."></option>
                                {contactContent.filter(data => data.type === "[Question]").map((data, index) => {
                                  return <option key={index}>{data.titleFr}</option>
                                })}
                              </Form.Control>
                            ) : (
                              <Form.Control
                                as="select"
                                type="text"
                                name="inputDrop"
                                onChange={handleChange}
                                isValid={touched.inputDrop && !errors.inputDrop}
                                isInvalid={!!errors.inputDrop}
                                id="inputDrop"
                                className="form-select"
                              >
                                <option value='' label="I am interested in..."></option>
                                {contactContent.filter(data => data.type === "[Question]").map((data, index) => {
                                  return <option key={index}>{data.title}</option>
                                })}
                              </Form.Control>
                            )
                            )
                        }
                        <Form.Control.Feedback type='invalid'>
                          {' '}
                          {errors.inputDrop}{' '}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    {/* Changing default input text based on selection */}
                    {
                      !isLoaded ?
                        (lang === "fr" ?
                          (ContactTextFr.forEach((data, idx) => {
                            if (data.id === values.inputDrop) {
                              if (initialVals.inputText !== data.content) {
                                setDefaultTxt(data.content);
                                setInitialVals({
                                  name: values.name,
                                  email: values.email,
                                  phonenumber: values.phonenumber,
                                  inputDrop: values.inputDrop,
                                  inputText: data.content
                                })
                              }

                            }
                          }
                          )) :
                          (ContactText.forEach((data, idx) => {
                            if (data.id === values.inputDrop) {
                              if (initialVals.inputText !== data.content) {
                                setDefaultTxt(data.content);
                                setInitialVals({
                                  name: values.name,
                                  email: values.email,
                                  phonenumber: values.phonenumber,
                                  inputDrop: values.inputDrop,
                                  inputText: data.content
                                })
                              }

                            }
                          }
                          ))
                        )
                        :
                        (lang === "fr" ?
                          (contactContent.filter(data => data.type === "[Question]").forEach((data, idx) => {
                            if (data.titleFr === values.inputDrop) {
                              if (initialVals.inputText !== data.contentFr) {
                                setDefaultTxt(data.contentFr);
                                setInitialVals({
                                  name: values.name,
                                  email: values.email,
                                  phonenumber: values.phonenumber,
                                  inputDrop: values.inputDrop,
                                  inputText: data.contentFr
                                })
                              }

                            }
                          }
                          )) :
                          (contactContent.filter(data => data.type === "[Question]").forEach((data, idx) => {
                            if (data.title === values.inputDrop) {
                              if (initialVals.inputText !== data.content) {
                                setDefaultTxt(data.content);
                                setInitialVals({
                                  name: values.name,
                                  email: values.email,
                                  phonenumber: values.phonenumber,
                                  inputDrop: values.inputDrop,
                                  inputText: data.content
                                })
                              }

                            }
                          }
                          ))
                        )
                    }

                    {/* If the selection is valid, open up the request form */}
                    {
                      values.inputDrop !== "" ? (
                        <>
                          <InquiryForm
                            name="inputText"
                            lang={lang}
                            defVal={defaultTxt}
                            placehold={lang === "fr" ? "Je suis intéressé par....*" : "I'm interested in...*"} />
                          <Form.Text style={{ float: 'left', textAlign: "left", marginBottom: "1em" }} id="passwordHelpBlock" muted>
                            {lang === "fr" ?
                              "Veillez à relire votre demande car certaines parties doivent être modifiées pour que nous puissions mieux comprendre votre demande." :
                              "Please be sure to read over your inquiry as there are parts that should be changed for us to better understand your request."}
                          </Form.Text>
                          <InquiryForm
                            name="notes"
                            lang={lang}
                            defVal={defaultTxt}
                            placehold={lang === "fr" ? "Notes facultatives" : "Optional Notes"} />
                          <Form.Text style={{ float: 'left', textAlign: "left", marginBottom: "1em" }} id="passwordHelpBlock" muted>
                            {lang === "fr" ?
                              "Ajoutez ici toute note supplémentaire ici. Ces notes devraient permettre de compléter votre demande et nous donner des informations supplémentaires." :
                              "Add any additional notes you have here. These notes should help complement your request and give us additional information."}
                          </Form.Text>
                        </>
                      ) : (
                        <></>
                      )
                    }

                    <Button
                      disabled={isSubmitting}
                      className='btn-send button'
                      type='submit'
                    >
                      {lang === 'fr' ? 'Envoyer' : 'Send'}
                    </Button>
                    {/* {console.log(values)} */}
                  </Form>
                )}
              </Formik>
              )}
              {onSuccess === true && (
                !isLoaded ?
                  (<div>
                    <h1>Thank you for reaching out to us!</h1>
                    <p>Your message has been sent, our team will reach out soon.</p>
                  </div>)
                  :
                  (<div>
                    <h1 style={{ paddingLeft: "0em" }}>
                      {lang === 'fr' ?
                        contactContent.find(find => find.id === "032e731f-6f08-4724-9cc0-20653bce1621").titleFr
                        : contactContent.find(find => find.id === "032e731f-6f08-4724-9cc0-20653bce1621").title
                      }
                    </h1>
                    <p style={{ fontSize: "16pt", paddingLeft: "0em" }}>
                      {lang === 'fr' ?
                        contactContent.find(find => find.id === "032e731f-6f08-4724-9cc0-20653bce1621").contentFr
                        : contactContent.find(find => find.id === "032e731f-6f08-4724-9cc0-20653bce1621").content
                      }
                    </p>
                  </div>)
              )
              }
            </Col>
            <Col id="image-element" md={12} lg={6}>
              <img
                onContextMenu={(e) => e.preventDefault()}
                src='/assets/Home Page Assets/BookAconsultation.png'
                alt='Contact us'
                className="img-center-size card-img-dropshadow-heavy"
              />
            </Col>
          </Row>
        </Container>
      </div>
      {/* The following is used to show any popup at this location, there may not be any popups to show */}
      <PopupComponent lang={lang} location="contact" />
    </Container>
  )
}

export default Contact
