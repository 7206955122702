import React from "react";

import { Resource } from "react-admin";
import {
  AmplifyAdmin,
  CognitoGroupList,
  CognitoUserList,
  CognitoUserShow,
} from "react-admin-amplify";

import {
  PostList,
  PostEdit,
  PostCreate,
  PostIcon,
  VideoEmbedList,
  VideoEmbedEdit,
  VideoEmbedCreate,
  FAQCreate,
  FAQlist,
  FAQEdit,
  HomeList,
  HomeCreate,
  HomeEdit,
  ServiceCreate,
  ServiceList,
  ServiceEdit,
  AboutOthaliaContentCreate,
  AboutOthaliaContentList,
  AboutOthaliaContentEdit,
  AboutContentCreate,
  AboutContentList,
  AboutContentEdit,
  ContactCreate,
  ContactList,
  ContactEdit,
  AuthorCreate,
  AuthorList,
  AuthorEdit,
  IconCreate,
  IconList,
  IconEdit,
  PopupCreate,
  PopupList,
  PopupEdit,
  TermCreate,
  TermEdit,
  TermList
} from "./components/AdminPosts";

import awsExports from "./aws-exports";
import * as mutations from "./graphql/mutations";
import * as queries from "./graphql/queries";
import AdminLayout from "./styling/AdminLayout";

export default function AdminPanel() {
  return (
    <AmplifyAdmin
      operations={{ queries, mutations }} // Pass the queries and mutations
      layout={AdminLayout}
      options={{
        authGroups: ["Admins", "admin"],
        enableAdminQueries: true,
        storageBucket: awsExports.aws_user_files_s3_bucket,
        storageRegion: awsExports.aws_user_files_s3_bucket_region,
      }} // Pass the options
    >
      <Resource
        name="posts"
        options={{ label: 'Blog Posts' }}
        list={PostList}
        create={PostCreate}
        edit={PostEdit}
      />
      <Resource
        name="authors"
        options={{ label: 'Blog Posts Authors' }}
        list={AuthorList}
        create={AuthorCreate}
        edit={AuthorEdit}
      />
      <Resource
        name="homes"
        options={{ label: 'Home content' }}
        list={HomeList}
        create={HomeCreate}
        edit={HomeEdit}
      />
      <Resource
        name="services"
        options={{ label: 'Service content' }}
        list={ServiceList}
        create={ServiceCreate}
        edit={ServiceEdit}
      />
      <Resource
        name="AboutOthaliaContents"
        options={{ label: 'About Othalia content' }}
        list={AboutOthaliaContentList}
        // create={AboutOthaliaContentCreate}
        edit={AboutOthaliaContentEdit}
      />
      <Resource
        name="AboutContents"
        options={{ label: 'About content' }}
        list={AboutContentList}
        // create={AboutContentCreate}
        edit={AboutContentEdit}
      />
      <Resource
        name="Contacts"
        options={{ label: 'Contact content' }}
        list={ContactList}
        create={ContactCreate}
        edit={ContactEdit}
      />
      <Resource
        name="FAQposts"
        options={{ label: 'FAQ' }}
        create={FAQCreate}
        list={FAQlist}
        edit={FAQEdit}
      />
      <Resource
        name="VideoEmbeds"
        options={{ label: 'Static Embedded Videos' }}
        list={VideoEmbedList}
        create={VideoEmbedCreate}
        edit={VideoEmbedEdit}
      />
      <Resource
        name="iconImages"
        options={{ label: 'Website Icons' }}
        list={IconList}
        create={IconCreate}
        edit={IconEdit}
      />
      <Resource
        name="popups"
        options={{ label: 'Popups' }}
        list={PopupList}
        create={PopupCreate}
        edit={PopupEdit}
      />
      <Resource
        name="terms"
        options={{ label: 'Term and Policy'}}
        list={TermList}
        create={TermCreate}
        edit={TermEdit}
      />
      {/* <Resource
        name="cognitoUsers"
        options={{ label: "Cognito Users" }}
        list={CognitoUserList}
        show={CognitoUserShow}
      />
      <Resource
        name="cognitoGroups"
        options={{ label: "Cognito Groups" }}
        list={CognitoGroupList}
      /> */}

    </AmplifyAdmin>
  );
}
