import React, { useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import { Button, Col, Container, Modal, NavDropdown, Row, Spinner } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import "bootstrap/dist/css/bootstrap.min.css";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import "../App.css";
// import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import { Analytics } from "aws-amplify";
import MuiButton from "@mui/material/Button";
import { Menu, MenuItem } from "@mui/material";
import parse from "html-react-parser";
import { API, graphqlOperation } from "aws-amplify";
import { listIconImages, listPopups } from "../graphql/queries";
import { useParams } from "react-router-dom";

function NavBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  //the below is temporary icon fix for nav bar
  //the below is used for storing the content that will be grabbed from the API
  //is loaded is to check if the api is loaded
  const [icon, setIcon] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  //For the popup "Get it now"
  const [popupData, setPopupData] = useState([])
  const [popupLoaded, setPopupLoaded] = useState(false);
  const setPopupAPI = () => {
    API.graphql(graphqlOperation(listPopups))
      .then((data) => {
        const modifiedData2 = data["data"]["listPopups"]["items"];

        modifiedData2.map((item) => {
          item.content = parse(item.content);
          item.contentFr = parse(item.contentFr);
          return item;
        });

        setPopupData(modifiedData2);
        setPopupLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //sets up the api to grab data from the database, dynamoDB in this case
  const setContentAPI = () => {
    //calls the api to qurey the data using the given query
    API.graphql(graphqlOperation(listIconImages))
      .then((data) => {
        //stores list of data in a variable to be manipulated later
        const modifiedData2 = data["data"]["listIconImages"]["items"];
        //set the data grabbed from the api and set the variable used later
        setIcon(modifiedData2);
        //lets the app know that the data has loaded and can be rendered
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // test
  // const storedLang = localStorage.getItem("lang");
  const [lang, setLang] = useState();

  const [popup, setPopup] = useState(false);
  const handleShowPopup = () => {
    setPopup(true);
    Analytics.record("Ebook Popup")
      .catch(error => (console.log(error)))
  };
  const handleClosePopup = () => setPopup(false);

  //list of class names to disable right-click
  const imageClasses = [".logo", ".img-center-size"];

  //remove the context menu for the images
  const removeRightClick = () =>{
      imageClasses.forEach(imgClass => {
          const noContextMenu = document.querySelectorAll(imgClass);
          noContextMenu.forEach(data => {
              data.addEventListener("contextmenu", e => e.preventDefault());
          })
      })
  }

  useEffect(() => {
    setContentAPI();
    setPopupAPI();
    removeRightClick();
    if(window.location.href.includes("en")){
      setLang("en");
    } else {
      setLang("fr");
    }
  }, [])

  // useEffect(() => {
  //   localStorage.setItem("lang", String(lang));
  // }, [lang]);

  function handleSideNav() {
    const toggleButton = document.querySelector(
      ".sidenav---sidenav-toggle---1KRjR"
    );
    const toggleMenu = document.querySelector(".sidenav---sidenav-nav---3tvij");
    if (toggleButton.ariaExpanded === "false") {
      toggleMenu.hidden = false;
    } else {
      toggleMenu.hidden = true;
    }
  }

  return (
    <div>
      <div className="discover">
        <Container>
          <p>
            {lang === "fr" ? (
              <span>
                Découvrez le livre électronique essentiel sur la blockchain et
                la finance.{" "}
                <button className="btn-link" onClick={handleShowPopup}>
                  Obtenez-le maintenant!
                </button>
              </span>
            ) : (
              <span>
                Discover the Essential E-book on Blockchain and Finance.{" "}
                <button className="btn-link" onClick={handleShowPopup}>
                  Get it now!
                </button>
              </span>
            )}
          </p>
        </Container>
      </div>
      {/* The following is a Navbar component of React-Bootstrap to create our navbar */}
      <Navbar className="navBar">
        <Container className="px-5">
          {/* Brand is an element used for the company logo */}
          <Navbar.Brand href={`/${lang}/Home/`}>
            <img
              alt=""
              src="/LogoBlackBlue.png"
              //aspect ratio of 1866 : 572
              width="250"
              height="77"
              className="d-inline-block align-top logo"
            />
          </Navbar.Brand>
          <Navbar.Text>
            <Row className="infoText" xs="auto">
              {/* <Button onClick={()=> console.log(lang === 'en')}>test</Button> */}
              <Col xs={4} lg="auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-envelope"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
                </svg>
                {" info@innovfin.ca"}
              </Col>
              <Col xs={4} lg="auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-telephone"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                </svg>
                {" 416-900-3897"}
              </Col>
              <Col xs={4} lg="auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-geo-alt"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                </svg>
                {" Peterborough, Ontario, Canada"}
              </Col>
            </Row>
          </Navbar.Text>
        </Container>
      </Navbar>

      {/* The subNavBar is the navigation bar below the logo, where all the links are */}
      <Navbar className="subNavBar">
        <div className="align-items-center">

        </div>
        <Container>
          <MuiButton
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            className="Menu-btn"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="black" className="bi bi-list" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
            </svg>
          </MuiButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleClose}><a href={`/${lang}/Home/`}>{lang === "fr" ? "Page d'accueil" : "Home"}</a></MenuItem>
            <NavDropdown title="Services">
              <NavDropdown.Item href={`/${lang}/Education/`}>{lang === "fr" ? "Éducation" : "Education"}</NavDropdown.Item>
              <NavDropdown.Item href={`/${lang}/Strategy/`}>{lang === "fr" ? "Stratégie" : "Strategy"}</NavDropdown.Item>
              <NavDropdown.Item href={`/${lang}/Implementation/`}>{lang === "fr" ? "Mise en œuvre" : "Implementation"}</NavDropdown.Item>
              <NavDropdown.Item href={`/${lang}/BookSpeaker/`}>{lang === "fr"
                ? "Réserver un conférencier spécialiste de la blockchain"
                : "Book a Blockchain Speaker"}
              </NavDropdown.Item>
            </NavDropdown>
            <MenuItem onClick={handleClose}>
              <a href={`/${lang}/about-othalia-doe-bruce-1/`} >{lang === "fr"
                ? "À propos d'Othalia Doe-Bruce"
                : "About Othalia Doe-Bruce"}
              </a>
            </MenuItem>
            <MenuItem onClick={handleClose}><a href={`/${lang}/About/`}>{lang === "fr" ? "À propos d'InnovFin" : "About InnovFin"}</a></MenuItem>
            <MenuItem onClick={handleClose}><a href={`/${lang}/contact-us/`}>{lang === "fr" ? "Contactez-nous" : "Contact Us"}</a></MenuItem>
            <MenuItem onClick={handleClose}><a href={`/${lang}/FAQ/`}>FAQ</a></MenuItem>
            <MenuItem onClick={handleClose}><a href={`/${lang}/Blog/`}>{lang === "fr" ? "Blogue" : "Blog"}</a></MenuItem>
          </Menu>
          {/* React-bootstrap classes for a functioning nav bar */}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id={lang === "fr" ? "responsive-navbar-nav-fr" : "responsive-navbar-nav"}>
            {/* The actual navigation part of the nav bar */}
            <Nav activeKey={window.location.pathname} className="mr-auto px-5">
              {/* 
                                the nav.link components are used to create a clickable link
                                while the navedropdown creates a drop down menu for a menu of selections
                                which uses the navdropdown.item component as components of the drop down menu
                                */}
              <Nav.Link href={`/${lang}/Home/`}>
                {lang === "fr" ? "Page d'accueil" : "Home"}
              </Nav.Link>
              <div className="dropMenu">
                {/* Add href tag if we need services to lead to "Service" page */}
                <Nav.Link className="dropbtn">
                  {/* if we are in services, bold the word service too */}
                  {window.location.pathname === `/${lang}/Education/` ||
                    window.location.pathname === `/${lang}/Strategy/` ||
                    window.location.pathname === `/${lang}/Implementation/` ||
                    window.location.pathname === `/${lang}/BookSpeaker/` ? (
                    <span style={{ fontWeight: "900" }}>Services</span>
                  ) : (
                    <span>Services</span>
                  )}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </Nav.Link>
                <div className="dropMenu-content">
                  <Nav.Link href={`/${lang}/Education/`}>
                    {lang === "fr" ? "Éducation" : "Education"}
                  </Nav.Link>
                  <Nav.Link href={`/${lang}/Strategy/`}>
                    {lang === "fr" ? "Stratégie" : "Strategy"}
                  </Nav.Link>
                  <Nav.Link href={`/${lang}/Implementation/`}>
                    {lang === "fr" ? "Mise en œuvre" : "Implementation"}
                  </Nav.Link>
                  <Nav.Link href={`/${lang}/BookSpeaker/`}>
                    {lang === "fr"
                      ? "Réserver un conférencier spécialiste de la blockchain"
                      : "Book a Blockchain Speaker"}
                  </Nav.Link>
                </div>
              </div>

              {/* This will be changed back, but for now  */}
              <Nav.Link href={`/${lang}/about-othalia-doe-bruce-1/`} >
                {lang === "fr"
                  ? "À propos d'Othalia Doe-Bruce"
                  : "About Othalia Doe-Bruce"}
              </Nav.Link>
              <Nav.Link href={`/${lang}/About/`}>
                {lang === "fr" ? "À propos d'InnovFin" : "About InnovFin"}
              </Nav.Link>
              <Nav.Link href={`/${lang}/contact-us/`}>
                {lang === "fr" ? "Contactez-nous" : "Contact Us"}
              </Nav.Link>
              <Nav.Link href={`/${lang}/FAQ/`}>{lang === "fr" ? "FAQ" : "FAQ"}</Nav.Link>
              <Nav.Link href={`/${lang}/Blog/`}>
                {lang === "fr" ? "Blogue" : "Blog"}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <a href={`/${lang}/Account/`}>
            {!isLoaded ? (
              <img
                alt="test"
                src="../assets/Social Icons/User.png"
                width="30px"
              />
            ) : (
              <img
                alt="test"
                src={icon.find(find => find.id === "83d95d6f-0b7f-42a6-abfa-b186b190b777").image}
                width="30px"
              />
            )}

          </a>
          {/* <Button className='translateBtn' href="/Account/">Account</Button> */}

          <Button
            className="translateBtn"
            onClick={() => {
              window.location.pathname.includes("fr") ?
                window.location.href = window.location.pathname.replace("fr", "en")
                : window.location.href = window.location.pathname.replace("en", "fr")
            }}
          >
            {lang === "fr" ? (
              <span>
                En|<b style={{ fontWeight: "900" }}>Fr</b>
              </span>
            ) : (
              <span>
                <b style={{ fontWeight: "900" }}>En</b>|Fr
              </span>
            )}
          </Button>
        </Container>
      </Navbar>

      {
        !popupLoaded ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <Modal
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={popup}
            onHide={handleClosePopup}
          >
            <Modal.Header className="popup-innovEdu-header">
              <Modal.Title
                className="popup-header"
              >
                {lang === "fr" ?
                  popupData.find(find => find.id === "d86a9459-1b1a-4c89-b434-135d773b7bd6").titleFr
                  : popupData.find(find => find.id === "d86a9459-1b1a-4c89-b434-135d773b7bd6").title
                }
              </Modal.Title>
              <button className="btn-link" onClick={handleClosePopup}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  fill="black"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </button>
            </Modal.Header>
            <Modal.Body className="popup-innovEdu-body">
              <Row className="align-items-center">
                <Col sm={12} lg={8}>
                  <div style={{ whiteSpace: "pre-line" }}>
                    {lang === "fr" ?
                      (<span>
                        {popupData.find(find => find.id === "d86a9459-1b1a-4c89-b434-135d773b7bd6").contentFr}
                        <Button
                          href={popupData.find(find => find.id === "d86a9459-1b1a-4c89-b434-135d773b7bd6").linkFr}
                          style={{ marginTop: "1em" }}
                          className="btn-2"
                        >
                          {popupData.find(find => find.id === "d86a9459-1b1a-4c89-b434-135d773b7bd6").buttonFr}
                        </Button>
                      </span>
                      ) : (
                        <span>
                          {popupData.find(find => find.id === "d86a9459-1b1a-4c89-b434-135d773b7bd6").content}
                          <Button
                            href={popupData.find(find => find.id === "d86a9459-1b1a-4c89-b434-135d773b7bd6").link}
                            style={{ marginTop: "1em" }}
                            className="btn-2"
                          >
                            {popupData.find(find => find.id === "d86a9459-1b1a-4c89-b434-135d773b7bd6").button}
                          </Button>
                        </span>
                      )}
                  </div>
                </Col>
                <Col sm={12} lg={4}>
                  <img
                    className="img-center-size"
                    src={popupData.find(find => find.id === "d86a9459-1b1a-4c89-b434-135d773b7bd6").image}
                    alt=""
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="popup-innovEdu-footer"></Modal.Footer>
          </Modal >)
      }
    </div >
  );
}

export default NavBar;
