import React from 'react';

function AboutCheckList(props) {
    // Using SVG from bootstrap, keep these variables as backup
    // const IMG_SRC = "../assets/check-circle.svg";
    // const WIDTH = 20;
    // const HEIGHT = 20;
    // const iconButtonAlt = "check icon";
    return (
        <div className="checkList">
            <ul style={{ listStyleType: 'none', padding: "0em 1em" }}>
                {props.list.map((list, index) => (
                    <li key={index}>
                        <p style={{ margin: "0" }}>
                            {list}
                        </p>
                    </li>))
                }
            </ul>
        </div>
    )
}

export default AboutCheckList;

