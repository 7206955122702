import * as Yup from 'yup';

//Yup validation schema
const RegisterConfirmSchemaFr = Yup.object().shape({
  username: Yup.string()
    .required("Le nom d'utilisateur est requis"),
  authCode: Yup.number()
    .required("Le code d'authentification est requis")
    .typeError("Le code de confirmation doit être un numéro!"),
})

export default RegisterConfirmSchemaFr;