import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

export default function HelmetMetaData(props) {

    let location = useLocation();
    let urlPath = window.location.href;
    let quote = props.quote !== undefined ? props.quote : "";
    let title = props.title !== undefined ? props.title : "InnovFin Consulting - Innovative technologies and Solutions";
    let image = props.image !== undefined ? props.image : "./icon.png";
    let description = props.description !== undefined ? props.description : "InnovFin specializes in Blockchain technology and can provide capabilities in Artificial Intelligence (AI), Internet of Things (IoT) and Augmented Reality (AR)/Virtual Reality (VR)."
    let keywords = props.keywords !== undefined ? props.keywords : "";
    let hashtag = props.hashtag !== undefined ? props.hashtag : "#innovfin";

    return (
        <Helmet prioritizeSeoTags>
            <title>{title}</title>
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="csrf_token" content="" />
            <meta property="type" content="website" />
            <meta property="url" content={urlPath} />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="_token" content="" />
            <meta name="robots" content="noodp" />
            <meta name="theme-color" content="#ffffff" />
            <meta name="keywords" content={keywords} />
            <meta property="title" content={title} />
            <meta property="quote" content={quote} />
            <meta name="description" content={description} />
            <meta property="image" content={image} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:quote" content={quote} />
            <meta property="og:hashtag" content={hashtag} />
            <meta property="og:image" content={image} />
            <meta content="image/*" property="og:image:type" />
            <meta property="og:url" content={urlPath} />
            <meta property="og:site_name" content="InnovFin" />
            <meta property="og:description" content={description} />
        </Helmet>
    )
}