import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col, InputGroup } from 'react-bootstrap';
import '../styling/Account.css';
import LoginSchema from './Schema/LoginSchema';
import { Formik } from 'formik';
import Auth from '@aws-amplify/auth';
import LoginSchemaFr from './Schema/LoginSchemaFr';

function Login({ lang }) {
    const storedForm = JSON.parse(localStorage.getItem('formState'))
    const [formState, setFormState] = useState(storedForm)

    useEffect(() => {
        localStorage.setItem("formState", JSON.stringify(formState))
    }, [formState])

    async function signIn(data) {
        const { username, password } = data
        await Auth.signIn(username, password)
        setFormState(() => ({ ...formState, formType: "signedIn" }))
        window.location.reload();
    }
    return (
        <Container>
            <div className="infoContainer" >
                <h1>{lang === 'fr' ? 'Connexion' : 'Login'}</h1>
                <p style={{ padding: '0' }}>
                    {lang === 'fr' ? <span> Restez à jour avec nous ! <br />Connectez-vous ou créez un compte ci-dessous.</span> :
                    <span>Keep Up to date with us! <br />Login or create an account below.</span>}
                </p>
                <Container className="loginContainerWithBorder" >
                    <Formik
                        validationSchema={lang === 'fr' ? LoginSchemaFr : LoginSchema}
                        onSubmit={(data, { setSubmitting }) => {
                            setSubmitting(true);
                            signIn(data)
                            setSubmitting(false);
                        }}
                        initialValues={{
                            username: '',
                            password: '',
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            isSubmitting,
                            values,
                            errors,
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" /* controlId="formBasicEmail" */>
                                    <InputGroup className="mb-3">
                                        {/* <img src="../assets/Social Icons/Twitter.png" className="loginIcon" alt="user icon for login" /> */}
                                        {lang === 'fr' ?  <Form.Control type="text" placeholder="nom d'utilisateur" value={values.username} onChange={handleChange} isInvalid={!!errors.username} id="username" /> :
                                        <Form.Control type="text" placeholder="username" value={values.username} onChange={handleChange} isInvalid={!!errors.username} id="username" />}
                                        <Form.Control.Feedback type="invalid"> {errors.username} </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group className="mb-3" /* controlId="formBasicPassword" */>
                                    <InputGroup className="mb-3">
                                        {/* <img src="../assets/Social Icons/Twitter.png" className="loginIcon" alt="password icon for login" /> */}

                                        {lang === 'fr' ? <Form.Control type="password" placeholder="mot de passe" value={values.password} onChange={handleChange} isInvalid={!!errors.password} id="password" /> : 
                                        <Form.Control type="password" placeholder="password" value={values.password} onChange={handleChange} isInvalid={!!errors.password} id="password" />}
                                    </InputGroup>
                                </Form.Group>

                                <Button disabled={isSubmitting} className="loginBtn" type="submit">{lang === 'fr' ? 'Connexion' : 'Login'}</Button>
                            </Form>
                        )}
                    </Formik>

                    <div className="bottomSection">
                        <Row>
                            <Col xs={12} sm={6} md={6} ><Form.Text className="text-muted">
                                {lang === 'fr' ? 'Vous n\'avez pas de compte?' :  'Don\'t have an account?'}<br/></Form.Text>
                                <button href='' onClick={
                                    () => {
                                        setFormState(() => ({ ...formState, formType: "signUp" }));
                                        window.location.reload();
                                    }
                                } className="btn-link signUpLbl">{lang === 'fr' ? 'S\'inscrire' : 'Sign Up'}</button></Col>
                            <Col xs={12} sm={6} md={6}>
                                <Form.Text className="text-muted">
                                    {lang === 'fr' ? 'Mot de passe oublié?' : 'Forgot your password?'}<br/></Form.Text>
                                <button href="" onClick={
                                    () => {
                                        setFormState(() => ({ ...formState, formType: "forgotPass" }));
                                        window.location.reload();
                                    }
                                } className="btn-link signUpLbl">{lang === 'fr' ? 'Réinitialiser le mot de passe' : 'Reset Password'}</button></Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </Container>
    )
}

export default Login;
