import "./App.css";
import Home from "./Home";
import Contact from "./Contact";
import AboutOthalia from "./AboutOthalia";
import AboutInnovFin from "./AboutInnovFin";

import Blog from "./Blog";
import BlogPost from "./BlogPost";

import Admin from "./Admin";

import Education from "./Services/Education";
import Strategy from "./Services/Strategy";
import Implementation from "./Services/Implementation";
import BookSpeaker from "./Services/BookSpeaker";
import { Route, Redirect, useHistory, Switch } from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import React, { useEffect, useState } from "react";
import FAQ from "./FAQ";
import Account from "./Account";
import HelmetMetaData from "./components/HelmetMetaData";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import CookieConsent from 'react-cookie-consent';
import { Button, Modal } from 'react-bootstrap';
import { API, graphqlOperation } from "aws-amplify";
import { listHomes } from "./graphql/queries";
import parse from "html-react-parser";
import PrivatePolicy from "./components/PrivatePolicy";
import TermsAndConditions from "./components/TermsAndConditions";

function App() {
  //functions to handle cookies
  const handleShowCookie = () => setShowCookie(true);
  const handleCloseCookie = () => setShowCookie(false);
  const [showCookie, setShowCookie] = useState(false);
  const [homeContent, setHomeContent] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const getCookies = async () => {
    const items = API.graphql(graphqlOperation(listHomes));
    items
      .then((data) => {
        let modifiedData = data["data"]["listHomes"]["items"];
        modifiedData.map((item) => {
          item.content = parse(item.content);
        });
        setHomeContent(modifiedData);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const storedLang = localStorage.getItem("lang");
  const [lang] = useState(storedLang);
  const base = '/:lang(en|fr)?';
  ReactPixel.init("1931213053839755")

  useEffect(() => {
    getCookies();
    if (lang === null || lang === "null") {
      localStorage.setItem("lang", "en");
    };
    // console.log(window.location.pathname)
    if (!window.location.pathname.includes(`/${lang}/`) && lang !== null && window.location.pathname !== "/sitemap.xml") {
      window.location.href = `/${lang}` + window.location.pathname;
    }
    if (window.location.pathname.split("/")[1].includes("en") && localStorage.getItem("lang") !== "en") {
      console.log(true)
      localStorage.setItem("lang", "en");
      window.location.reload();
    } else if (window.location.pathname.split("/")[1].includes("fr") && localStorage.getItem("lang") !== "fr") {
      localStorage.setItem("lang", "fr");
      window.location.reload();
    };
    ReactGA.initialize("UA-146645183-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.pageView();
    //<script src='//fw-cdn.com/1078515/2149009.js' chat='true'></script>
    const chatBot = document.createElement("script");
    chatBot.src = '//fw-cdn.com/1078515/2149009.js'
    chatBot.chat = true;
    document.body.appendChild(chatBot);
    return () => {
      document.body.removeChild(chatBot);
    }
  }, []);

  return (
    <div className="App">
      {/* <HelmetMetaData></HelmetMetaData> */}
      {/* {console.log("cookie ", document.cookie)} */}
      <NavBar lang={lang} />
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to={lang === null || lang === "null" ? `/en/Home/` : `${lang}/Home/`} />;
          }}
        />
        <Route
          exact
          path="/sitemap.xml"
          render={() => {
            return <Redirect to="/sitemap.xml" />;
          }}
        />
        {/* <Route exact path="/Home/" component={() => <Home lang={lang} />} /> */}
        {/* {console.log(lang)} */}
        <Route
          exact
          path={`${base}/Home/`}
          component={() => <Home lang={lang} />} />
        {/* {({ match, location }) => {
            console.log("match ", match, "location ", location)
          }}
      </Route> */}
        {/*Using the router-dom to render each page based on url*/}
        <Route
          exact
          path={`${base}/Education/`}
          component={() => <Education lang={lang} />}
        />
        <Route
          exact
          path={`${base}/Strategy/`}
          component={() => <Strategy lang={lang} />}
        />
        <Route
          exact
          path={`${base}/Implementation/`}
          component={() => <Implementation lang={lang} />}
        />
        <Route
          exact
          path={`${base}/BookSpeaker/`}
          component={() => <BookSpeaker lang={lang} />}
        />
        <Route
          exact
          path={`${base}/About/`}
          component={() => <AboutInnovFin lang={lang} />}
        />
        <Route
          exact
          path={`${base}/about-othalia-doe-bruce-1`}
          component={() => <AboutOthalia lang={lang} />}
        />
        <Route
          exact
          path={`${base}/AboutOthalia/`}
          render={() => {
            return <Redirect to={`/${lang}/about-othalia-doe-bruce-1/`} />;
          }}
        />
        <Route
          exact
          path={`${base}/Blog/`}
          component={(props) => <Blog lang={lang} props={props} />}
        />
        <Route
          exact
          path={`${base}/Blog/f/:slug`}
          component={() => <BlogPost lang={lang} />}
        />
        <Route
          exact
          path={`${base}/Admin/`}
          component={() => <Admin lang={lang} />} />
        <Route
          exact
          path={`${base}/contact-us/`}
          component={() => <Contact lang={lang} />} />

        <Route
          exact
          path={`${base}/Contact/`}
          render={() => {
            return <Redirect to={`/${lang}/contact-us/`} />;
          }}
        />
        <Route
          exact
          path={`${base}/FAQ/`}
          component={() => <FAQ lang={lang} />} />
        <Route
          exact
          path={`${base}/Account/`}
          component={() => <Account lang={lang} />} />
        <Route
          exact
          path={`${base}/Privacy-Policy/`}
          component={() => <PrivatePolicy lang={lang} />} />
        <Route
          exact
          path={`${base}/Terms-and-Conditions/`}
          component={() => <TermsAndConditions lang={lang} />} />
      </Switch>
      <Footer lang={lang} />

      {/* For Cookies */}
      <CookieConsent
        location="bottom"
        buttonText={lang === 'fr' ? "J'ai compris" : "Understood"}
        buttonId="btn-consent"
        containerClasses="cookieConsent"
        buttonClasses="button"
        expires={100}
      >
        {lang === 'fr' ?
          <span>
            Ce site web utilise des cookies pour améliorer l'expérience utilisateur, en continuant vous acceptez notre utilisation des cookies. Consultez notre <button className="btn-link" id="cookieTxt" onClick={handleShowCookie}>Politique en matière de cookies</button>.
          </span>
          : <span>
            This website uses cookies to enhance the user experience, by continuing you accept our use of cookies. Check out our <button className="btn-link" id="cookieTxt" onClick={handleShowCookie}>Cookie Policy</button>.
          </span>}
        {/* Modal's used for cookie popup boxes */}
        <Modal
          animation={false}
          show={showCookie}
          onHide={handleCloseCookie}
          centered
          dialogClassName="popup-dialog-size"
        >
          <Modal.Header className="popup-innovEdu-header">
            <Modal.Title className="popup-header">
              {
                !isLoaded ?
                  (lang === 'fr' ?
                    'Politique en matière de cookies'
                    : 'Cookies Policy'
                  )
                  :
                  (lang === 'fr' ?
                    homeContent.find(find => find.id === "16fc8fc9-e078-4010-bc7d-a3b850cc3808").titleFr
                    : homeContent.find(find => find.id === "16fc8fc9-e078-4010-bc7d-a3b850cc3808").title
                  )
              }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="popup-innovEdu-body">
            {
              !isLoaded ?
                (lang === 'fr' ?
                  <span>
                    <p>
                      Un cookie est un petit fichier que notre site enregistre sur votre appareil lors de votre visite.
                      Ces fichiers ne sont utilisés que pour garder la trace d'informations telles que les fenêtres contextuelles que vous avez vues, afin que vous ne les voyiez pas chaque fois que vous visitez notre site Web.
                      D'autres utilisations des cookies sur notre site incluent, sans s'y limiter, la gestion des widgets pour YouTube et d'autres sites de médias sociaux. Un exemple d'utilisation des
                      cookies est lorsque l'utilisateur reconnaît l'utilisation de nos cookies en appuyant sur "J'ai compris", cette boîte de dialogue n'apparaîtra plus jusqu'à ce que le cookie ait expiré ou si vous avez manuellement effacé les cookies de votre navigateur.
                    </p>
                  </span>
                  : <span>
                    <p>
                      A cookie is a small file that our site stores on your device when you visit.
                      These files are only used to keep track of information such as what popups you have seen so that you do not see them every time you visit our website.
                      Other uses of cookies on our site includes, but are not limited to, handling widgets for YouTube and other social media sites. An example of Cookie usage
                      is when the user acknowledges the use of our cookies by pressing "understood", this dialog will not show up again until the cookie has expired or if you have manually cleared cookies from your browser.
                    </p>
                  </span>
                )
                :
                (lang === 'fr' ?
                  homeContent.find(find => find.id === "16fc8fc9-e078-4010-bc7d-a3b850cc3808").contentFr
                  : homeContent.find(find => find.id === "16fc8fc9-e078-4010-bc7d-a3b850cc3808").content
                )
            }
          </Modal.Body>
          <Modal.Footer className="popup-innovEdu-footer">
            <Button className="button btn-2" onClick={handleCloseCookie}>
              {
                !isLoaded ?
                  (lang === 'fr' ?
                    "Fermer"
                    : "Close"
                  )
                  :
                  (lang === 'fr' ?
                    homeContent.find(find => find.id === "16fc8fc9-e078-4010-bc7d-a3b850cc3808").buttonFr
                    : homeContent.find(find => find.id === "16fc8fc9-e078-4010-bc7d-a3b850cc3808").button
                  )
              }
            </Button>
          </Modal.Footer>
        </Modal>
      </CookieConsent>
    </div>
  );
}

export default App;
