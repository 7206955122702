import React, { useState, useEffect } from 'react'
import MailchimpForm from '../mailchimp-form'
import { API, graphqlOperation } from "aws-amplify";
import { listIconImages } from "../graphql/queries";
import { Col, Container, Row, Button, Modal, Nav } from 'react-bootstrap'

function Footer({ lang }) {
    const [showCookie, setShowCookie] = useState(false);
    const handleShowCookie = () => setShowCookie(true);
    const handleCloseCookie = () => setShowCookie(false);

    const [isLoaded, setIsLoaded] = useState(false);
    const [icon, setIcon] = useState([]);
    //we will remove the "Frequently asked quesion" header from the FAQpost array, so use these
    //variables to set header
    const [header, setHeader] = useState([]);
    const [headerText, setHeaderText] = useState([]);

    //list of class names to disable right-click
    const imageClasses = [".logo", ".img-center-size"];

    //remove the context menu for the images
    const removeRightClick = () => {
        imageClasses.forEach(imgClass => {
            const noContextMenu = document.querySelectorAll(imgClass);
            noContextMenu.forEach(data => {
                data.addEventListener("contextmenu", e => e.preventDefault());
            })
        })
    }

    useEffect(() => {
        API.graphql(graphqlOperation(listIconImages))
            .then((data) => {
                const modifiedData2 = data["data"]["listIconImages"]["items"];

                setIcon(modifiedData2);
                setIsLoaded(true);
            })
            .catch((err) => {
                console.log(err);
            });
        removeRightClick();
    }, []);
    return (
        <React.Fragment>
            {/* Added a fragment to render mailing list without taking on footer styles */}
            <div className="mailingList">
                <Container>
                    <Row className="justify-content-md-center">
                        <Col lg={5}>
                            <h1 className={lang === "fr" ? "subscribeTxt-fr" : "subscribeTxt"}>
                                {lang === "fr" ? (
                                    "Abonnez-vous à notre liste d'envoi"
                                ) : (
                                    "Subscribe to our mailing list!"
                                )}
                            </h1>
                        </Col>
                        <Col lg={5}>
                            <MailchimpForm lang={lang} />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="btn-social">
                {!isLoaded ? (
                    <div>
                        <a href='https://www.linkedin.com/company/innovfin'><img src='../assets/Social Icons/LinkedIn.png' alt='Innovfin LinkedIn' /></a>
                        <a href='https://www.youtube.com/channel/UCY7zGRalYuGaAykCFXIyObw'><img src='../assets/Social Icons/YouTube.png' alt='Innovfin Youtube' /></a>
                        <a href='https://www.facebook.com/InnovFinConsulting/'><img src='../assets/Social Icons/Facebook.png' alt='Innovfin Facebook' /></a>
                        <a href='https://twitter.com/InnovFinI'><img src='../assets/Social Icons/Twitter.png' alt='Innovfin Twitter' /></a>
                        <a href='https://join.slack.com/t/innovfinedtech/shared_invite/zt-u70pg874-JXRdvazWBrNWikoo5k1t4Q'><img src='../assets/Social Icons/Slack.png' alt='Innovfin Slack' /></a>
                        <a href='https://innovfinconsultingsspace.quora.com/'><img src='../assets/Social Icons/Quora.png' alt='Innovfin Quora' /></a>
                        {/* Disabled reddit for now
                <a href='https://www.reddit.com/r/InnovFin/ '><img src='../assets/Social Icons/Reddit.png' alt='Innovfin Reddit' /></a> */}
                    </div>
                ) : (
                    <div>
                        {icon.map((icon, idx) => {
                            return (
                                icon.title === "user" ? ""
                                    : <a key={idx} href={icon.link}>
                                        <img src={icon.image} alt={icon.title} />
                                    </a>
                            )
                        })}
                    </div>
                )}
            </div>
            <div className='footer'>
                {/* Used container so the grouping is consistant throughout the page */}
                <Container>
                    <div id='footer'>
                        {/* 
                    Using the react-bootstrap grid to create the sections of the footer seperated by rows and columns
                    lg are for large pages above a certain pixel count, this will allow 2 column elements on a row
                    sm is for small pages and md is for medium pages. To check out more, look at the documentation site
                    */}
                        <Row md={4} sm={2}>
                            <Col style={{ float: 'left' }} md={4} sm={12}>
                                {/* create a dive for the Innovfin logo and description as it is larger than the other elements */}
                                <div className='cardStyle1'>
                                    <div>
                                        <img
                                            className="logo"
                                            alt=""
                                            src="/LogoBlackBlue.png"
                                            //aspect ratio of 2501 : 1668
                                            width="150"
                                            height="46"
                                        // className="d-inline-block align-left"
                                        // style={{ display: 'block' }}
                                        />
                                    </div>
                                    <p>
                                        {lang === 'fr' ?
                                            'InnovFin est un cabinet de conseil et d\'expertise Blockchain abordant l\'impact des technologies disruptives sur les entreprises. Notre équipe a plus de 10+ ans d\'expérience de travail dans le secteur des services financiers.'
                                            : 'InnovFin is a Blockchain Consulting and Advisory firm addressing the impact of Disruptive Technologies on Companies. Our team has more than 10+ years of experience working in the financial services industry.'
                                        }
                                    </p>
                                </div>
                            </Col>
                            <Col md={2} sm={4} xs={4}>
                                {/* 
                            The second column which includes the organized links in the footer
                            Not all functionality is here yet, some missing functionalities are clickable and rediricting links
                            Buttons for social media and other functions
                            */}
                                <div className='footerCard'>
                                    <h1>{lang === 'fr' ? 'Entreprise' : 'Company'}</h1>
                                    <div className="dropMenu" id="footerMenu">
                                        <p style={{ textAlign: "left" }} >{lang === 'fr' ? 'Services' : 'Services'}</p>
                                        <div className='dropMenu-content'>
                                            <Nav.Link href={`/${lang}/Education/`}>{lang === 'fr' ? 'Éducation' : 'Education'}</Nav.Link>
                                            <Nav.Link href={`/${lang}/Strategy/`}>{lang === 'fr' ? 'Stratégie' : 'Strategy'}</Nav.Link>
                                            <Nav.Link href={`/${lang}/Implementation/`}>{lang === 'fr' ? 'Mise en œuvre' : 'Implementation'}</Nav.Link>
                                            <Nav.Link href={`/${lang}/BookSpeaker/`}>{lang === 'fr' ? 'Réserver un conférencier spécialiste de la blockchain' : 'Book Blockchain Speaker'}</Nav.Link>
                                        </div>

                                    </div>
                                    <a href={`/${lang}/about-othalia-doe-bruce-1/`} ><p>{lang === 'fr' ? 'À propos d\'Othalia Doe-Bruce' : 'About Othalia'}</p></a>
                                    <a href={`/${lang}/About/`}><p>{lang === 'fr' ? 'À propos d\'Innovfin' : 'About Innovfin'}</p></a>
                                    <a href={`/${lang}/Blog/`}><p>{lang === 'fr' ? 'Blogue' : 'Blog'}</p></a>
                                    <a href={`/${lang}/Privacy-Policy/`}><p>{lang === 'fr' ? 'Politique de confidentialité' : 'Privacy Policy'}</p></a>
                                    <a href={`/${lang}/Terms-and-Conditions/`}><p>{lang === 'fr' ? 'Conditions générales d\'utilisation' : 'Terms and Conditions'}</p></a>
                                    <a href={`/sitemap.xml`}><p>{lang === 'fr' ? 'Plan du site' : 'Sitemap'}</p></a>
                                </div>

                            </Col>
                            <Col md={2} sm={4} xs={4}>
                                <div className='footerCard'>
                                    <h1>{lang === 'fr' ? 'Liens' : 'Links'}</h1>
                                    <a href="https://www.innov-edu.ca/"><p>Innov-Edu Online Learning Platform</p></a>
                                    <a href="https://loyalty.innov-edu.ca/"><p>Innov-Edu Loyalty Points</p></a>
                                    <a href="https://www.certificate.innov-edu.ca/"><p>Innov-Edu Certification</p></a>
                                    <a href="https://www.marketplace.innov-edu.ca/"><p>Innov-Edu NFT Marketplace</p></a>
                                </div>
                            </Col>
                            <Col md={2} sm={4} xs={4}>
                                <div className='footerCard'>
                                    <h1>{lang === 'fr' ? 'Assistance' : 'Support'}</h1>
                                    <a href={`/${lang}/contact-us/`}><p>{lang === 'fr' ? 'Contactez-nous' : 'Contact Us'}</p></a>
                                    <a href={`/${lang}/FAQ/`}><p>{lang === 'fr' ? 'Questions fréquemment posées' : 'Frequently Asked Questions'}</p></a>
                                    <p><button style={{ float: 'left' }} className="btn-link" id="btn-footer2" onClick={handleShowCookie}>{lang === 'fr' ? 'Cookies' : 'Cookies'}</button></p>
                                </div>
                            </Col>
                            <Col md={2} sm={4} xs={4}>
                                <div className='footerCard'>
                                    <h1>{lang === 'fr' ? 'Contactez-nous' : 'Contact Us'}</h1>
                                    <p>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                                            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                        </svg>
                                        {' Peterborough, Ontario, Canada'}
                                    </p>
                                    <p>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                        </svg>
                                        {' 416-900-3897'}
                                    </p>
                                    <p>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
                                        </svg>
                                        {' info@innovfin.ca'}
                                    </p>
                                    <Button href={`/${lang}/contact-us/`} className='button btn-2' id="btn-footer">{lang === 'fr' ? 'Réserver une consultation' : 'Book Consultation'}</Button>
                                </div>
                            </Col>
                            <Col style={{ paddingTop: "2em" }} md={12}>
                                <div className="copyright">
                                    {lang === 'fr' ? <p>© 2022 InnovFin Consulting Inc. - Tous droits réservés</p> : <p>© 2022 InnovFin Consulting Inc. - All Rights Reserved</p>}
                                    <button style={{ float: "right" }} className="btn-link" onClick={() => {
                                        document.body.scrollTop = 0;
                                        document.documentElement.scrollTop = 0;
                                    }}>
                                        <p className="toTop" style={{ float: 'right' }}>{lang === 'fr' ? 'Retour en haut' : 'Back to Top'}</p>
                                    </button>
                                </div>
                            </Col>
                        </Row>

                    </div>

                </Container>

                {/* Used to show cookie policy */}
                <Modal
                    animation={false}
                    show={showCookie}
                    onHide={handleCloseCookie}
                    centered
                    dialogClassName="popup-dialog-size"
                >
                    <Modal.Header className="popup-innovEdu-header">
                        <Modal.Title className="popup-header">{lang === 'fr' ? 'Politique en matière de cookies' : 'Cookies Policy'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="popup-innovEdu-body">{lang === 'fr' ?
                        <span>
                            <p>
                                Un cookie est un petit fichier que notre site enregistre sur votre appareil lors de votre visite.
                                Ces fichiers ne sont utilisés que pour garder la trace d'informations telles que les fenêtres contextuelles que vous avez vues, afin que vous ne les voyiez pas chaque fois que vous visitez notre site Web.
                                D'autres utilisations des cookies sur notre site incluent, sans s'y limiter, la gestion des widgets pour YouTube et d'autres sites de médias sociaux. Un exemple d'utilisation des
                                cookies est lorsque l'utilisateur reconnaît l'utilisation de nos cookies en appuyant sur "J'ai compris", cette boîte de dialogue n'apparaîtra plus jusqu'à ce que le cookie ait expiré ou si vous avez manuellement effacé les cookies de votre navigateur.
                            </p>
                        </span>
                        : <span>
                            <p>
                                A cookie is a small file that our site stores on your device when you visit.
                                These files are only used to keep track of information such as what popups you have seen so that you do not see them every time you visit our website.
                                Other uses of cookies on our site includes, but are not limited to, handling widgets for YouTube and other social media sites. An example of Cookie usage
                                is when the user acknowledges the use of our cookies by pressing "understood", this dialog will not show up again until the cookie has expired or if you have manually cleared cookies from your browser.
                            </p>
                        </span>
                    }
                    </Modal.Body>
                    <Modal.Footer className="popup-innovEdu-footer">
                        <Button variant="secondary" onClick={handleCloseCookie}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default Footer
